import { Trans } from 'react-i18next';
import { t } from "i18next";
import Store from "../../../Store";
import { useState } from "react"
import { countries } from 'country-list-json';
import Api from '../../../API/Api';
import {
    Link,
    useNavigate
} from "react-router-dom"
import log from '../../../functions/log';
import generateString from '../../../functions/generateString';

const api = new Api

const Step3 = () => {

    const navigate = useNavigate()
    const current = new Date()

    const [region, setRegion] = useState('none')
    const [birthdate, setBirthDate] = useState('')
    const [theme, setTheme] = useState(Store.getState().theme)

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    const onSubmit = async () => {
        const userCountry = document.getElementById('region-select').value.toLowerCase()
        const userSex = document.getElementById('sex-select').value

        if (birthdate != "" && userCountry != "none" && userSex != "none") {
            const email = Store.getState().signUpStepData.email
            const password = Store.getState().signUpStepData.password
            const language = Store.getState().signUpStepData.language
            const acceptsNewsletter = Store.getState().signUpStepData.newsletterChecked
            const uid = Store.getState().signUpStepData.uid

            api.register(email, acceptsNewsletter, uid, password, language, birthdate, userSex, userCountry)
            .then(res => {
                if (res.data.success) {
                    Store.dispatch({
                        type: "UPDATE_SIGNUP_DATA",
                        payload: {
                            picId: res.data.picId
                        }
                    })
                    Store.dispatch({
                        type: 'UPDATE_SIGNUP_STEP',
                        payload: 4
                    })
                    log("log", "utils.signup3.success", "success", 10000)
                } else {
                    log("log", "utils.signup3.error", "error", 5000)
                }
            })
            .catch(err => {
                log("log", "utils.signup3.error", "error", 5000)
            })
        } else {
            log("log", "utils.signup3.error", "error", 5000)
        }
    }

    return (
        <div className={`full-page-form bg-${theme}-main`}>
            <div className='fixed' id="fixed-logo-wrapper" onClick={() => {
                Store.dispatch({
                    type: 'UPDATE_SIGNUP_STEP',
                    payload: 1
                })
            }}>
                <Link className={`logo color-${theme}-text`} to="/">dBaato!</Link>
            </div>
            <div className='form-wrapper'>
                <div className={`form bg-${theme}-component color-${theme}-text`}>
                    <h1 className={`title color-${theme}-text`}>{<Trans i18nKey={"utils.signup.title"} ></Trans>}</h1>
                    <p className={`color-${theme}-textLow`}>{<Trans i18nKey={"utils.signup.text"} ></Trans>}</p>
                    <form>
                        <div className='inputs-column'>
                            <label className={`label-${theme}`}>
                                {t('utils.signup3.birthdatePlaceholder')}
                                <input
                                    style={{display: 'block'}}
                                    type="date"
                                    className={`input border-${theme} placeholder-${theme} color-${theme}-text`}
                                    min={`${current.getFullYear()-100}-01-01`}
                                    max={`${current.getFullYear()-13}-01-01`}
                                    onChange={(event) => {setBirthDate(event.target.value)}}
                                    >
                                </input>
                            </label>
                            <select
                                id="region-select"
                                className={`input border-${theme} placeholder-${theme} color-${theme}-text`}
                                onChange={() => {setRegion(document.getElementById("region-select").value)}}>
                                <option key={Math.random()} value={region === "none" ? "none" : region}>{region === "none" ? t('utils.signup3.regionPlaceholder') : region}</option>
                                {countries.map(country => {
                                    return <option
                                        key={Math.random()} value={`${country.name}`}
                                        >{country.name}
                                    </option>
                                })}
                                <option value="notsay">{t('utils.signup3.notSay')}</option>
                            </select>
                            <select
                                className={`input border-${theme} placeholder-${theme} color-${theme}-text`}
                                id="sex-select">
                                <option value="none">{t('utils.signup3.sexPlaceholder')}</option>
                                <option value="female">{t('utils.signup3.female')}</option>
                                <option value="male">{t('utils.signup3.male')}</option>
                                <option value="non-binary">{t('utils.signup3.nonBinary')}</option>
                                <option value="notsay">{t('utils.signup3.notSay')}</option>
                            </select>
                        </div>
                    </form>
                    <div className="inputs-inline">
                        <div
                            className={`form-back border-${theme} label-${theme}`}
                            onClick={() => {
                                Store.dispatch({
                                    type: 'UPDATE_SIGNUP_STEP',
                                    payload: 2
                                })
                            }}>
                           {t("utils.signup.back")}
                        </div>
                        <button className={`btn bg-${theme}-contrast color-${theme}-onContrast`} onClick={() => {onSubmit()}}>
                            <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                {<Trans i18nKey={"utils.signup.button"}></Trans>}
                                <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
                                    <path className={`fill-${theme}-onContrast`} d="M17.8299 14.29L13.5899 10.05C13.497 9.95627 13.3864 9.88187 13.2645 9.8311C13.1427 9.78033 13.012 9.7542 12.8799 9.7542C12.7479 9.7542 12.6172 9.78033 12.4954 9.8311C12.3735 9.88187 12.2629 9.95627 12.1699 10.05C11.9837 10.2374 11.8792 10.4908 11.8792 10.755C11.8792 11.0192 11.9837 11.2726 12.1699 11.46L15.7099 15L12.1699 18.54C11.9837 18.7274 11.8792 18.9808 11.8792 19.245C11.8792 19.5092 11.9837 19.7626 12.1699 19.95C12.2634 20.0427 12.3742 20.116 12.496 20.1658C12.6179 20.2155 12.7483 20.2408 12.8799 20.24C13.0115 20.2408 13.142 20.2155 13.2638 20.1658C13.3857 20.116 13.4965 20.0427 13.5899 19.95L17.8299 15.71C17.9237 15.617 17.9981 15.5064 18.0488 15.3846C18.0996 15.2627 18.1257 15.132 18.1257 15C18.1257 14.868 18.0996 14.7373 18.0488 14.6154C17.9981 14.4936 17.9237 14.383 17.8299 14.29Z"/>
                                </svg>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Step3