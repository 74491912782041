
const topicsTrans = {

    fr: {
        "Animals": "Animaux",
        "Art": "Art",
        "Body": "Corps",
        "Business": "Business",
        "Cooking": "Cuisine",
        "Cryptocurrencies": "Cryptomonnaies",
        "Culture": "Culture",
        "Design": "Design",
        "Ecology": "Ecologie",
        "Economics": "Economie",
        "Education": "Education",
        "Entertainment": "Divertissement",
        "Fashion": "Mode",
        "Finance": "Finance",
        "Food": "Nourriture",
        "Gaming": "Jeux-vidéo",
        "Health": "Santé",
        "Healthcare": "Médecine",
        "History": "Histoire",
        "Investing": "Investissement",
        "Journalism": "Journalisme",
        "Legal": "Droit",
        "Literature": "Littérature",
        "Marketing": "Marketing",
        "Money": "Argent",
        "Nature": "Nature",
        "Nutrition": "Nutrition",
        "Philosophy": "Philosophie",
        "Physics": "Physique",
        "Politics": "Politique",
        "Psychology": "Psychologie",
        "Religion": "Religion",
        "Science": "Science",
        "Sex": "Sexe",
        "Society": "Société",
        "Space": "Espace",
        "Technologies": "Technologies",
        "Trading": "Trading",
        "Travel": "Voyage",
        "Work": "Travail"
    }
}

export default topicsTrans 