import { createStore } from 'redux';



const initialState = {
    theme: localStorage.getItem('theme') != null ? localStorage.getItem('theme') : 'light',
    signUpStep: 1,
    signUpStepData: {},
    login: { token: null, refresh: null, id: null, password: null, verifiedEmail: null },
    signUpVisible: false,
    passwordRecoveryStep: 1,
    resetPasswordData: {},
    newDebateData: { title: '', reason: '', type: "public", guests: null, topic: 'default' },
    newArgData: { content: '' },
    statsModalVisible: false,
    userToInvite: '',
    nextPaginationDebates: 0,
    nextPaginationArgs: 0,
    canPaginate: false,
    floatingVisible: false,
    navigationHistory: []
};

const symbolReducer = (state = initialState, action) => {
    switch(action.type) {

        case 'UPDATE_THEME':
            return {
                ...state,
                theme: action.payload
            }

        case 'UPDATE_SIGNUP_STEP':
            return {
                ...state,
                signUpStep: action.payload
            }

        case 'UPDATE_SIGNUP_DATA':
            return {
                ...state,
                signUpStepData: action.payload
            }
        
        case 'LOGIN':
            return {
                ...state,
                login: action.payload
            }

        case 'UPDATE_PASSWORD_RECOVERY_STEP':
            return {
                ...state,
                passwordRecoveryStep: action.payload
            }

        case 'UPDATE_PASSWORD_RECOVERY_DATA':
            return {
                ...state,
                resetPasswordData: action.payload
            }

        case 'CREATE_NEW_DEBATE':
            return {
                ...state,
                newDebateData: action.payload
            }

        case 'ADD_ARGUMENT':
            return {
                ...state,
                newArgData: action.payload
            }

        case 'SET_STATS_MODAL_VISIBLE':
            return {
                ...state,
                statsModalVisible: action.payload
            }

        case 'USER_TO_INVITE':
            return {
                ...state,
                userToInvite: action.payload
            }

        case 'SET_DEBATES_NEXT_PAGINATION':
            return {
                ...state,
                nextPaginationDebates: action.payload
            }

        case 'SET_ARGS_NEXT_PAGINATION':
            return {
                ...state,
                nextPaginationArgs: action.payload
            }

        case 'SET_HOME_DATA_READY':
            return {
                ...state,
                homeDataReady: action.payload
            }

        case 'SET_CAN_PAGINATE':
            return {
                ...state,
                canPaginate: action.payload
            }
        case 'SET_FLOATING_VISIBLE':
            return {
                ...state,
                floatingVisible: action.payload
            }
        case 'UPDATE_NAVIGATION_HISTORY':
            return {
                ...state,
                navigationHistory: [...state.navigationHistory, action.payload]
            }
        default:
            return state;
    }
};

const Store = createStore(symbolReducer);

export default Store;