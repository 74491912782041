import Api from "../../API/Api"
import Store from "../../Store"
import SETTINGS from "../../SETTINGS"

const api = new Api

class HomeContentInit {

    constructor () {
        this.isDataReady = false

        this.debates = []
        this.trendingTopics = {}
        this.userStats = {}
        this.privateDebates = []
        this.peopleToFollow = []
        this.followingUsersArgs = []
        this.profilePics = {}
        this.followingUsersArgsPics = {}
        this.nextPaginationStartArgs = 999999999999999
        this.nextPaginationStartDebates = 999999999999999

        this.getHomeData()
    }

    getHomeData = async () => {
        api.getHomeContent(9999999, SETTINGS.home.initMinDebates, 9999999, SETTINGS.home.initMinArgs, false)
        .then(res => {

            if (res.data.success) {
                if (res.data.debates.length > 0) {

                    this.isDataReady = true
                    this.debates = res.data.debates
                    this.trendingTopics = res.data.activeTopics.topicsList
                    this.userStats = res.data.userStats
                    this.privateDebates = res.data.privateDebates
                    this.peopleToFollow = res.data.peopleToFollow
                    this.profilePics = res.data.profilePics
                    this.followingUsersArgs = res.data.argsFromFollowedUsers
                    this.followingUsersArgsPics = res.data.profilePicsOfArgumentsAuthors
                    this.nextPaginationStartArgs = res.data.nextPaginationStartArgs
                    this.nextPaginationStartDebates = res.data.nextPaginationStartDebates

                    Store.dispatch({
                        type: 'SET_DEBATES_NEXT_PAGINATION',
                        payload: this.nextPaginationStartDebates
                    })

                    Store.dispatch({
                        type: 'SET_ARGS_NEXT_PAGINATION',
                        payload: this.nextPaginationStartArgs
                    })

                } else {
                    this.isDataReady = false
                }
            } else {
                this.isDataReady = false
            }
        })
        .catch(err => {
            this.isDataReady = false
        })
    }

    returnHomeData = () => {

        if (this.isDataReady) {
            return {
                isDataReady: this.isDataReady,
                debates: this.debates,
                trandingTopics: this.trendingTopics,
                userStats: this.userStats,
                privateDebates: this.privateDebates,
                peopleToFollow: this.peopleToFollow,
                profilePics: this.profilePics,
                followingUsersArgs: this.followingUsersArgs,
                followingUsersArgsPics: this.followingUsersArgsPics,
                nextPaginationStartDebates: this.nextPaginationStartDebates,
                nextPaginationStartArgs: this.nextPaginationStartArgs
            }
        } else {
            return {
                isDataReady: false
            }
        }
    }

    setIsDataReady = (bool) => {
        this.isDataReady = bool 
    }

    getIsDataReady = () => {
        return this.isDataReady
    }
}

export default HomeContentInit