import { useEffect, useState } from "react"
import Store from "../../../../Store"

const HomeCarousselShadow = () => {

    const [theme, setTheme] = useState(Store.getState().theme)

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    return (
        <div className={`home-caroussel-wrapper`}>
            <div className={`home-caroussel`}>
                <section className={`home-caroussel-part-wrapper bg-${theme}-component shadow-${theme}-component`}>
                    <div className={`home-caroussel-part`}>
                        <div className={`home-caroussel-info`}>
                            <div className={`home-caroussel-title shadow bg-${theme}-main`}></div>
                            <div className={`home-caroussel-topic shadow bg-${theme}-main`}></div>
                        </div>
                    </div>
                </section>
                <section className={`home-caroussel-part-wrapper bg-${theme}-component shadow-${theme}-component`}>
                    <div className={`home-caroussel-part`}>
                        <div className={`home-caroussel-info`}>
                            <div className={`home-caroussel-title shadow bg-${theme}-main`}></div>
                            <div className={`home-caroussel-topic shadow bg-${theme}-main`}></div>
                        </div>
                    </div>
                </section>
                <section className={`home-caroussel-part-wrapper bg-${theme}-component`}>
                    <div className={`home-caroussel-part`}>
                        <div className={`home-caroussel-info`}>
                            <div className={`home-caroussel-title shadow shadow bg-${theme}-main`}></div>
                            <div className={`home-caroussel-topic shadow shadow bg-${theme}-main`}></div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default HomeCarousselShadow