import { useState } from 'react'
import Store from "../../Store";
import { t } from "i18next"
import { IKContext, IKImage } from 'imagekitio-react';
import FloatingMenu from '../reusable/FloatingMenu';
import ProfilePicMini from '../reusable/ProfilePicMini';

const UserProfile = () => {

    const [theme, setTheme] = useState(Store.getState().theme)
    const [menuVisible, setMenuVisible] = useState(false)

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
        const floatingVisible = Store.getState().floatingVisible
        if (!floatingVisible) {
            setMenuVisible(false)
        }
    })

    const disconnect = () => {

        Store.dispatch({
            type: 'SET_CONTENT_LOADED',
            payload: false
        })

        localStorage.clear()

        Store.dispatch({
            type: 'LOGIN',
            payload: { token: null, refresh: null, id: null, password: null, verifiedEmail: null }
        })

        window.location.reload()
    }

    return (
        <>
            <span className={`btn color-${theme}-textLow`} id="user-wrapper"
                onClick={() => {
                    if (!menuVisible) {
                        setMenuVisible(true)
                    }
                }}>
                <ProfilePicMini
                    profilePicLink={`https://ik.imagekit.io/hhsvlzkzs/${localStorage.getItem('profile-pic')}`}
                    width={50}
                    height={50}
                    border={`border-${theme}-lowContrast`}
                />
            </span>
            {
                menuVisible ? 
                <FloatingMenu
                    masterId="user-wrapper"
                    content={
                        <div className={`floating-menu-home-debate color-${theme}-text`}>
                            <a href='/account' className={`floating-menu-home-action border-bottom-${theme}-onComponent`}>
                                <svg className='icon-btn' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22"><path className={`fill-${theme}-contrast`} d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z"/></svg>
                                <p className={`color-${theme}-text`}>Mon compte</p>
                            </a>
                            <div className="floating-menu-home-action" onClick={() => {
                                disconnect()
                                }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22"><path className={`fill-${theme}-contrast`} d="M12.59,13l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H3a1,1,0,0,0,0,2ZM12,2A10,10,0,0,0,3,7.55a1,1,0,0,0,1.8.9A8,8,0,1,1,12,20a7.93,7.93,0,0,1-7.16-4.45,1,1,0,0,0-1.8.9A10,10,0,1,0,12,2Z"/></svg>
                                <p>{t("utils.headerMenu.disconnect")}</p>
                            </div>
                        </div>
                    }
                />
                :
                null
            }
        </>
    )
}

export default UserProfile