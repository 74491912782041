import colors from '../../../styles/colors/colors'
import {
    Link,
    useNavigate
} from "react-router-dom"
import { Trans } from 'react-i18next'
import { t } from 'i18next'
import { useState } from 'react'
import Api from '../../../API/Api'
import Store from '../../../Store'

const api = new Api

const Reset3 = () => {

    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [token, setToken] = useState(Store.getState().resetPasswordData.token)
    const [email, setEmail] = useState(Store.getState().resetPasswordData.email)
    const [canSubmit, setCanSubmit] = useState(false)
    const [theme, setTheme] = useState(Store.getState().theme)

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    const navigate = useNavigate()

    const onApply = async () => {

        if (canSubmit) {
            api.changePassword(password1, token)
            .then(response => {
                if (response.data.success) {
                    setSuccessMessage(t('utils.passwordReset3.success'))
                    document.getElementById('forgot3-success-dialog').style.display = 'block'
                    setTimeout(() => {
                        document.getElementById('forgot3-success-dialog').style.display = 'none'
                        Store.dispatch({
                            type: 'UPDATE_PASSWORD_RECOVERY_STEP',
                            payload: 1
                        })
                        navigate('/login')
                    }, 2000)
                } else {
                    document.getElementById('forgot3-error-dialog').style.display = 'block'
                    setErrorMessage(t('utils.passwordReset3.error'))
                    setTimeout(() => {
                        document.getElementById('forgot3-error-dialog').style.display = 'none'
                    }, 5000)
                }
            })
            .catch(error => {
                console.log(error)
            })
        } else {
            document.getElementById('forgot3-error-dialog').style.display = 'block'
            setErrorMessage(t('utils.passwordReset3.error'))
            setTimeout(() => {
                document.getElementById('forgot3-error-dialog').style.display = 'none'
            }, 5000)
        }
    }

    const onType = (value, compare) => {

        const regexMin8Max50 = /(?=.{8,50})/
        const regex1Upper = /(?=.*[A-Z])/
        const regex1Lower = /(?=.*[a-z])/
        const regex1Number = /(?=.*[0-9])/

        const min8max50 = regexMin8Max50.test(value)
        const upper = regex1Upper.test(value)
        const lower = regex1Lower.test(value)
        const number = regex1Number.test(value)

        const match = value === compare

        if (min8max50) {
            document.getElementById('li-nb-chars').style.color = colors.light.green
        } else {
            document.getElementById('li-nb-chars').style.color = colors.light.grey
        }

        if (upper) {
            document.getElementById('li-upper').style.color = colors.light.green
        } else {
            document.getElementById('li-upper').style.color = colors.light.grey
        }

        if (lower) {
            document.getElementById('li-lower').style.color = colors.light.green
        } else {
            document.getElementById('li-lower').style.color = colors.light.grey
        }

        if (number) {
            document.getElementById('li-nb').style.color = colors.light.green
        } else {
            document.getElementById('li-nb').style.color = colors.light.grey
        }

        if (match) {
            document.getElementById('li-match').style.color = colors.light.green
        } else {
            document.getElementById('li-match').style.color = colors.light.grey
        }

        if (match && number && lower && upper && min8max50) {
            setCanSubmit(true)
        } else {
            setCanSubmit(false)
        }
    }
    
    return (
        <div className={`full-page-form bg-${theme}-main`}>
            <div className='fixed' id="fixed-logo-wrapper">
                <Link className={`logo color-${theme}-text`} to="/">dBaato!</Link>
            </div>
            <div className='form-wrapper'>
                <div className={`form bg-${theme}-component color-${theme}-text`}>
                    <h1 className={`title color-${theme}-text`}>{<Trans i18nKey={"utils.passwordReset3.title"} ></Trans>}</h1>
                    <p className={`color-${theme}-textLow`}>{<Trans i18nKey={"utils.passwordReset3.text"} ></Trans>}</p>
                    
                    <form>
                        <input
                            onChange={(e) => {
                                setPassword1(e.target.value)
                                onType(e.target.value, password2)
                            }}
                            className={`input border-${theme} placeholder-${theme} color-${theme}-text`}
                            type="password"
                            placeholder={t("utils.passwordReset3.placeholder")}>
                        </input>
                        <input
                            onChange={(e) => {
                                setPassword2(e.target.value)
                                onType(e.target.value, password1)
                            }}
                            className={`input border-${theme} placeholder-${theme} color-${theme}-text`}
                            type="password"
                            placeholder={t("utils.passwordReset3.placeholder1")}>
                        </input>

                        <div className="dynamic-rules center label-${theme}">
                            <p id="li-nb-chars"><Trans i18nKey={"utils.passwordReset3.instruction1"}/></p>
                            <p id="li-upper"><Trans i18nKey={"utils.passwordReset3.instruction2"}/></p>
                            <p id="li-lower"><Trans i18nKey={"utils.passwordReset3.instruction3"}/></p>
                            <p id="li-nb"><Trans i18nKey={"utils.passwordReset3.instruction4"}/></p>
                            <p id="li-match"><Trans i18nKey={"utils.passwordReset3.instruction5"}/></p>
                        </div>
                    </form>

                    <button className={`btn bg-${theme}-contrast color-${theme}-onContrast`} onClick={() => {onApply()}}>
                        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            {<Trans i18nKey={"utils.passwordReset3.button"}></Trans>}
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className={`fill-${theme}-onContrast`} d="M17.8299 14.29L13.5899 10.05C13.497 9.95627 13.3864 9.88187 13.2645 9.8311C13.1427 9.78033 13.012 9.7542 12.8799 9.7542C12.7479 9.7542 12.6172 9.78033 12.4954 9.8311C12.3735 9.88187 12.2629 9.95627 12.1699 10.05C11.9837 10.2374 11.8792 10.4908 11.8792 10.755C11.8792 11.0192 11.9837 11.2726 12.1699 11.46L15.7099 15L12.1699 18.54C11.9837 18.7274 11.8792 18.9808 11.8792 19.245C11.8792 19.5092 11.9837 19.7626 12.1699 19.95C12.2634 20.0427 12.3742 20.116 12.496 20.1658C12.6179 20.2155 12.7483 20.2408 12.8799 20.24C13.0115 20.2408 13.142 20.2155 13.2638 20.1658C13.3857 20.116 13.4965 20.0427 13.5899 19.95L17.8299 15.71C17.9237 15.617 17.9981 15.5064 18.0488 15.3846C18.0996 15.2627 18.1257 15.132 18.1257 15C18.1257 14.868 18.0996 14.7373 18.0488 14.6154C17.9981 14.4936 17.9237 14.383 17.8299 14.29Z"/>
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
            <div className={`dialog bg-${theme}-red`} id="forgot3-error-dialog">
                <p className={`color-light-main`}>{errorMessage}</p>
            </div>
            <div className={`dialog bg-${theme}-green`} id="forgot3-success-dialog">
                <p className={`color-light-main`}>{successMessage}</p>
            </div>
        </div>
    )

}

export default Reset3