import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// import the .json files containing our translation
import fr from "../src/translations/fr.json";
import en from "../src/translations/en.json";

// Language detection based on navigator settings || localStorage
const options = {
  order: ['navigator', 'localStorage'],
  lookupLocalStorage: 'i18nextLng'
}

i18n
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
.use(LanguageDetector)
// pass the i18n instance to react-i18next.
.use(initReactI18next)
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
.init({
    debug: false,
    fallbackLng: 'en',
    detection: options,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        en: {
          translation: {
            common: {
              searchBar: en.common.searchBar,
              searchResults: {
                debates: en.common.searchResults.debates,
                arguments: en.common.searchResults.arguments,
                noResult: en.common.searchResults.noResult
              },
              signup: en.common.signup,
              login: en.common.login
            },
            externals: {
              emailConfirm: {
                  confirming: en.externals.emailConfirm.confirming,
                  success: en.externals.emailConfirm.success,
                  error: en.externals.emailConfirm.error
              }
            },
            utils: {
              inDevelopment: en.utils.inDevelopment,
              headerMenu: {
                disconnect: en.utils.headerMenu.disconnect
              },
              login: {
                title: en.utils.login.title,
                text: en.utils.login.text,
                placeholder1: en.utils.login.placeholder1,
                placeholder2: en.utils.login.placeholder2,
                keepSigned: en.utils.login.keepSigned,
                login: en.utils.login.login,
                forgot: en.utils.login.forgot,
                placeholderForgot: en.utils.login.placeholderForgot,
                emailForgotError: en.utils.login.emailForgotError,
                passwordResetSucess: en.utils.login.passwordResetSucess,
                passwordResetError: en.utils.login.passwordResetError,
                emailNotVerifiedError: en.utils.login.emailNotVerifiedError,
                wrongPassword: en.utils.login.wrongPassword,
                success: en.utils.login.success,
                noAccount: en.utils.login.noAccount
              },
              passwordReset: {
                title: en.utils.passwordReset.title,
                text: en.utils.passwordReset.text,
                placeholder: en.utils.passwordReset.placeholder,
                button: en.utils.passwordReset.button,
                error: en.utils.passwordReset.error
              },
              passwordReset2: {
                title: en.utils.passwordReset2.title,
                text: en.utils.passwordReset2.text,
                button: en.utils.passwordReset2.button,
                error: en.utils.passwordReset2.error
              },
              passwordReset3: {
                title: en.utils.passwordReset3.title,
                text: en.utils.passwordReset3.text,
                placeholder: en.utils.passwordReset3.placeholder,
                placeholder1: en.utils.passwordReset3.placeholder1,
                instruction1: en.utils.passwordReset3.instruction1,
                instruction2: en.utils.passwordReset3.instruction2,
                instruction3: en.utils.passwordReset3.instruction3,
                instruction4: en.utils.passwordReset3.instruction4,
                instruction5: en.utils.passwordReset3.instruction5,
                button: en.utils.passwordReset3.button,
                error: en.utils.passwordReset3.error,
                success: en.utils.passwordReset3.success
              },
              signup: {
                title: en.utils.signup.title,
                text: en.utils.signup.text,
                placeholder: en.utils.signup.placeholder,
                checkbox: en.utils.signup.checkbox,
                button: en.utils.signup.button,
                error: en.utils.signup.error,
                emailLabel: en.utils.signup.emailLabel,
                login: en.utils.signup.login,
                back: en.utils.signup.back
              },
              signup2: {
                title: en.utils.signup2.title,
                text: en.utils.signup2.text,
                placeholder1: en.utils.signup2.placeholder1,
                placeholder2: en.utils.signup2.placeholder2,
                placeholder3: en.utils.signup2.placeholder3,
                button: en.utils.signup2.button,
                error: en.utils.signup2.error,
                errorid: en.utils.signup2.errorid,
                erroruid: en.utils.signup2.erroruid,
                errorpassword: en.utils.signup2.errorpassword,
                errorboth: en.utils.signup2.errorboth,
                success: en.utils.signup2.success,
              },
              signup3: {
                error: en.utils.signup3.error,
                success: en.utils.signup3.success,
                regionPlaceholder: en.utils.signup3.regionPlaceholder,
                birthdatePlaceholder: en.utils.signup3.birthdatePlaceholder,
                sexPlaceholder: en.utils.signup3.sexPlaceholder,
                female: en.utils.signup3.female,
                male: en.utils.signup3.male,
                nonBinary: en.utils.signup3.nonBinary,
                transgender: en.utils.signup3.transgender,
                intersex: en.utils.signup3.intersex,
                notSay: en.utils.signup3.notSay
              },
              signup4: {
                title: en.utils.signup4.title,
                text: en.utils.signup4.text,
                skip: en.utils.signup4.skip,
                end: en.utils.signup4.end,
                success: en.utils.signup4.success,
                error: en.utils.signup4.error
              }
            },
            home: {
              welcome: en.home.welcome,
              openDebate: en.home.openDebate,
              debate: {
                showTopPro: en.home.debate.showTopPro,
                showTopCon: en.home.debate.showTopCon,
                bestPro: en.home.debate.bestPro,
                bestCon: en.home.debate.bestCon,
                votedYes: en.home.debate.votedYes,
                votedNo: en.home.debate.votedNo,
                votedYesShort: en.home.debate.votedYesShort,
                votedNoShort: en.home.debate.votedNoShort,
                follow: en.home.debate.follow,
                unfollow: en.home.debate.unfollow,
                share: en.home.debate.share,
                signal: en.home.debate.signal,
                signalBtn: en.home.debate.signalBtn,
                signalPlaceholder: en.home.debate.signalPlaceholder,
                followError: en.home.debate.followError,
                signalIncorrect: en.home.debate.signalIncorrect,
                signalError: en.home.debate.signalError,
                signalSuccess: en.home.debate.signalSuccess,
                goToDebate: en.home.debate.goToDebate
              },
              trendingTopics: {
                title: en.home.trendingTopics.title,
                textSuffix: en.home.trendingTopics.textSuffix,
                textPrefix: en.home.trendingTopics.textPrefix
              },
              userStats: {
                title: en.home.userStats.title,
                score: en.home.userStats.score,
                nbArguments: en.home.userStats.nbArguments,
                counters: en.home.userStats.counters,
                yes: en.home.userStats.yes,
                no: en.home.userStats.no
              },
              privateDebates: {
                title: en.home.privateDebates.title,
                notYet: en.home.privateDebates.notYet,
                noVotes: en.home.privateDebates.noVotes,
              },
              peopleToFollow: {
                title: en.home.peopleToFollow.title,
                follow: en.home.peopleToFollow.follow,
                followError: en.home.peopleToFollow.followError,
                noMore: en.home.peopleToFollow.noMore
              }
            },
            newDebate: {
              openDebate: en.newDebate.openDebate,
              openDebateText: en.newDebate.openDebateText,
              openDebateNoUserText: en.newDebate.openDebateNoUserText,
              openDebateRegister: en.newDebate.openDebateRegister,
              openDebateLogin: en.newDebate.openDebateLogin,
              openDebatePlaceholder1: en.newDebate.openDebatePlaceholder1,
              openDebatePlaceholder2: en.newDebate.openDebatePlaceholder2,
              openDebateType: en.newDebate.openDebateType,
              openDebateReason: en.newDebate.openDebateReason,
              error: en.newDebate.error,
              errorTopic: en.newDebate.errorTopic,
              errorUnknown: en.newDebate.errorUnknown,
              topic: en.newDebate.topic,
              topicDefault: en.newDebate.topicDefault,
              next: en.newDebate.next,
              finish: en.newDebate.finish,
              public: en.newDebate.public,
              publicDescription: en.newDebate.publicDescription,
              private: en.newDebate.private,
              privateDescription: en.newDebate.privateDescription,
              guests: en.newDebate.guests,
              guestsInfo: en.newDebate.guestsInfo,
              guestsNoFriends: en.newDebate.guestsNoFriends,
              success: en.newDebate.success,
              
            },
            debate: {
              votedYes: en.debate.votedYes,
              votedNo: en.debate.votedNo,
              noReason: en.debate.noReason,
              argue: en.debate.argue,
              prefixe: en.debate.prefixe,
              suffixes: {
                min: en.debate.suffixes.min,
                hour: en.debate.suffixes.hour,
                day: en.debate.suffixes.day,
              },
              newArgSuccess: en.debate.newArgSuccess,
              newArgIncorrect: en.debate.newArgIncorrect,
              newArgError: en.debate.newArgError,
              newArgLabel1: en.debate.newArgLabel1,
              newArgLabel2: en.debate.newArgLabel2,
              newArgYes: en.debate.newArgYes,
              newArgNo: en.debate.newArgNo,
              newArgCancel: en.debate.newArgCancel,
              newArgAdd: en.debate.newArgAdd,
              signal: en.debate.signal,
              delete: en.debate.delete,
              signalModalLabel: en.debate.signalModalLabel,
              signalModalPlaceholder: en.debate.signalModalPlaceholder,
              counterTitle: en.debate.counterTitle,
              counterPlaceholder: en.debate.counterPlaceholder,
              counterBtn: en.debate.counterBtn,
              statsBtn: en.debate.statsBtn,
              statsKeyTitle: en.debate.statsKeyTitle,
              statsScoresRatio: en.debate.statsScoresRatio,
              statsYesDebatedPct: en.debate.statsYesDebatedPct,
              statsNoDebatedPct: en.debate.statsNoDebatedPct,
              statsDebateEvolutionTitle: en.debate.statsDebateEvolutionTitle,
              statsDemographicsTitle: en.debate.statsDemographicsTitle,
              statsTopCountryYes: en.debate.statsTopCountryYes,
              statsTopSexYes: en.debate.statsTopSexYes,
              statsMedianAgeYes: en.debate.statsMedianAgeYes,
              statsTopCountryNo: en.debate.statsTopCountryNo,
              statsTopSexNo: en.debate.statsTopSexNo,
              statsMedianAgeNo: en.debate.statsMedianAgeNo,
              voteTitle: en.debate.voteTitle,
              voteYes: en.debate.voteYes,
              voteNo: en.debate.voteNo,
              newArgTitle: en.debate.newArgTitle,
              newArgPlaceholder: en.debate.newArgPlaceholder,
              inviteTitle: en.debate.inviteTitle,
              inviteLabel: en.debate.inviteLabel,
              inviteUnknownUser: en.debate.inviteUnknownUser,
              inviteBtn: en.debate.inviteBtn,
              inviteFailed: en.debate.inviteFailed,
              inviteSuccess: en.debate.inviteSuccess,
              yourScore: en.debate.yourScore,
              argumentVotedYes: en.debate.argumentVotedYes,
              argumentVotedNo: en.debate.argumentVotedNo,
              argumentVotedYesSelf: en.debate.argumentVotedYesSelf,
              argumentVotedNoSelf: en.debate.argumentVotedNoSelf,

            },
            topic: {
              title: en.topic.title
            },
            account: {
              score: en.account.score,
              followers: en.account.followers,
              following: en.account.following,
              informations: en.account.informations,
              email: en.account.email,
              birthdate: en.account.birthdate,
              region: en.account.region,
              sex: en.account.sex,
              emailsPrefNo: en.account.emailsPrefNo,
              emailsPrefYes: en.account.emailsPrefYes,
              emailsChange: en.account.emailsChange,
              accountDelete: en.account.accountDelete,
              accountDeleteConfTitle: en.account.accountDeleteConfTitle,
              accountDeleteConfYes: en.account.accountDeleteConfYes,
              accountDeleteConfCancel: en.account.accountDeleteConfCancel,
              accountDeletePlaceholder: en.account.accountDeletePlaceholder,
              logs: {
                unknownError: en.account.logs.unknownError
              }
            }
          }
        },
        fr: {
          translation: {
            common: {
              searchBar: fr.common.searchBar,
              searchResults: {
                debates: fr.common.searchResults.debates,
                arguments: fr.common.searchResults.arguments,
                noResult: fr.common.searchResults.noResult
              },
              signup: fr.common.signup,
              login: fr.common.login
            },
            externals: {
              emailConfirm: {
                  confirming: fr.externals.emailConfirm.confirming,
                  success: fr.externals.emailConfirm.success,
                  error: fr.externals.emailConfirm.error
              }
            },
            utils: {
              inDevelopment: fr.utils.inDevelopment,
              headerMenu: {
                disconnect: fr.utils.headerMenu.disconnect
              },
              login: {
                title: fr.utils.login.title,
                text: fr.utils.login.text,
                placeholder1: fr.utils.login.placeholder1,
                placeholder2: fr.utils.login.placeholder2,
                keepSigned: fr.utils.login.keepSigned,
                login: fr.utils.login.login,
                forgot: fr.utils.login.forgot,
                placeholderForgot: fr.utils.login.placeholderForgot,
                emailForgotError: fr.utils.login.emailForgotError,
                passwordResetSucess: fr.utils.login.passwordResetSucess,
                passwordResetError: fr.utils.login.passwordResetError,
                emailNotVerifiedError: fr.utils.login.emailNotVerifiedError,
                wrongPassword: fr.utils.login.wrongPassword,
                success: fr.utils.login.success,
                noAccount: fr.utils.login.noAccount
              },
              passwordReset: {
                title: fr.utils.passwordReset.title,
                text: fr.utils.passwordReset.text,
                placeholder: fr.utils.passwordReset.placeholder,
                button: fr.utils.passwordReset.button,
                error: fr.utils.passwordReset.error
              },
              passwordReset2: {
                title: fr.utils.passwordReset2.title,
                text: fr.utils.passwordReset2.text,
                button: fr.utils.passwordReset2.button,
                error: fr.utils.passwordReset2.error
              },
              passwordReset3: {
                title: fr.utils.passwordReset3.title,
                text: fr.utils.passwordReset3.text,
                placeholder: fr.utils.passwordReset3.placeholder,
                placeholder1: fr.utils.passwordReset3.placeholder1,
                instruction1: fr.utils.passwordReset3.instruction1,
                instruction2: fr.utils.passwordReset3.instruction2,
                instruction3: fr.utils.passwordReset3.instruction3,
                instruction4: fr.utils.passwordReset3.instruction4,
                instruction5: fr.utils.passwordReset3.instruction5,
                button: fr.utils.passwordReset3.button,
                error: fr.utils.passwordReset3.error,
                success: fr.utils.passwordReset3.success,
              },
              signup: {
                title: fr.utils.signup.title,
                text: fr.utils.signup.text,
                placeholder: fr.utils.signup.placeholder,
                checkbox: fr.utils.signup.checkbox,
                button: fr.utils.signup.button,
                error: fr.utils.signup.error,
                emailLabel: fr.utils.signup.emailLabel,
                login: fr.utils.signup.login,
                back: fr.utils.signup.back
              },
              signup2: {
                title: fr.utils.signup2.title,
                text: fr.utils.signup2.text,
                placeholder1: fr.utils.signup2.placeholder1,
                placeholder2: fr.utils.signup2.placeholder2,
                placeholder3: fr.utils.signup2.placeholder3,
                button: fr.utils.signup2.button,
                error: fr.utils.signup2.error,
                errorid: fr.utils.signup2.errorid,
                erroruid: fr.utils.signup2.erroruid,
                errorpassword: fr.utils.signup2.errorpassword,
                errorboth: fr.utils.signup2.errorboth,
                success: fr.utils.signup2.success,
              },
              signup3: {
                error: fr.utils.signup3.error,
                success: fr.utils.signup3.success,
                regionPlaceholder: fr.utils.signup3.regionPlaceholder,
                birthdatePlaceholder: fr.utils.signup3.birthdatePlaceholder,
                sexPlaceholder: fr.utils.signup3.sexPlaceholder,
                female: fr.utils.signup3.female,
                male: fr.utils.signup3.male,
                nonBinary: fr.utils.signup3.nonBinary,
                transgender: fr.utils.signup3.transgender,
                intersex: fr.utils.signup3.intersex,
                notSay: fr.utils.signup3.notSay
              },
              signup4: {
                title: fr.utils.signup4.title,
                text: fr.utils.signup4.text,
                skip: fr.utils.signup4.skip,
                end: fr.utils.signup4.end,
                success: fr.utils.signup4.success,
                error: fr.utils.signup4.error
              }
            },
            home: {
              welcome: fr.home.welcome,
              openDebate: fr.home.openDebate,
              debate: {
                showTopPro: fr.home.debate.showTopPro,
                showTopCon: fr.home.debate.showTopCon,
                bestPro: fr.home.debate.bestPro,
                bestCon: fr.home.debate.bestCon,
                votedYes: fr.home.debate.votedYes,
                votedNo: fr.home.debate.votedNo,
                votedYesShort: fr.home.debate.votedYesShort,
                votedNoShort: fr.home.debate.votedNoShort,
                follow: fr.home.debate.follow,
                unfollow: fr.home.debate.unfollow,
                share: fr.home.debate.share,
                signal: fr.home.debate.signal,
                signalBtn: fr.home.debate.signalBtn,
                signalPlaceholder: fr.home.debate.signalPlaceholder,
                followError: fr.home.debate.followError,
                signalIncorrect: fr.home.debate.signalIncorrect,
                signalError: fr.home.debate.signalError,
                signalSuccess: fr.home.debate.signalSuccess,
                goToDebate: fr.home.debate.goToDebate
              },
              trendingTopics: {
                title: fr.home.trendingTopics.title,
                textSuffix: fr.home.trendingTopics.textSuffix,
                textPrefix: fr.home.trendingTopics.textPrefix
              },
              userStats: {
                title: fr.home.userStats.title,
                score: fr.home.userStats.score,
                nbArguments: fr.home.userStats.nbArguments,
                counters: fr.home.userStats.counters,
                yes: fr.home.userStats.yes,
                no: fr.home.userStats.no
              },
              privateDebates: {
                title: fr.home.privateDebates.title,
                notYet: fr.home.privateDebates.notYet,
                noVotes: fr.home.privateDebates.noVotes,

              },
              peopleToFollow: {
                title: fr.home.peopleToFollow.title,
                follow: fr.home.peopleToFollow.follow,
                followError: fr.home.peopleToFollow.followError,
                noMore: fr.home.peopleToFollow.noMore
              }
            },
            newDebate: {
              openDebate: fr.newDebate.openDebate,
              openDebateText: fr.newDebate.openDebateText,
              openDebateNoUserText: fr.newDebate.openDebateNoUserText,
              openDebateRegister: fr.newDebate.openDebateRegister,
              openDebateLogin: fr.newDebate.openDebateLogin,
              openDebatePlaceholder1: fr.newDebate.openDebatePlaceholder1,
              openDebatePlaceholder2: fr.newDebate.openDebatePlaceholder2,
              openDebateType: fr.newDebate.openDebateType,
              openDebateTypeOption1: fr.newDebate.openDebateTypeOption1,
              openDebateTypeOption2: fr.newDebate.openDebateTypeOption2,
              openDebateReason: fr.newDebate.openDebateReason,
              error: fr.newDebate.error,
              errorTopic: fr.newDebate.errorTopic,
              errorUnknown: fr.newDebate.errorUnknown,
              topic: fr.newDebate.topic,
              topicDefault: fr.newDebate.topicDefault,
              next: fr.newDebate.next,
              finish: fr.newDebate.finish,
              public: fr.newDebate.public,
              publicDescription: fr.newDebate.publicDescription,
              private: fr.newDebate.private,
              privateDescription: fr.newDebate.privateDescription,
              guests: fr.newDebate.guests,
              guestsInfo: fr.newDebate.guestsInfo,
              guestsNoFriends: fr.newDebate.guestsNoFriends,
              success: fr.newDebate.success,

            },
            debate: {
              votedYes: fr.debate.votedYes,
              votedNo: fr.debate.votedNo,
              noReason: fr.debate.noReason,
              argue: fr.debate.argue,
              prefixe: fr.debate.prefixe,
              suffixes: {
                min: fr.debate.suffixes.min,
                hour: fr.debate.suffixes.hour,
                day: fr.debate.suffixes.day,
              },
              newArgSuccess: fr.debate.newArgSuccess,
              newArgIncorrect: fr.debate.newArgIncorrect,
              newArgError: fr.debate.newArgError,
              newArgLabel1: fr.debate.newArgLabel1,
              newArgLabel2: fr.debate.newArgLabel2,
              newArgYes: fr.debate.newArgYes,
              newArgNo: fr.debate.newArgNo,
              newArgCancel: fr.debate.newArgCancel,
              newArgAdd: fr.debate.newArgAdd,
              signal: fr.debate.signal,
              delete: fr.debate.delete,
              signalModalLabel: fr.debate.signalModalLabel,
              signalModalPlaceholder: fr.debate.signalModalPlaceholder,
              counterTitle: fr.debate.counterTitle,
              counterPlaceholder: fr.debate.counterPlaceholder,
              counterBtn: fr.debate.counterBtn,
              statsBtn: fr.debate.statsBtn,
              statsKeyTitle: fr.debate.statsKeyTitle,
              statsScoresRatio: fr.debate.statsScoresRatio,
              statsYesDebatedPct: fr.debate.statsYesDebatedPct,
              statsNoDebatedPct: fr.debate.statsNoDebatedPct,
              statsDebateEvolutionTitle: fr.debate.statsDebateEvolutionTitle,
              statsDemographicsTitle: fr.debate.statsDemographicsTitle,
              statsTopCountryYes: fr.debate.statsTopCountryYes,
              statsTopSexYes: fr.debate.statsTopSexYes,
              statsMedianAgeYes: fr.debate.statsMedianAgeYes,
              statsTopCountryNo: fr.debate.statsTopCountryNo,
              statsTopSexNo: fr.debate.statsTopSexNo,
              statsMedianAgeNo: fr.debate.statsMedianAgeNo,
              voteTitle: fr.debate.voteTitle,
              voteYes: fr.debate.voteYes,
              voteNo: fr.debate.voteNo,
              newArgTitle: fr.debate.newArgTitle,
              newArgPlaceholder: fr.debate.newArgPlaceholder,
              inviteTitle: fr.debate.inviteTitle,
              inviteLabel: fr.debate.inviteLabel,
              inviteUnknownUser: fr.debate.inviteUnknownUser,
              inviteBtn: fr.debate.inviteBtn,
              inviteFailed: fr.debate.inviteFailed,
              inviteSuccess: fr.debate.inviteSuccess,
              yourScore: fr.debate.yourScore,
              argumentVotedYes: fr.debate.argumentVotedYes,
              argumentVotedNo: fr.debate.argumentVotedNo,
              argumentVotedYesSelf: fr.debate.argumentVotedYesSelf,
              argumentVotedNoSelf: fr.debate.argumentVotedNoSelf,
            },
            topic: {
              title: fr.topic.title
            },
            account: {
              score: fr.account.score,
              followers: fr.account.followers,
              following: fr.account.following,
              informations: fr.account.informations,
              email: fr.account.email,
              birthdate: fr.account.birthdate,
              region: fr.account.region,
              sex: fr.account.sex,
              emailsPrefNo: fr.account.emailsPrefNo,
              emailsPrefYes: fr.account.emailsPrefYes,
              emailsChange: fr.account.emailsChange,
              accountDelete: fr.account.accountDelete,
              accountDeleteConfTitle: fr.account.accountDeleteConfTitle,
              accountDeleteConfYes: fr.account.accountDeleteConfYes,
              accountDeleteConfCancel: fr.account.accountDeleteConfCancel,
              accountDeletePlaceholder: fr.account.accountDeletePlaceholder,
              logs: {
                unknownError: fr.account.logs.unknownError
              }
            }
          }
        }
    }
});

export default i18n;