import { useState, useEffect } from "react"
import Step1 from "./signUpSteps/Step1";
import Step2 from "./signUpSteps/Step2";
import Step3 from "./signUpSteps/Step3";
import Step4 from "./signUpSteps/Step4";
import Store from "../../Store";

const steps = {
    1: <Step1/>,
    2: <Step2/>,
    3: <Step3/>,
    4: <Step4/>
}

const SignUp = () => {

    const [step, setStep] = useState(Store.getState().signUpStep)
    const [theme, setTheme] = useState(Store.getState().theme)
    const easing = 'cubic-bezier(0.075, 0.82, 0.165, 1)'

    Store.subscribe(() => {
        setStep(Store.getState().signUpStep)
        setTheme(Store.getState().theme)

        try {
            const loadBar = document.getElementById('load-bar-signup')
            let pct = 25
            if (Store.getState().signUpStep === 1) {
                pct = 25
            } else if (Store.getState().signUpStep === 2) {
                pct = 50
            } else if (Store.getState().signUpStep === 3) {
                pct = 75
            } else if (Store.getState().signUpStep === 4) {
                pct = 100
            } 

            loadBar.animate([{ width: `${pct}%` }], { duration: 5000, easing })
            .onfinish = () => {loadBar.style.width = `${pct}%`}
            
        } catch (err) {}

    })

    useEffect(() => {
        Store.dispatch({
            type: 'UPDATE_NAVIGATION_HISTORY',
            payload: "signup"
        })
    }, [])

    return (
        <>
            {steps[step]}
            <div className="bottom-load-bar-wrapper">
                <div id="load-bar-signup" className={`bottom-load-bar bg-${theme}-gradient`}></div>
            </div>
            <div id="log"></div>
        </>
    )
}

export default SignUp