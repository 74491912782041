const getTopSex = (obj) => {
    let sexHighest = 0
    let sex
    Object.keys(obj).forEach(sex_ => {
        if (obj[sex_] > sexHighest) {
            sexHighest = obj[sex_]
            sex = sex_
        }
    })
    return sex
}

export default getTopSex