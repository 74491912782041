import { useEffect, useState } from "react"
import Store from "../../../../Store"

const HomeDebateShadow = () => {

    const [theme, setTheme] = useState(Store.getState().theme)
    const [shadowClass, setShadowClass] = useState(`shadow-${theme}-component bg-${theme}-main`)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    useEffect(() => {
        setShadowClass(`shadow shadow-${theme}-component bg-${theme}-main`)
        setWindowWidth(window.innerWidth)
    }, [theme])

    return (
        <div className={`home-debate-wrapper bg-${theme}-component shadow-${theme} ${windowWidth <= 570 ? 'mobile-shadow' : null}`}>
            <div className={`home-debate`}>
                <div className={`home-debate-top`}>
                    <div className="home-debate-title">
                        <div className={shadowClass}></div>
                        <div className={shadowClass}></div>
                    </div>
                    <div className={shadowClass}></div>
                </div>
                <div className="home-debate-middle">
                    <div className={shadowClass}></div>
                </div>
                <div className="home-debate-bottom">
                    <div className={shadowClass}></div>
                </div>
            </div>
        </div>
    )
}

export default HomeDebateShadow