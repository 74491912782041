import { useEffect, useState } from 'react'
import Store from '../../../Store'
import topicsTrans from '../../../utils/topicsTrans'
import { t } from "i18next"
import { Trans } from 'react-i18next'
import Api from '../../../API/Api'
import { useNavigate } from 'react-router-dom'
import log from '../../../functions/log'
import ProfilePicMini from '../ProfilePicMini'

const api = new Api

// Props: - contentType, -
const HomeSideComponent = (props) => {

    const [theme, setTheme] = useState(Store.getState().theme)
    const navigate = useNavigate()

    const [lngIsEn] = useState(navigator.language.split('-')[0].toLowerCase() === 'en')
    const [lng] = useState(navigator.language.split('-')[0].toLowerCase())
    const [content, setContent] = useState(null)
    
    const [nbPeopleToFollow, setNbPeopleToFollow] = useState(props.contentType === "peopleToFollow" ? props.people.length : 0)
    const [followCount, setFollowCount] = useState(0)

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    const peopleToFollowSetContent = (users) => {
        setContent(
            users.length > 0 ?
            users.map(toFollow => {

                // Find user favorite domain
                let mostActiveIn
                let mostActiveCount = 0
                let domains = JSON.parse(toFollow.explored_domains)
                domains = domains.domains
                domains.forEach(domain => {
                    let counts = 0
                    domains.forEach(domain_ => {
                        if (domain_ === domain) {
                            counts++
                        }
                    })
                    if (counts > mostActiveCount) {
                        mostActiveIn = domain
                        mostActiveCount = counts
                    }
                })

                if (!lngIsEn) {
                    try {
                        mostActiveIn = topicsTrans[lng][mostActiveIn]
                    } catch(err) {}
                }

                const id = Math.random()

                return (
                    <div id={id} key={Math.random()} className={`home-peopleToFollow-wrapper color-${theme}-text`}>
                        <div className={`home-peopleToFollow`}>
                             
                            <ProfilePicMini profilePicLink={props.profilePics[toFollow.uid]} border={`border-${theme}`} />

                            <div className={`home-peopleToFollow-uid-domain`}>
                                <p className={`home-peopleToFollow-domain`}>{mostActiveIn}</p>
                                <p className={`home-peopleToFollow-uid`}>{toFollow.uid}</p>
                            </div>
                            <button
                                onClick={() => {
                                    if (localStorage.getItem('persistant-login-id') != null && typeof localStorage.getItem('persistant-login-id') === "string") {
                                        api.followUnfollow("follow", toFollow.uid)
                                        .then(res => {
                                            if (res.data.success) {
                                                // Hide the component if followed.
                                                setFollowCount(followCount+1)
                                                const userComponent = document.getElementById(id)
                                                userComponent.animate([{ opacity: 0 }], {duration: 300})
                                                .onfinish = (() => {
                                                    userComponent.style.opacity = 0
                                                    userComponent.animate([{ height: '0px' }], {duration: 200})
                                                    .onfinish = () => {
                                                        userComponent.style.display = "none"
                                                        userComponent.style.height = "0px"
                                                    }
                                                })

                                                const startMs = Date.now()
                                                // Load more people to follow if user followed all
                                                // followCount+1 and not followcount as last increment is not counted yet
                                                if (followCount+1 >= nbPeopleToFollow) {
                                                    //console.log("user followed everybody, loading more profiles")
                                                    api.getPeopleToFollow()
                                                    .then(res => {
                                                        if (res.data.success) {
                                                            if (Date.now() - startMs < 500) {
                                                                setTimeout(() => {
                                                                    peopleToFollowSetContent(res.data.peopleToFollow)
                                                                }, Date.now() - startMs)
                                                            } else {
                                                                peopleToFollowSetContent(res.data.peopleToFollow)
                                                            }
                                                        }
                                                    })
                                                    .catch(err => {
                                                        console.log(err)
                                                    })
                                                }
                                            } else {
                                                log('log', "home.peopleToFollow.followError", "error", 5000)
                                            }
                                        })
                                        .catch(err => {
                                            log('log', "home.peopleToFollow.followError", "error", 5000)
                                        })
                                    } else {
                                        navigate('/signup')
                                    }                              
                                }}
                                className={`btn bg-${theme}-contrast color-${theme}-onContrast`}>
                                <Trans i18nKey={"home.peopleToFollow.follow"}/>
                            </button>
                        </div>
                    </div>
                )
            })
            :
            <p>{t("home.peopleToFollow.noMore")}</p>
        )
    }

    const sendInvite = async () => {

        const invitedUser =  Store.getState().userToInvite

        const checkEmail = (email) => {
            const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            return regex.test(email)
        }

        const checkUid = (uid) => {
            const regex = /^(?=.*[a-zA-Z])[a-z0-9]{5,25}$/i
            return regex.test(uid)
        }

        let regexValidated = false
        if (checkEmail(invitedUser) || checkUid(invitedUser)) {
            regexValidated = true
        }

        if (regexValidated) {
            api.inviteToPrivateDebate(invitedUser, props.debateId, props.debate, props.topic)
            .then(res => {
                if (res.data.success) {
                    log("log", "debate.inviteSuccess", "success", 3000)
                } else {
                    log("log", "debate.inviteFailed", "error", 3000)
                }
            })
            .catch(err => {
                log("log", "debate.inviteFailed", "error", 3000)
            })
        } else {
            log("log", "debate.inviteUnknownUser", "error", 3000)
        }

    }

    const goToLogin = () => {
        navigate('/login')
    }

    useEffect(() => {
        // Set content as per the props.contentType value
        switch (props.contentType) {

            case 'trending':
                setContent(
                    props.topics.map((topic, index) => {
                        let topicDisplayed = topic.topic
                        if (!lngIsEn) {
                            try {
                                topicDisplayed = topicsTrans[lng][topic.topic]
                            } catch(err) {}
                        }
                        return (
                            <a href={`/topic/${topic.topic}`} key={Math.random()} className={`trending-topic`}>
                                <h3 className={`color-${theme}-text`}>#{index+1} {topicDisplayed}</h3>
                                <p className={`color-${theme}-text`}>{t('home.trendingTopics.textPrefix')} {topic.nbParticipants} {t('home.trendingTopics.textSuffix')}</p>
                            </a>
                        )
                    })
                )
                break
            case 'userStats':
                setContent(
                    <>
                        <div className='home-userStat-wrapper'>
                            <h3>{props.score}</h3>
                            <p>{t('home.userStats.score')}</p>
                        </div>
                        <div className='home-userStat-wrapper'>
                            <h3>{props.nbArguments}</h3>
                            <p>{t('home.userStats.nbArguments')}</p>
                        </div>
                        <div className='home-userStat-wrapper'>
                            <h3>{props.counters}</h3>
                            <p>{t('home.userStats.counters')}</p>
                        </div>
                    </>
                )
                break
            case 'privateDebates':
                props.debates.length > 0 ? 
                    setContent(
                        <div>
                            {
                                props.debates.length > 0 ? 
                                props.debates.map(debate => {
                                    let yesPCt = ((debate.voted_yes / (debate.voted_no + debate.voted_yes))*100).toFixed(0)
                                    let noPct = ((debate.voted_no / (debate.voted_no + debate.voted_yes))*100).toFixed(0)

                                    if (isNaN(yesPCt)) {
                                        yesPCt = 0
                                    }
                                    if (isNaN(noPct)) {
                                        noPct = 0
                                    }

                                    const displayedVote = yesPCt >= noPct ? yesPCt : noPct
                                    const displayedVoteText = yesPCt >= noPct ? t("home.debate.votedYes") : t("home.debate.votedNo")
                                    
                                    return (
                                        <a 
                                            href={debate.link} target="_blank"
                                            key={Math.random()} className={`home-private-debate border-bottom-${theme}-onComponent color-${theme}-text`}>
                                            {
                                                debate.nbArguments > 0 ? 
                                                <>
                                                    <h3>{debate.title}</h3>
                                                    <div className='home-private-debate-stats'>
                                                        <p>{displayedVote}% {displayedVoteText}</p>
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                <path className={`fill-${theme}-contrast`} d="M13,11H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Zm4-4H7A1,1,0,0,0,7,9H17a1,1,0,0,0,0-2Zm2-5H5A3,3,0,0,0,2,5V15a3,3,0,0,0,3,3H16.59l3.7,3.71A1,1,0,0,0,21,22a.84.84,0,0,0,.38-.08A1,1,0,0,0,22,21V5A3,3,0,0,0,19,2Zm1,16.59-2.29-2.3A1,1,0,0,0,17,16H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"/>
                                                            </svg>
                                                            {debate.nbArguments}
                                                        </span>
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                <path className={`fill-${theme}-contrast`} d="M12.3,12.22A4.92,4.92,0,0,0,14,8.5a5,5,0,0,0-10,0,4.92,4.92,0,0,0,1.7,3.72A8,8,0,0,0,1,19.5a1,1,0,0,0,2,0,6,6,0,0,1,12,0,1,1,0,0,0,2,0A8,8,0,0,0,12.3,12.22ZM9,11.5a3,3,0,1,1,3-3A3,3,0,0,1,9,11.5Zm9.74.32A5,5,0,0,0,15,3.5a1,1,0,0,0,0,2,3,3,0,0,1,3,3,3,3,0,0,1-1.5,2.59,1,1,0,0,0-.5.84,1,1,0,0,0,.45.86l.39.26.13.07a7,7,0,0,1,4,6.38,1,1,0,0,0,2,0A9,9,0,0,0,18.74,11.82Z"/>
                                                            </svg>
                                                            {debate.nbParticipants}
                                                        </span>
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    <h3>{debate.title}</h3>
                                                    <p>{t("home.privateDebates.noVotes")}</p>
                                                </>
                                            }
                                        </a>
                                    )
                                })
                                :
                                <span>{t("home.privateDebates.notYet")}</span>
                            }
                        </div>
                    )
                    :
                    setContent(
                        <div>
                            <h1>{t("home.privateDebates.notYet")}</h1>
                        </div>
                    )
                break
            case "peopleToFollow":
                peopleToFollowSetContent(props.people)
                break
            case "debate-stats-btn":
                setContent(
                    null
                )
                break
            case "debate-vote-btns":
                setContent(
                    <div className="vote-btns-wrapper">
                        <p
                            onClick={localStorage.getItem('persistant-login-id') != null && typeof localStorage.getItem('persistant-login-id') === "string" ? props.onClickVoteYes : goToLogin}
                            className={`color-${theme}-text`}>{t("debate.voteYes")}
                        </p>
                        <div className={`border-${theme}-lowContrast`}></div>
                        <p
                            onClick={localStorage.getItem('persistant-login-id') != null && typeof localStorage.getItem('persistant-login-id') === "string" ? props.onClickVoteNo : goToLogin}
                            className={`color-${theme}-text`}>{t("debate.voteNo")}
                        </p>
                    </div>
                )
                break
            case "inviteFriends":
                setContent(
                    <div>
                        <label className={`label-${theme}`}>{t("debate.inviteLabel")}</label>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                            <input 
                                className={`input border-${theme}-lowContrast placeholder-${theme} color-${theme}-text`}
                                style={{ width: "94%" }}
                                onChange={(e) => {
                                    console.log(e.target.value)
                                    Store.dispatch({
                                        type: "USER_TO_INVITE",
                                        payload: e.target.value
                                    })
                                }}>
                            </input>
                            <button
                                onClick={() => {sendInvite()}}
                                className={`btn bg-${theme}-contrast color-${theme}-onContrast`}>
                                {t("debate.inviteBtn")}
                            </button>
                        </div>
                    </div>)
            default:
                break
        }
    }, [theme])

    return (
        <div id={props.contentType} className={
            `home-side-component-wrapper ${props.contentType}
            bg-${theme}-${props.bg} ${typeof props.border != "string" ? `shadow-${theme}` : null}
            ${typeof props.border === "string" ? props.border : null} color-${theme}-${props.color}`}
            onClick={props.onClick != undefined ? () => props.onClick() : null }>
            <div className={`home-side-component`}>
                {props.title != "" ? <h1 className={`home-side-title`}>{props.title}</h1> : null}
                {content}
            </div>
        </div>
    )

}

export default HomeSideComponent