import { t } from "i18next";

const getTimeEllapsed = (originTmstp) => {

    const diff = (Date.now() - originTmstp) / 1000

    const minutesPassed = Math.round(diff / 60)
    const hoursPassed = Math.round(minutesPassed / 60)

    if (minutesPassed < 60) {
        return `${t("debate.prefixe")} ${minutesPassed} ${t("debate.suffixes.min")}`
    } else if (hoursPassed >= 1 && hoursPassed < 24) {
        return `${t("debate.prefixe")} ${hoursPassed} ${t("debate.suffixes.hour")}`
    } else {
        return `${t("debate.prefixe")} ${Math.floor(hoursPassed / 24)} ${t("debate.suffixes.day")}`
    }
}

export default getTimeEllapsed