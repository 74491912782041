import Home from './components/reusable/Home';
import EmailConfirm from './components/externals/EmailConfirm';
import Login from './components/utils/Login';
import SignUp from './components/utils/SignUp';
import ForgotPassword from './components/utils/ForgotPassword';
import NewDebate from './components/utils/NewDebate';
import Debate from './components/Debate';
import Topic from './components/Topic';
import Account from './components/Account';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"

const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/signup" element={<SignUp/>} />
        <Route path="/newdebate" element={<NewDebate/>} />
        <Route path="/forgot-password" element={<ForgotPassword/>} />
        <Route path="/api/auth/confirm/:user/:emailtoken" element={<EmailConfirm/>} />
        <Route path="/debate/:topic/:id" element={<Debate/>} />
        <Route path="/topic/:topic" element={<Topic/>}/>
        <Route path="/account" element={<Account/>}/>
      </Routes>
    </Router>
  );
}

export default App;
