import {
    Link,
    useNavigate
} from "react-router-dom"
import { Trans } from 'react-i18next'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import Api from '../../API/Api'
import topicsTrans from '../../utils/topicsTrans'
import Store from '../../Store'
import Select from 'react-select'

const api = new Api


// Data prompted to the user:
// - question (debate)
// - why does he opens this debate ? (optional)
// - domain
// - type
// - if private: invite guests
const NewDebate = () => {

    const [step, setStep] = useState(1)
    const [userExist, setUserExist] = useState(false)
    const [user, setUser] = useState('')
    const [errorTitle, setErrorTitle] = useState(t("newDebate.error"))
    const [errorTopic, setErrorTopic] = useState(t("newDebate.errorTopic"))
    const [errorUnknown, seterrorUnknown] = useState(t("newDebate.errorUnknown"))
    const [success, setSuccess] = useState(t("newDebate.success"))
    const [contentLoaded, setContentLoaded] = useState(false)
    const [debateObject, setDebateObject] = useState(Store.getState().newDebateData)
    const [topics, setTopics] = useState()
    const [topic, setTopic] = useState("default")
    const [type, setType] = useState('public')
    const [optionsSelect, setOptionsSelect] = useState([])
    const [theme, setTheme] = useState(Store.getState().theme)

    const [values, setValues] = useState([])

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
        setDebateObject(Store.getState().newDebateData)
    })

    const navigate = useNavigate()

    // Detect language for topics translation
    // Check that user is loged in
    //    if yes -> allow to open debate
    // if no -> show register and login buttons
    useEffect(() => {

        if (!contentLoaded) {
            let userLocal = localStorage.getItem('persistant-login-id')

            if (userLocal != undefined) {
                setUserExist(true)

                if (userLocal[0]) {
                    setUser(userLocal)
                } else {
                    setUser(userLocal)
                }

                // Since the debate topics list is large, translations aren't
                // done under the i18n module but directly within 'topicsTrans.js'
                // and on component mount, we generate a <Select> for topics
                // We use the react-select module for the select rendering
                const language = navigator.language
                let topics = []
                try {
                    Object.keys(topicsTrans[language]).map(topic => {
                        const label = topicsTrans[language][topic]
                        topics.push({value: topic, label})
                    })
                } catch {
                    const topicKey = Object.keys(topicsTrans)[0]
                    Object.keys(topicsTrans[topicKey]).map(topic => {
                        topics.push({value: topic, label: topic})
                    })
                }
                setTopics(topics)

                // Check for friends
                api.getFriends(userLocal)
                .then(response => {
                    try {
                        let friends = response.data.friends
                        let options = []
                        friends.map(friend => {
                            options.push({ value: friend, label: friend })
                        })
                        setOptionsSelect(options)
                    } catch (error) {
                        console.log(error)
                    }
                    
                })
                .catch(error => {
                    
                })
                
            } else {
                navigate('/signup')
            }
            setContentLoaded(true)
        }
    })

    const nextStep = () => {

        if (debateObject.title.length < 5) {
            document.getElementById('new-debate-error-dialog').style.display = 'block'
            setTimeout(() => {
                document.getElementById('new-debate-error-dialog').style.display = 'none'
            }, 5000)
        } else {
            document.getElementById('new-debate-title').value = ''
            document.getElementById('new-debate-reason').value = ''
            setStep(2)
        }
    }

    const submit = async () => {

        // Show error
        if (debateObject.topic === "default" && topic === "default") {

            document.getElementById('new-debate-error-topic-dialog').style.display = 'block'
            setTimeout(() => {
                document.getElementById('new-debate-error-topic-dialog').style.display = 'none'
            }, 5000)

        } else {

            let userLocal = localStorage.getItem('persistant-login-id')

            if (userLocal != undefined) {

                const topicValue = debateObject.topic === "default" ? topic : debateObject.topic

                // Get the user language
                const language = localStorage.getItem('i18nextLng')

                api.newDebate(debateObject.title, debateObject.reason, debateObject.type, userLocal, debateObject.guests, topicValue, language)
                .then(response => {

                    if (response.data.success) {

                        document.getElementById('new-debate-success-dialog').style.display = 'block'
                            setTimeout(() => {
                            // Reset form inputs to defaults
                            Store.dispatch({
                                type: 'CREATE_NEW_DEBATE',
                                payload: { title: '', reason: '', type: "public", guests: null, topic: 'default' }
                            })

                            setType('public')
                            setTopic('default')
                            document.getElementById('new-debate-success-dialog').style.display = 'none'
                            // Navigate to the freshly created debate
                            navigate(`/debate/${response.data.topic}/${response.data.id}`)
                        }, 2000)
                    } else {
                        document.getElementById('new-debate-error-unknow-dialog').style.display = 'block'
                        setTimeout(() => {
                            document.getElementById('new-debate-error-unknow-dialog').style.display = 'none'
                        }, 5000)
                    }
                })
                .catch(error => {
                    document.getElementById('new-debate-error-unknow-dialog').style.display = 'block'
                    setTimeout(() => {
                        document.getElementById('new-debate-error-unknow-dialog').style.display = 'none'
                    }, 5000)
                })

            } else {
                document.getElementById('new-debate-error-unknow-dialog').style.display = 'block'
                setTimeout(() => {
                    document.getElementById('new-debate-error-unknow-dialog').style.display = 'none'
                }, 5000)
            }
        }
    }

    const handleChange = (option) => {
        setValues(option)

        let debateData = Store.getState().newDebateData
        debateData.guests = []
        option.map(opt => {
            debateData.guests.push(opt.value)
        })

        Store.dispatch({
            type: 'CREATE_NEW_DEBATE',
            payload: debateData
        })
    }

    return (
        <>
            <div className={`full-page-form bg-${theme}-main`}>

                <div className='fixed' id="fixed-logo-wrapper">
                    <Link className={`logo color-${theme}-text`} to="/">Dbaato!</Link>
                </div>
                <div className='form-wrapper'>
                    <>
                        <div className={`form bg-${theme}-component color-${theme}-text`}>
                            <h1 className={`title color-${theme}-text`}><Trans i18nKey={"newDebate.openDebate"}/></h1>
                            {step === 1 ? 
                            <>
                                <form>
                                    <div className={`form-content`}>
                                        <label className={`label-${theme}`}>{<Trans i18nKey={"newDebate.openDebateText"}/>}</label>
                                        <textarea
                                            placeholder={`${t("newDebate.openDebatePlaceholder1")}`}
                                            style={{ backgroundColor: 'transparent' }}
                                            id="new-debate-title"
                                            onChange={(e) => {
                                                let newObject = debateObject
                                                newObject['title'] = e.target.value
            
                                                Store.dispatch({
                                                    type: 'CREATE_NEW_DEBATE',
                                                    payload: newObject
                                                })
                                            }}
                                            className={`input border-${theme} placeholder-${theme} color-${theme}-text`}
                                            >
                                        </textarea>
                                        <label className={`label-${theme}`}>{<Trans i18nKey={"newDebate.openDebateReason"}/>}</label>
                                        <textarea
                                            placeholder={`${t("newDebate.openDebatePlaceholder2")}`}
                                            id="new-debate-reason"
                                            style={{ backgroundColor: 'transparent' }}
                                            onChange={(e) => {
                                                let newObject = debateObject
                                                newObject['reason'] = e.target.value
            
                                                Store.dispatch({
                                                    type: 'CREATE_NEW_DEBATE',
                                                    payload: newObject
                                                })
                                            }}
                                            className={`input border-${theme} placeholder-${theme} color-${theme}-text`}
                                            maxLength="5000">
                                        </textarea>
                                    </div>
                                </form>
                                <button className={`btn bg-${theme}-contrast color-${theme}-onContrast`} onClick={() => {nextStep()}}>
                                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        {<Trans i18nKey={"newDebate.next"}></Trans>}
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path className={`fill-${theme}-onContrast`} d="M17.8299 14.29L13.5899 10.05C13.497 9.95627 13.3864 9.88187 13.2645 9.8311C13.1427 9.78033 13.012 9.7542 12.8799 9.7542C12.7479 9.7542 12.6172 9.78033 12.4954 9.8311C12.3735 9.88187 12.2629 9.95627 12.1699 10.05C11.9837 10.2374 11.8792 10.4908 11.8792 10.755C11.8792 11.0192 11.9837 11.2726 12.1699 11.46L15.7099 15L12.1699 18.54C11.9837 18.7274 11.8792 18.9808 11.8792 19.245C11.8792 19.5092 11.9837 19.7626 12.1699 19.95C12.2634 20.0427 12.3742 20.116 12.496 20.1658C12.6179 20.2155 12.7483 20.2408 12.8799 20.24C13.0115 20.2408 13.142 20.2155 13.2638 20.1658C13.3857 20.116 13.4965 20.0427 13.5899 19.95L17.8299 15.71C17.9237 15.617 17.9981 15.5064 18.0488 15.3846C18.0996 15.2627 18.1257 15.132 18.1257 15C18.1257 14.868 18.0996 14.7373 18.0488 14.6154C17.9981 14.4936 17.9237 14.383 17.8299 14.29Z"/>
                                            
                                        </svg>
                                    </div>
                                </button>
                            </>
                            : 
                            <>
                                <form>
                                    <div className={`form-content`}>
                                    
                                        <div id="center">
                                            <div id="debate-type-buttons">
                                                <div
                                                    id="new-debate-public-btn"
                                                    className={`bg-${theme}-contrast`}
                                                    onClick={() => {

                                                        setType('public')
                                                        let newObject = debateObject

                                                        // Change classname to update style
                                                        if (newObject.type === 'private') {
                                                            document.getElementById("new-debate-public-btn").className = `bg-${theme}-contrast`
                                                            document.getElementById("new-debate-private-btn").className = `bg-${theme}-lowContrast`
                                                            document.getElementById("new-debate-public-title").className = `box-title color-${theme}-main`
                                                            document.getElementById("new-debate-public-description").className = `box-description  color-${theme}-main`
                                                            document.getElementById("new-debate-private-title").className = `box-title color-${theme}-textLow`
                                                            document.getElementById("new-debate-private-description").className = `box-description  color-${theme}-textLow`
                                                            document.getElementById("new-debate-private-shape").setAttribute("class", `fill-${theme}-textLow`)
                                                            document.getElementById("new-debate-public-shape").setAttribute("class", `fill-${theme}-contrast`)
                                                        } 
                                                        
                                                        newObject['type'] = 'public'

                                                        Store.dispatch({
                                                            type: 'CREATE_NEW_DEBATE',
                                                            payload: newObject
                                                        })

                                                    }}>
                                                    <svg className={`fill-${theme}-contrast`} width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
                                                        <path id="new-debate-public-shape" d="M25.5 13.5H13.5V10.5C13.4978 9.60928 13.76 8.73795 14.2534 7.99636C14.7468 7.25476 15.4491 6.67627 16.2715 6.33414C17.0939 5.99201 17.9993 5.90164 18.8731 6.07447C19.7469 6.2473 20.5498 6.67556 21.18 7.305C21.7439 7.8811 22.1473 8.59474 22.35 9.375C22.3992 9.56608 22.4856 9.74558 22.6043 9.90326C22.7229 10.0609 22.8714 10.1937 23.0413 10.294C23.2113 10.3943 23.3993 10.4601 23.5947 10.4878C23.79 10.5154 23.9889 10.5042 24.18 10.455C24.3711 10.4058 24.5506 10.3194 24.7083 10.2007C24.8659 10.0821 24.9987 9.93361 25.099 9.76368C25.1993 9.59375 25.2651 9.40573 25.2928 9.21035C25.3204 9.01498 25.3092 8.81608 25.26 8.625C24.9183 7.3272 24.2405 6.1424 23.295 5.19C22.2453 4.14355 20.909 3.43161 19.4549 3.14407C18.0009 2.85653 16.4942 3.00629 15.1252 3.57444C13.7562 4.14258 12.5862 5.10364 11.763 6.33626C10.9398 7.56888 10.5003 9.01778 10.5 10.5V13.5C9.30653 13.5 8.16193 13.9741 7.31802 14.818C6.47411 15.6619 6 16.8065 6 18V28.5C6 29.6935 6.47411 30.8381 7.31802 31.682C8.16193 32.5259 9.30653 33 10.5 33H25.5C26.6935 33 27.8381 32.5259 28.682 31.682C29.5259 30.8381 30 29.6935 30 28.5V18C30 16.8065 29.5259 15.6619 28.682 14.818C27.8381 13.9741 26.6935 13.5 25.5 13.5ZM27 28.5C27 28.8978 26.842 29.2794 26.5607 29.5607C26.2794 29.842 25.8978 30 25.5 30H10.5C10.1022 30 9.72064 29.842 9.43934 29.5607C9.15804 29.2794 9 28.8978 9 28.5V18C9 17.6022 9.15804 17.2206 9.43934 16.9393C9.72064 16.658 10.1022 16.5 10.5 16.5H25.5C25.8978 16.5 26.2794 16.658 26.5607 16.9393C26.842 17.2206 27 17.6022 27 18V28.5Z"/>
                                                    </svg>
                                                    <h2 className={`box-title color-${theme}-main`} id="new-debate-public-title">{<Trans i18nKey={"newDebate.public"} />}</h2>
                                                    <p className={`box-description color-${theme}-main`} id="new-debate-public-description">{<Trans i18nKey={"newDebate.publicDescription"} />}</p>
                                                </div>
                                                <div
                                                    id="new-debate-private-btn"
                                                    className={`bg-${theme}-lowContrast`}
                                                    onClick={() => {

                                                        setType('private')                                               
                                                        let newObject = debateObject

                                                        // Change classname to update style
                                                        if (newObject.type === 'public') {
                                                            document.getElementById("new-debate-public-btn").className = `bg-${theme}-lowContrast`
                                                            document.getElementById("new-debate-private-btn").className = `bg-${theme}-contrast`
                                                            document.getElementById("new-debate-public-title").className = `box-title color-${theme}-textLow`
                                                            document.getElementById("new-debate-public-description").className = `box-description  color-${theme}-textLow`
                                                            document.getElementById("new-debate-private-title").className = `box-title color-${theme}-main`
                                                            document.getElementById("new-debate-private-description").className = `box-description  color-${theme}-main`
                                                            document.querySelector("#new-debate-private-shape").setAttribute("class", `fill-${theme}-contrast`)
                                                            document.getElementById("new-debate-public-shape").setAttribute("class", `fill-${theme}-textLow`)
                                                        } 
                                                        
                                                        newObject['type'] = 'private'

                                                        Store.dispatch({
                                                            type: 'CREATE_NEW_DEBATE',
                                                            payload: newObject
                                                        })
                                                    }}>
                                                    <svg  className={`fill-${theme}-textLow`} width="36" height="36" viewBox="0 0 36 36" fill="current-color" xmlns="http://www.w3.org/2000/svg">
                                                        <path id="new-debate-private-shape" d="M25.5 13.5V10.5C25.5 8.51088 24.7098 6.60322 23.3033 5.1967C21.8968 3.79018 19.9891 3 18 3C16.0109 3 14.1032 3.79018 12.6967 5.1967C11.2902 6.60322 10.5 8.51088 10.5 10.5V13.5C9.30653 13.5 8.16193 13.9741 7.31802 14.818C6.47411 15.6619 6 16.8065 6 18V28.5C6 29.6935 6.47411 30.8381 7.31802 31.682C8.16193 32.5259 9.30653 33 10.5 33H25.5C26.6935 33 27.8381 32.5259 28.682 31.682C29.5259 30.8381 30 29.6935 30 28.5V18C30 16.8065 29.5259 15.6619 28.682 14.818C27.8381 13.9741 26.6935 13.5 25.5 13.5ZM13.5 10.5C13.5 9.30653 13.9741 8.16193 14.818 7.31802C15.6619 6.47411 16.8065 6 18 6C19.1935 6 20.3381 6.47411 21.182 7.31802C22.0259 8.16193 22.5 9.30653 22.5 10.5V13.5H13.5V10.5ZM27 28.5C27 28.8978 26.842 29.2794 26.5607 29.5607C26.2794 29.842 25.8978 30 25.5 30H10.5C10.1022 30 9.72064 29.842 9.43934 29.5607C9.15804 29.2794 9 28.8978 9 28.5V18C9 17.6022 9.15804 17.2206 9.43934 16.9393C9.72064 16.658 10.1022 16.5 10.5 16.5H25.5C25.8978 16.5 26.2794 16.658 26.5607 16.9393C26.842 17.2206 27 17.6022 27 18V28.5Z" fill="current-color"/>
                                                    </svg>
                                                    <h2 className={`box-title color-${theme}-textLow`} id="new-debate-private-title">{<Trans i18nKey={"newDebate.private"} />}</h2>
                                                    <p className={`box-description color-${theme}-textLow`} id="new-debate-private-description">{<Trans i18nKey={"newDebate.privateDescription"} />}</p>
                                                </div>
                                            </div>

                                            {type === 'private' ? 
                                                // If private debate and user has friends : show select + info
                                                // elif private debate and no friends : show error no friends
                                                // else (public debate) : do not show anything
                                                <>
                                                    {optionsSelect.length > 0 ?
                                                        <>
                                                            <Select
                                                                id="multi-select-friends"
                                                                name="guests"
                                                                placeholder={t("newDebate.guests")}
                                                                value={values}
                                                                options={optionsSelect}
                                                                onChange={handleChange}
                                                                isMulti="true"
                                                                //className={`input border-${theme} placeholder-${theme} label-${theme}`}
                                                            />
                                                            <p id="multi-select-friends" className={`color-${theme}-text`}>{<Trans i18nKey={"newDebate.guestsInfo"}/>}</p>
                                                        </>
                                                        :
                                                        <p id="multi-select-friends" className={`color-${theme}-text`}>{<Trans i18nKey={"newDebate.guestsNoFriends"} />}</p>
                                                    }
                                                </>
                                                :
                                                null
                                            }

                                            
                                            <select
                                                className={`input border-${theme} placeholder-${theme} label-${theme}`}
                                                value={topic}
                                                onChange={(e) => {
                                                    let newObject = debateObject
                                                    newObject['topic'] = e.target.value

                                                    setTopic(e.target.value)

                                                    Store.dispatch({
                                                        type: 'CREATE_NEW_DEBATE',
                                                        payload: newObject
                                                    })
                                                }}>
                                                <option value="default">{t("newDebate.topicDefault")}</option>
                                                {topics.map(topic => {
                                                    return (
                                                        <option value={topic.value} key={Math.random()}>
                                                            {topic.label}
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                                <button className={`btn bg-${theme}-contrast color-${theme}-onContrast`} onClick={() => {submit()}}>
                                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                        {<Trans i18nKey={"newDebate.next"}></Trans>}
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path className={`fill-${theme}-onContrast`} d="M17.8299 14.29L13.5899 10.05C13.497 9.95627 13.3864 9.88187 13.2645 9.8311C13.1427 9.78033 13.012 9.7542 12.8799 9.7542C12.7479 9.7542 12.6172 9.78033 12.4954 9.8311C12.3735 9.88187 12.2629 9.95627 12.1699 10.05C11.9837 10.2374 11.8792 10.4908 11.8792 10.755C11.8792 11.0192 11.9837 11.2726 12.1699 11.46L15.7099 15L12.1699 18.54C11.9837 18.7274 11.8792 18.9808 11.8792 19.245C11.8792 19.5092 11.9837 19.7626 12.1699 19.95C12.2634 20.0427 12.3742 20.116 12.496 20.1658C12.6179 20.2155 12.7483 20.2408 12.8799 20.24C13.0115 20.2408 13.142 20.2155 13.2638 20.1658C13.3857 20.116 13.4965 20.0427 13.5899 19.95L17.8299 15.71C17.9237 15.617 17.9981 15.5064 18.0488 15.3846C18.0996 15.2627 18.1257 15.132 18.1257 15C18.1257 14.868 18.0996 14.7373 18.0488 14.6154C17.9981 14.4936 17.9237 14.383 17.8299 14.29Z"/>
                                            
                                        </svg>
                                    </div>
                                </button>
                            </>
                            }
                        </div>
                    </>
                </div>
            </div>
            <div className={`dialog bg-${theme}-red`} id="new-debate-error-dialog">
                <p className={`color-light-main`}>{ errorTitle }</p>
            </div>
            <div className={`dialog bg-${theme}-red`} id="new-debate-error-topic-dialog">
                <p className={`color-light-main`}>{ errorTopic }</p>
            </div>
            <div className={`dialog bg-${theme}-red`} id="new-debate-error-unknow-dialog">
                <p className={`color-light-main`}>{ errorUnknown }</p>
            </div>
            <div className={`dialog bg-${theme}-green`} id="new-debate-success-dialog">
                <p className={`color-light-main`}>{ success }</p>
            </div>
        </>
    )
}

export default NewDebate