import Store from "../../../Store"
import { useEffect, useState } from "react"
import ProfilePicMini from "../ProfilePicMini"

const FollowingArg = (props) => {

    const [theme, setTheme] = useState(Store.getState().theme)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })
    useEffect(() => {
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth)
        })
    }, [])

    return (
        <a href={`/debate/${props.debateDomain}/${props.debateId}`} id={Math.random()} className={`bg-${theme}-component shadow-${theme} home-arg-wrapper color-${theme}-text ${windowWidth <= 570 ? `border-${theme}-lowContrast` : null}`}>
            <div className={`home-arg`}>
                <div className={`home-arg-header`}>
                    <div className="home-arg-header-pp-author-title">
                        <div>
                            <ProfilePicMini
                                profilePicLink={props.profilePics[props.author]}
                                border={`border-${theme}`}
                            />
                        </div>
                        <div>
                            <p className="home-arg-author">{props.author}</p>
                            <p className={`label-${theme}`}>{props.debateTitle}</p>
                        </div>
                    </div>
                </div>
                <div className={`home-arg-content`}>
                    <p>"{props.content}"</p>
                </div>
            </div>
        </a>
    )

}

export default FollowingArg