const getBreakpoint = () => {

    const screenWidth = window.innerWidth

    if (screenWidth >= 1700) {
        return "big"
    } else if (screenWidth >= 1500 && screenWidth < 1700) {
        return "medium"
    } else if (screenWidth >= 1000 && screenWidth < 1500) {
        return "small"
    } else {
        return "extraSmall"
    }
}

export default getBreakpoint