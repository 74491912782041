import { useEffect, useState } from "react";
import Store from "../Store";
import { t } from "i18next"
import Header from "./common/Header";
import ThemeSwitch from "./reusable/ThemeSwitch";
import { useParams, Navigate } from "react-router-dom";
import Api from "../API/Api";
import TopicDebate from "../components/reusable/TopicDebate"
import Loading from "./reusable/Loading";
import topicsTrans from "../utils/topicsTrans";

const api = new Api

const Topic = () => {

    const [topic] = useState(useParams().topic)
    const [topicTranslated, setTopicTranslated] = useState(topic)
    const [debates, setDebates] = useState([])
    const [contentLoaded, setContentLoaded] = useState(false)
    const [theme, setTheme] = useState(Store.getState().theme)

    useEffect(() => {

        Object.keys(topicsTrans).includes(localStorage.getItem('i18nextLng')) ? 
            setTopicTranslated(topicsTrans[localStorage.getItem('i18nextLng')][topic]) : setTopicTranslated(topic)

        Store.dispatch({
            type: 'UPDATE_NAVIGATION_HISTORY',
            payload: "topic"
        })

        api.getTopicDebates(topic)
        .then(res => {
            if (res.data.success && res.data.content) {
                const homeDebates = []
                res.data.debates.forEach(debate => {

                    let yesPct = (debate.voted_yes / (debate.voted_no + debate.voted_yes)) * 100

                    try {
                        debate.top_pro = JSON.parse(debate.top_pro)
                    } catch (err) {}

                    try {
                        debate.top_con = JSON.parse(debate.top_con)
                    } catch (err) {}

                    homeDebates.push(
                        <TopicDebate
                            title={debate.title}
                            id={debate.id}
                            domain={debate.domain}
                            nb_arguments={debate.nb_arguments}
                            nb_participants={debate.nb_participants}
                            openTimestamp={debate.open_timestamp}
                            votedNo={debate.voted_no}
                            votedYes={debate.voted_yes}
                        />
                    )
                })

                setDebates(homeDebates)
                setContentLoaded(true)
            }
        })
        .catch(err => {
            console.log(err)
        })

    }, [])

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    return (
        <>
            <Header />
            <main className={`main-wrapper bg-${theme}-main`}>
                <div className='main'>
                    <div id="topic-debates-wrapper">
                        <div id="topic-debates">
                            <div id="topic-title-wrapper">
                                <h1 className={`color-${theme}-text`}>
                                    {`${t("topic.title")} ${topicTranslated}`}
                                </h1>
                            </div>
                            {
                                contentLoaded ? debates : <Loading/>
                            }
                        </div>
                    </div>
                </div>
                <div id="log"></div>
                <ThemeSwitch/>
            </main>
        </>
    )

}

export default Topic