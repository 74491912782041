import axios from "axios";

class Api {

    constructor () {
        this.baseUrl = 'https://dbaato-back.herokuapp.com/'
        this.controller = new AbortController()
        this.searchs = []
        axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*'
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*'
    }

    // Manage the token refreshing process automatically
    // Routes with token auth middleware will be reached from this function
    // It tries to reach the endpoint and automatically refreshes
    // the token if it is invalid and makes an other call after saving the new token
    // If token still unrefreshable, login again to get fresh tokens and make request again
    autoRefreshedRequest = async (endpoint, body, method) => {

        const response = await axios({
            baseURL: this.baseUrl,
            url: endpoint,
            method,
            data: body != null ? body : null,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('persistant-login-token')}`
            }
        })

        if (!response.data.success) {
            if (response.data.reason === "invalid-token") {
                // Refresh the token
                const refreshed = await this.refreshToken()
                // Do the request again
                if (refreshed.data.success) {
                    localStorage.setItem('persistant-login-token', refreshed.data.token)
                    return axios({
                        baseURL: this.baseUrl,
                        url: endpoint,
                        method,
                        data: body != null ? body : null,
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('persistant-login-token')}`
                        }
                    })
                    .then(res => {
                        return res
                    })
                    .catch(error => {
                        return {success: false, reason: error}
                    })
                } else {
                    // If the token can not be refreshed, it means that refresh token
                    // probably expired, so we try to login again to reset login data
                    const loginResponse = await this.login({
                        email: null,
                        uid: localStorage.getItem('persistant-login-id'),
                        password: localStorage.getItem('persistant-login-psswd') 
                    })

                    if (loginResponse.data.success) {
                        localStorage.setItem('persistant-login-email', loginResponse.data.email)
                        localStorage.setItem('persistant-login-emailVerified', loginResponse.data.verifiedEmail)
                        localStorage.setItem('persistant-login-token', loginResponse.data.tokens.token)
                        localStorage.setItem('persistant-login-refresh', loginResponse.data.tokens.refresh)

                        return axios({
                            baseURL: this.baseUrl,
                            url: endpoint,
                            method,
                            data: body != null ? body : null,
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('persistant-login-token')}`
                            }
                        })
                        .then(res => {
                            return res
                        })
                        .catch(error => {
                            return {success: false, reason: error}
                        })

                    } else {
                        return {success: false, reason: "could not refresh token"}
                    }
                }
            }
        } else {
            return response
        }

    }

    // Refresh token: send refresh token to get a new token
    refreshToken = async () => {
        const response = await axios.post(
            this.baseUrl + 'api/auth/refreshtoken',
            {uid: localStorage.getItem('persistant-login-psswd'), password: localStorage.getItem('persistant-login-id')},
            {
                headers: {
                    'Authorization': 
                        `Bearer ${localStorage.getItem('persistant-login-refresh')}`
                }
            }
        )

        return response
    }

    // Login function. returns api keys (token + refresh) if success. 
    // Otherwise returns an error
    login = async (credentials) => {
        return await axios.post(
            this.baseUrl + 'api/auth/login',
            { user: credentials }
        )
    }

    // Function triggered when a new user registers. Create an user in the db
    register = async (email, emailConsent, uid, password, language, birthdate, sex, region) => {

        if (emailConsent) {
            emailConsent = 1
        } else {
            emailConsent = 0
        }

        return await axios.post(this.baseUrl + 'api/auth/register',
            {
                user: {
                    email,
                    emailConsent,
                    uid,
                    password,
                    language,
                    birthdate,
                    sex,
                    region
                }
            }
        )
    }

    // When an user registers, this function is triggered and backend
    // sends a verification email (with a link) to the user
    confirmEmail = async (user, emailToken) => {

        return await axios.get(
            this.baseUrl + `api/auth/confirm/${user}/${emailToken}`
        )
    }

    // This function generates a link, sent by email to the user
    // that will allow the user to get the password reset form
    resetPassword = async (email) => {

        return await axios.post(
            this.baseUrl + 'api/auth/passwordresetrequest',
            { email }
        )
    }

    // This function is used by the form allowing user to change passwd
    changePassword = async (password, token) => {

        return await axios.post(
            this.baseUrl + 'api/auth/passwordresetclient',
            { token, password}
        )
    }

    // On password reset request, verify code
    verifyResetCode = async (code, email) => {

        return await axios.post(
            this.baseUrl + 'api/auth/passwordcode',
            { code, email}
        )
    }

    // Check if the email is already used
    isEmailUsed = async (email) => {

        return await axios.post(
            this.baseUrl + 'api/auth/checkemailused',
            { email }
        )
    }

    // Check that the uid is available on signup
    isUidUsed = async (uid) => {

        return await axios.post(
            this.baseUrl + 'api/auth/checkuidused',
            { uid }
        )
    }

    // First-time profile-pic set
    setProfilePic = async (picId, file) => {
        const extension = file.type
        const form = new FormData

        if (extension === "image/jpeg" || extension === "image/jpg" || 
            extension === "image/png" || extension === "image/psd" ||
            extension === "image/ai" || extension === "image/svg") {

            const toBase64 = (file) => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });

            const base64 = await toBase64(file)

            form.append('base64', base64)
            form.append('picId', picId)

            const response = await axios.post(
                this.baseUrl + 'api/setProfilePic',
                form
            )

            return { success: response.data.success, base64 }

        } else {
            // Throw extension error
            return { data: { success: false, error: "incorrectExtension" } }
        }
    }

    editProfilePic = async (picId, file, uid) => {

        const extension = file.type
        const form = new FormData

        if (extension === "image/jpeg" || extension === "image/jpg" || 
            extension === "image/png" || extension === "image/psd" ||
            extension === "image/ai" || extension === "image/svg") {

            const toBase64 = (file) => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });

            const base64 = await toBase64(file)

            form.append('base64', base64)
            form.append('picId', picId)
            form.append('field', 'pic')
            form.append('uid', uid)

            const response = await this.autoRefreshedRequest(
                'api/edit-user',
                form,
                "post"
            )
            return response

        } else {
            // Throw extension error
            console.log(extension)
            return { success: false, message: "incorrectExtension" }
        }
    }

    // Get users friend
    getFriends = async (uid) => {

        return await axios.post(
            this.baseUrl + 'api/getfriends',
            { uid }
        )
    }

    // Generate a new debate
    newDebate = async (title, reason, type, uid, friends, topic, language) => {

        return await this.autoRefreshedRequest(
            'api/newdebate',
            { title, reason, type, uid, friends, topic, language },
            "post"
        )
    }

    // Get home data - can be triggered by a register & not registered user
    // We check if uid & token exists in localStorage to see if user is registered
    // 2 different routes: /api/home/:user
    getHomeContent = async (
        paginationStartDebates, paginationLimitDebates, 
        paginationStartArgs, paginationLimitArgs, onlyGetFeed) => {

        const lsUid = localStorage.getItem('persistant-login-id')
        const lsToken = localStorage.getItem('persistant-login-token')

        // If user is loged in, use the auto refreshed request
        if (typeof(lsUid) === "string" && typeof(lsToken) === "string") {
            
            return await this.autoRefreshedRequest(
                `api/home/:${lsUid}/:${paginationStartDebates}/:${paginationLimitDebates}/:${paginationStartArgs}/:${paginationLimitArgs}/:${onlyGetFeed}`,
                null,
                "get"
            )

        } else {
            // If user is not loged in, axios get request
            return await axios.get(`${this.baseUrl}api/home-anonymous/:${paginationStartDebates}/:${paginationLimitDebates}/:${paginationStartArgs}/:${paginationLimitArgs}/:${onlyGetFeed}`)
        }
    }

    // Get debate data
    getDebate = async (id, topic, uid) => {

        if (uid != null && uid != undefined) {
            return await this.autoRefreshedRequest(
                `api/debate/:${id}/:${topic}/:${uid}`,
                null,
                "get"
            )
        } else {
            return await this.autoRefreshedRequest(
                `api/guest/debate/:${id}/:${topic}`,
                null,
                "get"
            )
        }
    }

    // Get debate's arguments
    getArguments = async (id, uid) => {

        return await this.autoRefreshedRequest(
            `api/args/:${id}/:${uid}`,
            null,
            "get"
        )
    }

    // Add argument to debate
    addArgument = async (id, content, counterArgumentOf, type, uid, topic, title, timestamp) => {

        return await this.autoRefreshedRequest(
            'api/debate',
            { action: "add", id, content, uid, counterArgumentOf, type, topic, title, timestamp },
            "post"
        )
    }

    // Like argument
    likeArgument = async (uid, argId, debateId, topic) => {

        return await this.autoRefreshedRequest(
            'api/debate',
            { action: "like", uid, argId, debateId, topic },
            "post"
        )
    }

    // Edit argument
    editArgument = async (argId) => {

        return await this.autoRefreshedRequest(
            'api/debate',
            { action: "edit", argId },
            "post"
        )
    }

    // Delete argument
    deleteArgument = async (argId, debateId) => {

        return await this.autoRefreshedRequest(
            'api/debate',
            { action: "del", argId, id: debateId },
            "post"
        )
    }

    // Signal argument, debate or user
    signalment = async (objectId, objectType, reason, message, uid) => {

        return await this.autoRefreshedRequest(
            'api/debate',
            { 
                action: "signal",
                objectId, objectType, reason,
                message, uid
            },
            "post"
        )
    }

    // Upvote / downvote a debate
    debateVote = async (direction, debateId, uid) => {

        return await this.autoRefreshedRequest(
            'api/debate',
            {
                action: "vote",
                debateId,
                direction,
                uid
            },
            "post"
        )
    }

    // follow a debate
    debateFollow = async (debateId, uid, email, debateTitle, topic) => {

        return await this.autoRefreshedRequest(
            'api/debate',
            {
                action: "follow",
                debateId,
                uid,
                email,
                debateTitle,
                topic
            },
            "post"
        )
    }

    getPeopleToFollow = async () => {
        const uid = localStorage.getItem('persistant-login-id') != null ? localStorage.getItem('persistant-login-id') : "unknown"
        const url = uid != null ? `api/users-to-follow/:${uid}` : `api/users-to-follow-unknwon/:unknown` 
        return await this.autoRefreshedRequest(
            url,
            null,
            "get"
        )
    }

    followUnfollow = async (action, uidFollowUnfollow) => {
        return await this.autoRefreshedRequest(
            'api/follow-unfollow',
            {
                uid: localStorage.getItem('persistant-login-id'),
                action,
                uidFollowUnfollow
            },
            "post"
        )
    }

    inviteToPrivateDebate = async (guest, debateId, debate, topic) => {

        return await this.autoRefreshedRequest(
            'api/debate',
            {
                action: "inviteGuest",
                debate,
                topic,
                debateId,
                guest
            },
            "post"
        )

    }

    globalSearch = async (query) => {

        // Abort already running calls
        if (this.searchs.length > 0) {

        }

        return await axios.get(
            this.baseUrl + `api/globalSearch/:${query}`
        )
    }

    getTopicDebates = async (topic) => {

        return await axios.get(
            this.baseUrl + `api/topic/:${topic}`
        )

    }

    getUserProfile = async () => {

        return await this.autoRefreshedRequest(
            'api/profile',
            { uid: localStorage.getItem('persistant-login-id') },
            "post"
        )

    }

    editUserData = async (field, value) => {

        return await this.autoRefreshedRequest(
            'api/edit-user',
            {
                field,
                value,
                uid: localStorage.getItem('persistant-login-id')
            },
            "post"
        )
    }

    deleteAccount = async (uid) => {
        return await this.autoRefreshedRequest(
            'api/delete-account',
            {
                uid
            },
            "post"
        )
    }
}

export default Api