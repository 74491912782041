import Store from "../../Store";
import { useState } from "react";

const Loading = (props) => {

    const [theme, setTheme] = useState(Store.getState().theme)
    
    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    return (
        
        <div 
            className={`loading-wrapper`}
            >
            <div className={`loading-bar border-${theme}-contrast`}>
                <div className={`loading bg-${theme}-contrast`}>
                </div>
            </div>
        </div>

    )
}

export default Loading