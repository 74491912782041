import Store from "../../Store";
import { useState } from "react";

const ThemeSwitch = () => {

    const [theme, setTheme] = useState(Store.getState().theme)
    const [bg, setBg] = useState(Store.getState().theme === "light" ? "rgb(150, 150, 150)" : "rgb(0, 0, 0)")
    const [bgBtn, setBgBtn] = useState(Store.getState().theme === "light" ? "rgb(243, 244, 253)" : "rgb(255, 235, 43)")
    const [marginLeft, setMarginLeft] = useState(Store.getState().theme === "light" ? "3px" : "23px")

    const switchTheme = () => {

        const rail = document.getElementById("theme-switch-container")
        const btn = document.getElementById("theme-switch")

        rail.animate([{ backgroundColor: theme === "light" ? "rgb(0,0,0)" : "rgb(150, 150, 150)" }], 
            { duration: 400, easing: "ease-in-out" })
        .onfinish = () => {setBg(theme === "light" ? "#000000" : "#959595")}

        btn.animate([
            { marginLeft: theme === "light" ? "23px" : "3px" }
        ], { duration: 400, easing: "ease-in-out" })
        .onfinish = () => {
            setMarginLeft(theme === "light" ? "23px" : "3px")
            btn.animate([{ backgroundColor: theme === "light" ? "rgb(255, 235, 43)" : "rgb(243, 244, 253)" }], { duration: 200, easing: 'ease-in-out'})
            .onfinish = () => {
                setBgBtn(theme === "light" ? "rgb(255, 235, 43)" : "rgb(243, 244, 253)")
            }
        }

        localStorage.setItem('theme', theme === 'light' ? 'dark': 'light')

        Store.dispatch({
            type: 'UPDATE_THEME',
            payload: theme === "light" ? "dark" : "light"
        })

        setTheme(theme === "light" ? "dark" : "light")
    }

    return (
        <div 
            id="theme-switch-container"
            style={{ backgroundColor: bg}}
            onClick={() => {switchTheme()}}>
            <button 
                id="theme-switch"
                onClick={() => {switchTheme()}}
                style={{ backgroundColor: bgBtn, marginLeft }}>
            </button>
        </div>
    )

}

export default ThemeSwitch