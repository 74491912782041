import React from 'react'
import { Chart as ChartJS } from 'chart.js/auto'
import { Line } from "react-chartjs-2"

const DebateEvolution = (props) => {

    return (
        <Line
            data={{
                labels: props.labels,
                datasets: [
                    {
                        label: 'pros',
                        fill: false,
                        lineTension: 0,
                        backgroundColor: 'rgba(0, 255, 25, 0.8)',
                        borderColor: 'rgb(0, 255, 25)',
                        borderWidth: 2,
                        data: props.data.yes,
                        stepped: true
                    },
                    {
                        label: 'cons',
                        fill: false,
                        lineTension: 0,
                        backgroundColor: 'rgba(255, 0, 25, 0.8)',
                        borderColor: 'rgb(255, 0, 25)',
                        borderWidth: 2,
                        data: props.data.no,
                        stepped: true
                    }
                ]
            }}
            options={{
                title: {
                    display: true,
                    text: "Chart title!",
                    fontSize: 20
                },
                legend:  {
                    display: true,
                    position: "right"
                },
                responsive: true
            }}
        />
    )

}

export default DebateEvolution