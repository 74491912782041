import React, { useState, useEffect } from 'react'
import Header from '../common/Header'
import { t } from "i18next";
import {
    Navigate
} from "react-router-dom"
import Store from '../../Store';
import Api from '../../API/Api';
import HomeDebateShadow from './home/shadowComponents/HomeDebateShadow';
import HomeCarousselShadow from './home/shadowComponents/HomeCarousselShadow';
import SideBoxShadow from './home/shadowComponents/SideBoxShadow';
import HomeDebate from './home/HomeDebate';
import HomeSideComponent from './home/HomeSideComponent';
import FollowingArg from './home/FollowingArg';
import HomePaginator from '../../functions/HomePaginator';
import HomeContentInit from './HomeContentInit';
import HomeCarousel from './home/HomeCarousel';
import ThemeSwitch from './ThemeSwitch';
import SETTINGS from '../../SETTINGS';
import getBreakpoint from '../../functions/getBreakpoint';

const api = new Api
const paginator = new HomePaginator
const hci = new HomeContentInit

const Home = () => {

    const [theme, setTheme] = useState(Store.getState().theme)
    const [breakpoint, setBreakpoint] = useState(getBreakpoint())
    const [breakpointTemporary, setBreakpointTemporary] = useState(getBreakpoint())
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    const [contentLoaded, setContentLoaded] = useState(false)

    const [debates, setDebates] = useState()
    const [trendingTopics, setTrendingTopics] = useState()
    const [userStats, setUserStats] = useState()
    const [privateDebates, setPrivateDebates] = useState()
    const [peopleToFollow, setPeopleToFollow] = useState()
    const [followingUsersArgs, setFollowingUsersArgs] = useState()
    const [profilePics, setProfilePics] = useState()
    const [followingUsersArgsPics, setFollowingUsersArgsPics] = useState()
    const [debatesCarousel, setDebatesCarousel] = useState([])
    const [isEndOfFeed, setIsEndOfFeed] = useState(false)

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    const onScroll = () => {

        if (contentLoaded && paginator.canPaginate() && !isEndOfFeed) {
            
            api.getHomeContent(Store.getState().nextPaginationDebates, SETTINGS.home.paginationMinDebates, Store.getState().nextPaginationArgs, SETTINGS.home.paginationMinArgs, true)
            .then(res => {
                if (res.data.success) {

                    Store.dispatch({
                        type: 'SET_DEBATES_NEXT_PAGINATION',
                        payload: res.data.nextPaginationStartDebates
                    })

                    Store.dispatch({
                        type: 'SET_ARGS_NEXT_PAGINATION',
                        payload: res.data.nextPaginationStartArgs
                    })
                    
                    let endOfArgs = false
                    let endOfDebates = false
                    if (typeof res.data.debates === "object") {
                        setDebates([...debates, ...res.data.debates])
                    } else {
                        if (typeof res.data.debates === "string") {
                            if (res.data.debates === "none") {
                                endOfDebates = true
                            }
                        }
                    }

                    if (typeof res.data.argsFromFollowedUsers === "object") {
                        setFollowingUsersArgs([...followingUsersArgs, ...res.data.argsFromFollowedUsers])
                        let picsObj = followingUsersArgsPics

                        Object.keys(res.data.profilePicsOfArgumentsAuthors).forEach(author => {
                            picsObj[author] = res.data.profilePicsOfArgumentsAuthors[author]
                        })

                        setFollowingUsersArgsPics(picsObj)

                    } else {
                        endOfArgs = true
                    }

                    if (endOfDebates && endOfArgs) {
                        
                        setIsEndOfFeed(true)
                    } 
 
                    paginator.setIsPaginating(false)

                } else if (!res.data.success) {
                    if (res.data.message === "noMoreContent") {
                        setIsEndOfFeed(true)
                    }

                } else if (typeof res.data === "undefined") {
                    setIsEndOfFeed(true)
                }
            })
            .catch(err => {
                paginator.setIsPaginating(false)
            })
        }
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            setBreakpointTemporary(getBreakpoint())
            setWindowHeight(window.innerHeight)
        })
        Store.dispatch({
            type: 'UPDATE_NAVIGATION_HISTORY',
            payload: "home"
        })
    }, [])

    useEffect(() => {

        window.onscroll = () => {
            onScroll()
        }

        if (!contentLoaded) {

            const data = hci.returnHomeData()

            if (data.isDataReady) {
                if (breakpoint != "extraSmall") {
                    setDebatesCarousel(data.debates.slice(0, 3))
                    setDebates(data.debates.slice(3, data.debates.length))
                } else {
                    setDebatesCarousel([])
                    setDebates(data.debates)
                }
                setTrendingTopics(data.trandingTopics)
                setUserStats(data.userStats)
                setPrivateDebates(data.privateDebates)
                setPeopleToFollow(data.peopleToFollow)
                setFollowingUsersArgs(data.followingUsersArgs)
                setProfilePics(data.profilePics)
                setFollowingUsersArgsPics(data.followingUsersArgsPics)

                Store.dispatch({
                    type: 'SET_DEBATES_NEXT_PAGINATION',
                    payload: data.nextPaginationStartDebates
                })

                Store.dispatch({
                    type: 'SET_ARGS_NEXT_PAGINATION',
                    payload: data.nextPaginationStartArgs
                })

            } else {
                const checkDataReady = () => {
                    const data = hci.returnHomeData()
                    if (data.isDataReady) {
                        if (breakpoint != "extraSmall") {
                            setDebatesCarousel(data.debates.slice(0, 3))
                            setDebates(data.debates.slice(3, data.debates.length))
                        } else {
                            setDebatesCarousel([])
                            setDebates(data.debates)
                        }
                        setTrendingTopics(data.trandingTopics)
                        setUserStats(data.userStats)
                        setPrivateDebates(data.privateDebates)
                        setPeopleToFollow(data.peopleToFollow)
                        setFollowingUsersArgs(data.followingUsersArgs)
                        setProfilePics(data.profilePics)
                        setFollowingUsersArgsPics(data.followingUsersArgsPics)

                        Store.dispatch({
                            type: 'SET_DEBATES_NEXT_PAGINATION',
                            payload: data.nextPaginationStartDebates
                        })

                        Store.dispatch({
                            type: 'SET_ARGS_NEXT_PAGINATION',
                            payload: data.nextPaginationStartArgs
                        })

                    } else {
                        setTimeout(() => {
                            checkDataReady()
                        }, 2000)
                    }
                }
                checkDataReady()
            }
        }

        if (typeof debates === "object" && typeof followingUsersArgs === "object" && !contentLoaded) {
            setContentLoaded(true)
        }

    }, [contentLoaded, debates, followingUsersArgs, privateDebates, userStats, peopleToFollow, followingUsersArgsPics, profilePics, debatesCarousel])

    useEffect(() => {
        if (contentLoaded) {
            if (breakpoint === "extraSmall" && breakpointTemporary != "extraSmall") {
                setDebates(debates.slice(3, debates.length))
                setDebatesCarousel(debates.slice(0, 3))
            } else if (breakpoint != "extraSmall" && breakpointTemporary === "extraSmall") {
                setDebates([...debatesCarousel, ...debates])
                setDebatesCarousel([])
            }
            setBreakpoint(breakpointTemporary)
        }
    }, [breakpointTemporary])

    useEffect(() => {

        // Manage the sticky attribute of the side components
        if (contentLoaded) {

            if (breakpoint != "extraSmall") {

                const setSticky = () => {

                    const header = document.getElementsByTagName('header')[0]
                    const headerHeight = header.getBoundingClientRect().height

                    const leftSideWrapper = document.getElementById('home-left-side')
                    const rightSideWrapper = document.getElementById('home-right-side')
                    
                    if (leftSideWrapper.getBoundingClientRect().height < (window.innerHeight - (headerHeight + SETTINGS.home.stickyOffsetPxFromHeader))) {
                        leftSideWrapper.style.position = 'sticky'
                        leftSideWrapper.style.top = `${headerHeight + SETTINGS.home.stickyOffsetPxFromHeader}px`
                    } else {
                        leftSideWrapper.style.position = 'sticky'
                        leftSideWrapper.style.top = `${ window.innerHeight - leftSideWrapper.getBoundingClientRect().height}px`
                    }

                    if (rightSideWrapper.getBoundingClientRect().height < (window.innerHeight - (headerHeight + SETTINGS.home.stickyOffsetPxFromHeader))) {
                        rightSideWrapper.style.position = 'sticky'
                        rightSideWrapper.style.top = `${headerHeight + SETTINGS.home.stickyOffsetPxFromHeader}px`
                    } else {
                        rightSideWrapper.style.position = 'sticky'
                        rightSideWrapper.style.top = `${ window.innerHeight - rightSideWrapper.getBoundingClientRect().height}px`
                    }
                }
    
                try {
                    setSticky()
                } catch (err) {
                    setTimeout(() => {
                        setSticky()
                    }, 500)
                }
            }
        }

    }, [contentLoaded, windowHeight, debates, breakpoint])

    return (
        <>
            <Header />
            <main className={`main-wrapper bg-${theme}-main`}>
                <div className='main'>
                    <div id="home-wrapper">
                        <div id="home">
                            {
                                breakpoint != "extraSmall" ?
                                <>
                                    {
                                        !contentLoaded ?
                                        <HomeCarousselShadow/>
                                        :
                                        <HomeCarousel
                                            debates={debatesCarousel}
                                        />
                                    }
                                </>
                                :
                                null
                            }
                            <div id="home-body">
                                <span id="home-left-side">
                                    { !contentLoaded ?
                                        <>
                                            {
                                                breakpoint === "big" || breakpoint === "medium" ?
                                                <> 
                                                    <SideBoxShadow/>
                                                    <SideBoxShadow/>
                                                    <SideBoxShadow/>
                                                </>
                                                :
                                                null
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                localStorage.getItem('persistant-login-id') != undefined ?
                                                <>
                                                    {
                                                        breakpoint === "big" || breakpoint === "medium" ? 
                                                        <HomeSideComponent
                                                            title={t("home.privateDebates.title")}
                                                            contentType="privateDebates"
                                                            debates={privateDebates}
                                                            bg="component"
                                                            color="text"
                                                        />
                                                        :
                                                        null
                                                    }
                                                </>
                                                :
                                                null
                                            }
                                            {
                                                    
                                                breakpoint === "big" || breakpoint === "medium" ?
                                                <HomeSideComponent
                                                    title={t('home.trendingTopics.title')}
                                                    contentType="trending"
                                                    topics={trendingTopics}
                                                    bg="component"
                                                    color="text"
                                                />
                                                :
                                                null
                                            }
                                        </>
                                    }
                                </span>
                                <span id="home-main">
                                    <a
                                        href="/newdebate"
                                        target="_blank"
                                        id="open-debate-btn"
                                        className={`btn bg-${theme}-contrast`}
                                        >
                                        <p className={`color-${theme}-onContrast`}>{t('home.openDebate')}</p>
                                    </a>
                                    <h1 id="home-welcome" className={`color-${theme}-text`}>{t("home.welcome")}</h1>
                                    <a
                                        href="/newdebate"
                                        target="_blank"
                                        id="open-debate-floating"
                                        className={`bg-${theme}-contrast color-${theme}-onContrast`}
                                        >+
                                    </a>
                                    {
                                        !contentLoaded ?
                                        <>
                                            <HomeDebateShadow/>
                                            <HomeDebateShadow/>
                                        </>
                                        :
                                        <>
                                        {
                                            <>
                                            {
                                                breakpoint === "extraSmall" && localStorage.getItem('persistant-login-id') != undefined ?
                                                <div id="small-inline-side-components">
                                                    <HomeSideComponent
                                                        title={t("home.privateDebates.title")}
                                                        contentType="privateDebates"
                                                        debates={privateDebates}
                                                        bg="component"
                                                        color="text"
                                                    />
                                                    <HomeSideComponent
                                                        title={`${localStorage.getItem('persistant-login-id')}${t("home.userStats.title")}`}
                                                        contentType="userStats"
                                                        score={userStats.score}
                                                        nbArguments={userStats.nbArguments}
                                                        counters={userStats.counters}
                                                        yes={userStats.yes}
                                                        no={userStats.no}
                                                        bg="gradient"
                                                        color="white"
                                                    />
                                                </div>
                                                :
                                                null
                                            }
                                            {    
                                            
                                                debates.length > followingUsersArgs.length ?
                                                
                                                debates.map((debate, index) => {
                                                    const yesPct = Math.round((debate.voted_yes / (debate.voted_yes + debate.voted_no))*100)
                                                    
                                                    let afterDebate
                                                    let afterDebateComponent = null

                                                    try {
                                                        afterDebate = followingUsersArgs[index]
                                                        afterDebateComponent = 
                                                        <FollowingArg
                                                            key={Math.random()}
                                                            author={followingUsersArgs[index].author}
                                                            content={followingUsersArgs[index].content}
                                                            debateTitle={followingUsersArgs[index].title}
                                                            debateDomain={followingUsersArgs[index].domain}
                                                            debateId={followingUsersArgs[index].debate_id}
                                                            profilePics={followingUsersArgsPics}
                                                        />
                                                    } catch (err) {
                                                        afterDebateComponent = null
                                                    }
                                                    
                                                    return (
                                                        <>
                                                            {
                                                                index === SETTINGS.home.usersToFollowAppearIndex && breakpoint === "extraSmall" ?
                                                                <>
                                                                    {
                                                                        peopleToFollow.length > 0 ? 
                                                                        <HomeSideComponent
                                                                            title={t("home.peopleToFollow.title")}
                                                                            contentType="peopleToFollow"
                                                                            people={peopleToFollow}
                                                                            profilePics={profilePics}
                                                                            bg="component"
                                                                            color="text"
                                                                        />
                                                                        :
                                                                        null
                                                                    }
                                                                    <HomeDebate
                                                                        key={Math.random()}
                                                                        debateId={debate.id}
                                                                        title={debate.title}
                                                                        topShowed={
                                                                            debate.top_pro != null && debate.top_con ?
                                                                                debate.top_pro.content.length >= debate.top_con.content.length ?
                                                                                "pro" : "con"
                                                                            :
                                                                            debate.top_pro != null ?
                                                                            "pro" : "con"
                                                                        }
                                                                        topPro={debate.top_pro}
                                                                        topCon={debate.top_con}
                                                                        domain={debate.domain}
                                                                        yesPct={yesPct}
                                                                        nbArguments={debate.nb_arguments}
                                                                        userVote={debate.user_vote}
                                                                        isFollowing={debate.is_following}
                                                                        onClick={() => {Navigate(`/debate/${debate.domain}/${debate.id}`)}}
                                                                    />
                                                                    {afterDebateComponent != null ? afterDebateComponent : null}
                                                                </>
                                                                :
                                                                index === SETTINGS.home.trendingTopicsAppearIndex && breakpoint === "extraSmall" ?
                                                                <>
                                                                    {
                                                                        peopleToFollow.length > 0 ? 
                                                                        <HomeSideComponent
                                                                            title={t('home.trendingTopics.title')}
                                                                            contentType="trending"
                                                                            topics={trendingTopics}
                                                                            bg="component"
                                                                            color="text"
                                                                        />
                                                                        :
                                                                        null
                                                                    }
                                                                    <HomeDebate
                                                                        key={Math.random()}
                                                                        debateId={debate.id}
                                                                        title={debate.title}
                                                                        topShowed={
                                                                            debate.top_pro != null && debate.top_con ?
                                                                                debate.top_pro.content.length >= debate.top_con.content.length ?
                                                                                "pro" : "con"
                                                                            :
                                                                            debate.top_pro != null ?
                                                                            "pro" : "con"
                                                                        }
                                                                        topPro={debate.top_pro}
                                                                        topCon={debate.top_con}
                                                                        domain={debate.domain}
                                                                        yesPct={yesPct}
                                                                        nbArguments={debate.nb_arguments}
                                                                        userVote={debate.user_vote}
                                                                        isFollowing={debate.is_following}
                                                                        onClick={() => {Navigate(`/debate/${debate.domain}/${debate.id}`)}}
                                                                    />
                                                                    {afterDebateComponent != null ? afterDebateComponent : null}
                                                                </>
                                                                :
                                                                <>

                                                                    <HomeDebate
                                                                        key={Math.random()}
                                                                        debateId={debate.id}
                                                                        title={debate.title}
                                                                        topShowed={
                                                                            debate.top_pro != null && debate.top_con ?
                                                                                debate.top_pro.content.length >= debate.top_con.content.length ?
                                                                                "pro" : "con"
                                                                            :
                                                                            debate.top_pro != null ?
                                                                            "pro" : "con"
                                                                        }
                                                                        topPro={debate.top_pro}
                                                                        topCon={debate.top_con}
                                                                        domain={debate.domain}
                                                                        yesPct={yesPct}
                                                                        nbArguments={debate.nb_arguments}
                                                                        userVote={debate.user_vote}
                                                                        isFollowing={debate.is_following}
                                                                        onClick={() => {Navigate(`/debate/${debate.domain}/${debate.id}`)}}
                                                                    />
                                                                    {afterDebateComponent != null ? afterDebateComponent : null}
                                                                </>
                                                            }
                                                        </>
                                                    )
                                                })

                                                :

                                                followingUsersArgs.map((arg, index) => {
                                                    let debate
                                                    let beforeArgComponent = null

                                                    try {
                                                        debate = debates[index]
                                                        if (typeof debate === "object") {
                                                            const yesPct = Math.round((debate.voted_yes / (debate.voted_yes + debate.voted_no))*100)
                                                            beforeArgComponent = 
                                                            <HomeDebate
                                                                key={Math.random()}
                                                                debateId={debate.id}
                                                                title={debate.title}
                                                                topShowed={
                                                                    debate.top_pro != null && debate.top_con ?
                                                                        debate.top_pro.content.length >= debate.top_con.content.length ?
                                                                        "pro" : "con"
                                                                    :
                                                                    debate.top_pro != null ?
                                                                    "pro" : "con"
                                                                }
                                                                topPro={debate.top_pro}
                                                                topCon={debate.top_con}
                                                                domain={debate.domain}
                                                                yesPct={yesPct}
                                                                nbArguments={debate.nb_arguments}
                                                                userVote={debate.user_vote}
                                                                isFollowing={debate.is_following}
                                                                onClick={() => {Navigate(`/debate/${debate.domain}/${debate.id}`)}}
                                                            />
                                                        } else {
                                                            beforeArgComponent = null
                                                        }
                                                        
                                                    } catch (err) {
                                                        beforeArgComponent = null
                                                    }

                                                    return (
                                                        <>
                                                            {beforeArgComponent != null ? beforeArgComponent : null}
                                                            <FollowingArg
                                                                key={Math.random()}
                                                                author={arg.author}
                                                                content={arg.content}
                                                                debateTitle={arg.title}
                                                                debateDomain={arg.domain}
                                                                debateId={arg.debate_id}
                                                                profilePics={followingUsersArgsPics}
                                                            />
                                                        </>
                                                    )
                                                })}
                                            </>
                                        }
                                        </>
                                    }
                                </span>
                                <span id="home-right-side">
                                    { !contentLoaded ?
                                        <>
                                            {
                                                breakpoint != "extraSmall" ?
                                                <>
                                                    <SideBoxShadow/>
                                                    <SideBoxShadow/>
                                                    <SideBoxShadow/>
                                                </>
                                                :
                                                null
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                breakpoint != "extraSmall" ?
                                                <>
                                                    {
                                                        localStorage.getItem('persistant-login-id') != undefined ?
                                                        <>
                                                            {
                                                                breakpoint === "small" ?
                                                                <HomeSideComponent
                                                                    title={t("home.privateDebates.title")}
                                                                    contentType="privateDebates"
                                                                    debates={privateDebates}
                                                                    bg="component"
                                                                    color="text"
                                                                />
                                                                :
                                                                null
                                                            }
                                                        </>
                                                        :
                                                        null
                                                    }
                                                    {
                                                        <>

                                                            {
                                                                breakpoint === "small" ?
                                                                    <HomeSideComponent
                                                                    title={t('home.trendingTopics.title')}
                                                                    contentType="trending"
                                                                    topics={trendingTopics}
                                                                    bg="component"
                                                                    color="text"
                                                                />
                                                                :
                                                                null
                                                            }
                                                            {
                                                                localStorage.getItem('persistant-login-id') != undefined ?
                                                                <HomeSideComponent
                                                                    title={`${localStorage.getItem('persistant-login-id')}${t("home.userStats.title")}`}
                                                                    contentType="userStats"
                                                                    score={userStats.score}
                                                                    nbArguments={userStats.nbArguments}
                                                                    counters={userStats.counters}
                                                                    yes={userStats.yes}
                                                                    no={userStats.no}
                                                                    bg="gradient"
                                                                    color="white"
                                                                />
                                                                :
                                                                null
                                                            }
                                                            {
                                                                peopleToFollow.length > 0 ? 
                                                                <HomeSideComponent
                                                                    title={t("home.peopleToFollow.title")}
                                                                    contentType="peopleToFollow"
                                                                    people={peopleToFollow}
                                                                    profilePics={profilePics}
                                                                    bg="component"
                                                                    color="text"
                                                                />
                                                                :
                                                                null
                                                            }
                                                        </>
                                                    }
                                                </>
                                                :
                                                null
                                            }
                                        </>
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="log"></div>
                <ThemeSwitch/>
            </main>
        </>
    )

}

export default Home;