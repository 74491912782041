import React, { useState } from "react"
import Store from "../../Store"

const Modal = (props) => {

    const [theme, setTheme] = useState(Store.getState().theme)

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    const onClose = (e) => {
        if (e.target.className === "close-bar" || e.target.className === "modal-full" || e.target.className === "modal-close") {
            props.onClose()
        }
    }

    return (

        <div className="modal-full" onClick={(e) => {
            onClose(e)
        }}>
            <div className={`modal-wrapper bg-light-component`}>
                <div className="modal-close-wrapper bg-light-component border-bottom-light-onComponent">
                    <div
                        className="modal-close"
                        onClick={(e) => {
                            onClose(e)
                        }}>
                        <div className="close-bar"></div>
                        <div className="close-bar"></div>
                    </div>
                </div>
                <div className="modal-content">{props.content}</div>
            </div>
        </div>
    )

}

export default Modal