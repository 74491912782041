import { useEffect, useState } from "react"
import React from "react"
import Store from "../../Store"

const FloatingMenu = (props) => {

    const [theme, setTheme] = useState(Store.getState().theme)
    const [idWrapper] = useState(Math.random())
    const [idMenu] = useState(Math.random())

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    useEffect(() => {
        const masterRect = document.getElementById(props.masterId).getBoundingClientRect()
        const wrapper = document.getElementById(idWrapper)
        const menu = document.getElementById(idMenu)
        menu.style.left = `${masterRect.left - menu.getBoundingClientRect().width + masterRect.width}px`
        menu.style.top = `${masterRect.bottom+10}px`
        wrapper.style.opacity = "1"
    }, [])

    const closeMenu = (e) => {
        if (e.target.className === "floating-menu-wrapper") {
            Store.dispatch({
                type: 'SET_FLOATING_VISIBLE',
                payload: false
            })
        }
    }

    return (
        <div id={idWrapper} className="floating-menu-wrapper" onClick={(e) => {
            closeMenu(e)
            }}>
            <div id={idMenu} className={`floating-menu bg-${theme}-component border-${theme} shadow-${theme}`}>
                <div className={`floating-menu-content`}>
                    {props.content}
                </div>
            </div>
        </div>
    )


}

export default FloatingMenu