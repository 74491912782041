
import { useState, useEffect } from 'react'
import Store from '../../Store'
import Reset1 from './resetSteps/Reset1'
import Reset2 from './resetSteps/Reset2'
import Reset3 from './resetSteps/Reset3'

const steps = {
    1: <Reset1/>,
    2: <Reset2/>,
    3: <Reset3/>
}

const ForgotPassword = () => {

    const [step, setStep] = useState(Store.getState().passwordRecoveryStep)

    useEffect(() => {
        Store.dispatch({
            type: 'UPDATE_NAVIGATION_HISTORY',
            payload: "password-reset"
        })
    })

    Store.subscribe(() => {
        setStep(Store.getState().passwordRecoveryStep)
    })

    return steps[step]

}

export default ForgotPassword