import React, {useState} from "react";
import { Trans } from 'react-i18next';
import Store from "../../Store";


const AccountLogSign = () => {

    const [theme, setTheme] = useState(Store.getState().theme)

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    return (
        <>
            <div className="account-log-sign">
                <span id="login">
                    <a className={`color-${theme}-text`} id="button-login" href="/login">
                        <Trans i18nKey={"common.login"}></Trans>
                    </a>
                </span>
                <button
                    className={`btn bg-${theme}-contrast`}>
                    <a className={`color-${theme}-onContrast`} id="button-signup" href="/signup">
                        <Trans i18nKey={"common.signup"}></Trans>
                    </a>
                </button>
            </div>
        </>
    )
}

export default AccountLogSign