import Store from "../../Store"
import { useEffect, useState } from 'react'
import Api from "../../API/Api"
import getTimeEllapsed from "../../functions/getTimeEllapsed";
import { t } from "i18next";
import Modal from "./Modal";
import CounterArg from "./CounterArg";
import log from "../../functions/log";
import ProfilePicMini from "./ProfilePicMini";
import FloatingMenu from "./FloatingMenu";
import { useNavigate } from "react-router-dom";
import getBreakpoint from "../../functions/getBreakpoint";

const api = new Api

const Argument = (props) => {

    const menuBtnId = Math.random()
    const [theme, setTheme] = useState(Store.getState().theme)
    const [breakpoint, setBreakpoint] = useState(getBreakpoint())
    const [modalCounterVisible, setModalCounterVisible] = useState(false)
    const [modalSignalVisible, setModalSignalVisible] = useState(false)
    const [signalContent, setSignalContent] = useState('')
    const [newCounterArgSide, setCounterArgSide] = useState('')
    const [counterArgContent, setCounterArgContent] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [floatingMenuOpen, setFloatingMenuOpen] = useState(false)
    const counterArgsId = Math.random()

    const navigate = useNavigate()

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    const submitCounterArgument = async () => {

        let argFreeFromDQ = ""
        for (let i = 0; i < counterArgContent.length; i++) {
            if (counterArgContent[i] === '"') {
                argFreeFromDQ += "'"
            } else {
                argFreeFromDQ += counterArgContent[i]
            }
        }

        if (counterArgContent != '' && counterArgContent.length >= 5 && newCounterArgSide != '' && !submitting) {

            setSubmitting(true)

            api.addArgument(
                props.debateId, 
                argFreeFromDQ,
                props.id,
                props.vote === "yes" ? "no" : "yes",
                localStorage.getItem('persistant-login-id'),
                props.topic,
                props.title,
                Date.now()
            )
            .then(response => {
                setSubmitting(false)
                if (typeof(response) != 'undefined') {
                    if (response.data.success) {
                        setCounterArgContent('')
                        setCounterArgSide('')
                        setModalCounterVisible(false)
                        log('log', 'debate.newArgSuccess', 'success', 5000)
                        props.onAction()
                    } else {
                        log('log', 'debate.newArgError', 'error', 5000)
                    }
                } else {
                    log('log', 'debate.newArgError', 'error', 5000)
                }
            })
            .catch(error => {
                setSubmitting(false)
                log('log', 'debate.newArgError', 'error', 5000)
            })
        } else {
            log('log', 'debate.newArgIncorrect', 'error', 5000)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {setFloatingMenuOpen(false)})
        window.addEventListener('click', (e) => {
            if (floatingMenuOpen) {
                let isMenuClicked = false
                e.path.forEach(path => {
                    try {
                        if (path.className.includes("floating-menu-wrapper")) {
                            isMenuClicked = true
                        }
                    } catch (err) {}
                })
                if (!isMenuClicked) {
                    setFloatingMenuOpen(false)
                }
            }
        })
        window.addEventListener('resize', () => {
            setBreakpoint(getBreakpoint())
        })
    }, [])

    return (
        <>
            <div className="argument">
                <div className="argument-header-wrapper">
                    <div className="argument-header">
                        <ProfilePicMini profilePicLink={props.profilePics[props.author]} border={`border-${theme}-lowContrast`} customWidth={30} customHeight={30}/>
                        <div className="argument-author-vote-when">
                            <p className={`argument-author color-${theme}-text`}>{props.author}</p>
                            <div className="argument-vote-when">
                                <p className={`color-${theme}-info`}>
                                    {
                                        props.author === localStorage.getItem('persistant-login-id') ?
                                        props.vote === "yes" ? t("debate.argumentVotedYesSelf") : t("debate.argumentVotedNoSelf")
                                        :
                                        props.vote === "yes" ? t("debate.argumentVotedYes") : t("debate.argumentVotedNo")
                                    }
                                </p>
                                <span className={`argument-header-separator bg-${theme}-info`}></span>
                                <p className={`color-${theme}-info`}>{getTimeEllapsed(props.timestamp)}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`argument-content-wrapper border-${theme}-left-contrast`}>
                    <div className="argument-content">
                        <p className={`color-${theme}-text argument-text`}>{props.argument}</p>
                        <div className={`argument-vote-score-wrapper border-${theme}-ultraLowContrast-bottom`}>
                        {   
                            props.author != localStorage.getItem('persistant-login-id') ?
                            <div className="argument-vote-score">
                                <span>
                                    <svg className="icon-btn"
                                        onClick={() => {

                                            if (localStorage.getItem('persistant-login-id') == null) {
                                                navigate('/login')
                                            } else {
                                                props.onLike(
                                                    localStorage.getItem('persistant-login-id'),
                                                    props.id, props.debateId, props.topic
                                                )
                                                .then(res => {
                                                    props.onAction()
                                                })
                                                .catch(err => console.log(err))
                                            }
                                        }}
                                        width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.1762 12.432C15.8278 11.926 16.0223 11.1946 15.9355 10.387C15.8463 9.06943 15.2798 7.79294 14.1809 6.77194L10.4381 3.29415C10.4381 3.29415 9.70061 2.60831 9.0138 3.34575C8.328 4.08269 9.06594 4.76853 9.06594 4.76853L10.6471 6.2384L12.0177 7.51189C12.0177 7.51189 11.6743 7.88061 10.1573 6.47086L5.03381 1.70855C5.03381 1.70855 4.29537 1.02321 3.60906 1.76065C2.92325 2.49759 3.66069 3.18343 3.66069 3.18343L8.50546 7.68673C8.49494 7.67671 8.139 8.03341 8.13248 8.0274L2.94882 3.20948C2.94882 3.20948 2.21087 2.52364 1.52456 3.26108C0.838254 3.99852 1.5762 4.68386 1.5762 4.68386L6.76036 9.50278C6.76638 9.50879 6.4325 9.88553 6.44253 9.89505L1.97074 5.73792C1.97074 5.73792 1.23279 5.05208 0.546485 5.78952C-0.139824 6.52697 0.598121 7.21281 0.598121 7.21281L5.35265 11.6314C5.39627 11.672 5.10199 12.0863 5.15664 12.1369L1.73061 8.95271C1.73061 8.95271 0.992661 8.26687 0.306352 9.00481C-0.379957 9.74225 0.357988 10.4281 0.357988 10.4281L5.94622 15.6197C6.87918 16.4869 7.8372 16.9258 8.99225 17.0866C9.22737 17.1192 9.52816 17.1192 9.65148 17.1347C10.041 17.1838 10.3829 17.3221 10.8406 17.6026C11.4297 17.9633 12.3135 18.1136 12.8213 17.5711L14.5529 15.7199L16.1516 14.0106C16.659 13.4685 16.5682 13.0006 16.1762 12.432Z" fill="#D2A077"/>
                                        <path d="M0.963237 17.7819C0.834899 17.7819 0.70656 17.7328 0.608803 17.6351C0.412786 17.4392 0.412786 17.1226 0.608803 16.9267L2.38899 15.1488C2.58501 14.9529 2.90185 14.9529 3.09786 15.1488C3.29388 15.3446 3.29388 15.6613 3.09786 15.8571L1.31767 17.6356C1.21991 17.7333 1.09158 17.7819 0.963237 17.7819ZM4.34515 18.2438C4.2905 18.2438 4.23436 18.2348 4.17921 18.2157C3.91802 18.1245 3.78016 17.839 3.8719 17.5775L4.35868 16.1883C4.44992 15.9273 4.73618 15.79 4.99737 15.8807C5.25856 15.9724 5.39642 16.2579 5.30468 16.5189L4.81789 17.9081C4.7457 18.1145 4.55219 18.2438 4.34515 18.2438ZM0.50152 14.4028C0.294976 14.4028 0.101466 14.2741 0.0287745 14.0687C-0.0634685 13.8076 0.0733921 13.5216 0.33458 13.4294L1.58538 12.9881C1.84757 12.8979 2.13282 13.0336 2.22506 13.2936C2.31731 13.5547 2.18044 13.8407 1.91926 13.9329L0.66846 14.3743C0.612813 14.3938 0.556666 14.4028 0.50152 14.4028ZM15.2704 3.49811C15.1421 3.49811 15.0138 3.44901 14.916 3.35132C14.72 3.15544 14.72 2.83832 14.916 2.64244L16.6967 0.863984C16.8927 0.668604 17.2095 0.668604 17.4056 0.863984C17.6016 1.05936 17.6016 1.37698 17.4056 1.57286L15.6249 3.35132C15.5271 3.44951 15.3988 3.49811 15.2704 3.49811ZM13.1829 2.64795C13.1283 2.64795 13.0721 2.63843 13.0175 2.6194C12.7563 2.52822 12.6184 2.24266 12.7097 1.98116L13.196 0.591955C13.2877 0.330948 13.573 0.19318 13.8347 0.284357C14.0958 0.375534 14.2337 0.66109 14.1425 0.922598L13.6562 2.3118C13.584 2.5187 13.39 2.64795 13.1829 2.64795ZM16.2626 5.54058C16.056 5.54058 15.8625 5.41183 15.7898 5.20643C15.6976 4.94542 15.8349 4.65936 16.0956 4.56718L17.3459 4.12583C17.6076 4.03315 17.8933 4.17091 17.9856 4.43142C18.0778 4.69243 17.9405 4.97848 17.6798 5.07066L16.4295 5.51202C16.3738 5.53156 16.3177 5.54058 16.2626 5.54058Z" fill="#FA743E"/>
                                        <path d="M17.7241 12.1689C17.3927 11.6519 17.6118 10.927 17.5527 10.1169C17.5075 8.79737 16.9847 7.50236 15.9209 6.44481L12.2973 2.84332C12.2973 2.84332 11.5834 2.13344 10.8721 2.84733C10.1617 3.56071 10.8761 4.27109 10.8761 4.27109L12.4071 5.79354L13.7336 7.1121C13.7336 7.1121 13.3777 7.4688 11.9093 6.00896C10.4399 4.54863 6.94922 1.07689 6.94922 1.07689C6.94922 1.07689 6.23484 0.367013 5.52397 1.0809C4.81309 1.79428 5.52748 2.50416 5.52748 2.50416L10.2178 7.16821C10.2078 7.15819 9.83984 7.50236 9.83332 7.49585L4.8151 2.50616C4.8151 2.50616 4.10072 1.79579 3.38984 2.50967C2.67947 3.22356 3.39335 3.93343 3.39335 3.93343L8.41208 8.92412C8.41809 8.93013 8.07118 9.29534 8.08121 9.30536L3.7523 5.0005C3.7523 5.0005 3.03792 4.29063 2.32704 5.00451C1.61617 5.7184 2.33055 6.42878 2.33055 6.42878L6.93318 11.0052C6.97529 11.0467 6.66748 11.4515 6.72012 11.5041L3.40338 8.20623C3.40338 8.20623 2.689 7.49635 1.97812 8.21023C1.26725 8.92412 1.98163 9.6345 1.98163 9.6345L7.39089 15.0109C8.29427 15.9092 9.23675 16.3796 10.3858 16.5795C10.6199 16.6201 10.9207 16.6301 11.043 16.6496C11.4305 16.7117 11.7674 16.8615 12.2161 17.1576C12.7926 17.5378 13.6709 17.7177 14.1968 17.1927L15.99 15.4012L17.6454 13.747C18.1713 13.221 18.0966 12.75 17.7241 12.1689Z" fill="#F3D2A2"/>
                                    </svg>
                                    <p className={`color-${theme}-text`}>{props.score}</p>
                                    <svg className="icon-btn" onClick={() => {
                                        
                                        if (localStorage.getItem('persistant-login-id') == null) {
                                            navigate('/login')
                                        } else {
                                            setModalCounterVisible(true)
                                            setCounterArgSide(props.vote === "yes" ? "no" : "yes")
                                        }
                                        
                                        }}
                                        width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17 9.8513C17 10.1182 16.9363 10.3686 16.8296 10.5962C16.1176 12.8915 12.7842 12.7239 8.04285 12.8358C7.25014 12.8544 7.70357 13.7898 7.98179 15.8432C8.16285 17.1787 7.30111 19.2294 5.85268 19.2294C3.46446 19.2294 5.76188 17.3469 3.65029 12.6915C2.52202 10.2041 0 11.5975 0 9.09894V3.41214C0 2.43958 0.0955713 1.50469 1.46436 1.35082C2.79121 1.2012 2.49282 0.256226 4.4069 0.256226H13.9874C14.9633 0.256226 15.757 1.04998 15.757 2.02519C15.757 2.4295 15.6153 2.79772 15.3864 3.09591C15.928 3.39887 16.2997 3.97084 16.2997 4.63407C16.2997 5.03732 16.1584 5.40554 15.9301 5.7032C16.4733 6.00563 16.846 6.57813 16.846 7.24242C16.846 7.72472 16.6506 8.16192 16.3358 8.48187C16.7377 8.80605 17 9.29631 17 9.8513Z" fill="#F2D1A1"/>
                                        <path d="M10.6623 8.08278H15.2306C15.8519 8.08278 16.4348 8.41492 16.7524 8.94922C16.883 9.16941 16.8102 9.45433 16.5893 9.58538C16.369 9.71644 16.0839 9.64268 15.9527 9.42249C15.8025 9.16835 15.5248 9.01129 15.2301 9.01129H10.5524C10.0889 9.01129 9.71192 9.38801 9.71192 9.8512C9.71192 10.3144 10.0889 10.6911 10.5524 10.6911H13.6781C13.9346 10.6911 14.1427 10.8991 14.1427 11.1554C14.1427 11.4116 13.9346 11.6196 13.6781 11.6196H10.5519C9.576 11.6196 8.78223 10.8264 8.78223 9.8512C8.78223 9.30736 9.03443 8.82612 9.42202 8.50141C9.09549 8.1804 8.89213 7.73472 8.89213 7.24234C8.89213 6.6969 9.14593 6.21407 9.53565 5.88989C9.21124 5.56942 9.01001 5.12532 9.01001 4.634C9.01001 4.03975 9.30681 3.51554 9.75812 3.19454C9.4799 2.88203 9.30575 2.47507 9.30575 2.02514C9.30575 1.04993 10.0995 0.256714 11.0754 0.256714H13.9872C14.6084 0.256714 15.1919 0.588327 15.5094 1.12262C15.6405 1.34281 15.5678 1.62773 15.3474 1.75879C15.1266 1.88878 14.8415 1.81715 14.7108 1.59696C14.5595 1.34281 14.2818 1.18523 13.9872 1.18523H11.0754C10.6119 1.18523 10.2349 1.56194 10.2349 2.02514C10.2349 2.48834 10.6119 2.86505 11.0754 2.86505H14.5298C15.151 2.86505 15.735 3.19719 16.052 3.73149C16.1832 3.95221 16.1104 4.23713 15.8901 4.36765C15.6676 4.50083 15.3836 4.42602 15.2535 4.20583C15.1 3.94743 14.8292 3.79356 14.5298 3.79356H10.7797C10.3161 3.79356 9.93917 4.17081 9.93917 4.634C9.93917 5.0972 10.3161 5.47391 10.7797 5.47391H15.0756C15.6968 5.47391 16.2803 5.80553 16.5978 6.33982C16.729 6.56001 16.6562 6.84493 16.4359 6.97599C16.2145 7.10651 15.9299 7.03435 15.7993 6.81416C15.6458 6.5563 15.3751 6.40243 15.0756 6.40243H10.6623C10.1988 6.40243 9.82183 6.77914 9.82183 7.24234C9.82183 7.70553 10.1983 8.08278 10.6623 8.08278Z" fill="#D19F77"/>
                                    </svg>
                                </span>
                                <span className="signal" onClick={() => {
                                    if (localStorage.getItem('persistant-login-id') == null) {
                                        navigate('/login')
                                    } else {
                                        if (!modalSignalVisible) {
                                            setModalSignalVisible(true)
                                        }
                                    }
                                    }}>
                                    <svg
                                        className="icon-btn" width="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path className={`fill-${theme}-contrast`} d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"/>      
                                    </svg>
                                    <p className={`color-${theme}-text`} >{t("debate.signal")}</p>
                                </span>
                            </div>
                            :
                            <div className="argument-vote-score">
                                <span>
                                    <p className={`color-${theme}-text`}>{t("debate.yourScore")}</p>
                                    <p className={`color-${theme}-text`}>{props.score}</p>
                                </span>
                                <span className="argument-del" onClick={async () => {
                                        const res = await api.deleteArgument(props.id, props.debateId)
                                        if (res.data.success) {
                                            props.onAction()
                                        }
                                    }}>
                                    <span className={`border-${theme}-contrast color-${theme}-text`}>
                                        X
                                    </span>
                                    <p className={`color-${theme}-text`}>{t("debate.delete")}</p>
                                </span>
                            </div>
                        }
                        </div>
                        <div className={`argument-counter-args-wrapper`} id={counterArgsId}>
                            {
                            breakpoint != "extraSmall" ?
                            props.counterArgs.map(counterArg => {
                                return (
                                    <CounterArg
                                        id={counterArg.id}
                                        key={Math.random()}
                                        author={counterArg.author}
                                        upvotes={counterArg.upvotes}
                                        downvotes={counterArg.downvotes}
                                        content={counterArg.content}
                                        timestamp={counterArg.timestamp}
                                        vote={props.vote === "yes" ? "no" : "yes"}
                                        arguments={props.arguments}
                                        counterArgsList={
                                            props.arguments.map(arg => {
                                                if (arg.counter_argument_of === counterArg.id) {
                                                    return arg
                                                }
                                            })
                                        }
                                        mainArgId={props.id}
                                        topic={props.topic}
                                        title={props.title}
                                        debateId={props.debateId}
                                        onAction={props.onAction}
                                        onLike={props.onLike}
                                        onEdit={props.onEdit}
                                        onDelete={props.onDelete}
                                        onSignal={props.onSignal}
                                        profilePics={props.profilePics}
                                    />
                                )
                            })
                            : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                breakpoint === "extraSmall" ?
                props.counterArgs.map(counterArg => {
                    return (
                        
                        <CounterArg
                            id={counterArg.id}
                            key={Math.random()}
                            author={counterArg.author}
                            upvotes={counterArg.upvotes}
                            downvotes={counterArg.downvotes}
                            content={counterArg.content}
                            timestamp={counterArg.timestamp}
                            vote={props.vote === "yes" ? "no" : "yes"}
                            arguments={props.arguments}
                            counterArgsList={
                                props.arguments.map(arg => {
                                    if (arg.counter_argument_of === counterArg.id) {
                                        return arg
                                    }
                                })
                            }
                            mainArgId={props.id}
                            topic={props.topic}
                            title={props.title}
                            debateId={props.debateId}
                            onAction={props.onAction}
                            onLike={props.onLike}
                            onEdit={props.onEdit}
                            onDelete={props.onDelete}
                            onSignal={props.onSignal}
                            profilePics={props.profilePics}
                        />
                    )
                })
                : null
            }
            {modalCounterVisible ? 
                <Modal
                    content={
                        <div className="content">
                            <h1 className="modal-title color-light-text">{t("debate.counterTitle")}</h1>
                            <textarea
                                className={`input border-light placeholder-${theme} label-${theme}`}
                                placeholder={t("debate.counterPlaceholder")}
                                onChange={(e) => {setCounterArgContent(e.target.value)}}>
                            </textarea>
                            <button className={`btn`} style={{
                                backgroundColor: newCounterArgSide === "yes" ? "rgb(0, 122, 100)" : "rgb(232, 83, 83)",
                                color: 'rgb(255,255,255)'}}
                                onClick={() => {
                                    submitCounterArgument()
                                }}>
                                {t("debate.counterBtn")}
                            </button>
                        </div>
                    }
                    onClose={() => {setModalCounterVisible(false); setCounterArgSide(''); setCounterArgContent('')}}/> 
            : null}
            {modalSignalVisible ? 
                <Modal
                    content={
                        <div className="content">
                            <h1 className="modal-title color-light-text">{t('debate.signal')}</h1>
                            <label className="label-light">{t('debate.signalModalLabel')}</label>
                            <textarea
                                className={`input border-${theme} placeholder-${theme} label-${theme}`}
                                onChange={(e) => {setSignalContent(e.target.value)}}>
                            </textarea>
                            <button
                                className={`btn`} style={{
                                backgroundColor: "rgb(232, 83, 83)",
                                color: 'rgb(255,255,255)'}}
                                onClick={() => {
                                    props.onSignal(props.id, 'argument', 'null', signalContent, localStorage.getItem('persistant-login-id'))
                                    setModalSignalVisible(false)
                                }}>
                                {t("debate.signal")}
                            </button>
                        </div>
                    }
                    onClose={() => {setModalSignalVisible(false)}}
                />
            : null}
            {
                floatingMenuOpen?
                <FloatingMenu
                    content={
                        <div>
                            {
                                props.author === localStorage.getItem('persistant-login-id') ? 
                                <div className="floating-menu-svg-text"
                                    onClick={ async () => {
                                        const res = await api.deleteArgument(props.id, props.debateId)
                                        if ( res.data.success ) {
                                            props.onAction()
                                        }
                                    }}>
                                    <svg
                                        className="icon-btn" xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 24 24">
                                        <path className={`fill-${theme}-contrast`} d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/>
                                    </svg>
                                    <p className={`color-${theme}-text`}>{t('debate.delete')}</p>
                                </div>
                                :
                                // Signalment icon
                                <div className="floating-menu-svg-text" onClick={() => {
                                    if (localStorage.getItem('persistant-login-id') == null) {
                                        navigate('/login')
                                    } else {
                                        if (!modalSignalVisible) {
                                            setModalSignalVisible(true)
                                        }
                                    }
                                    }}>
                                    <svg
                                        className="icon-btn" width="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path className={`fill-${theme}-red`} d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"/>      
                                    </svg>
                                    <p className={`color-${theme}-red`}>{t('debate.signal')}</p>
                                </div>
                            }
                        </div>
                    }
                    masterId={menuBtnId}
                />
                :
                null
            }
        </>
    )
}

export default Argument