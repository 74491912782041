import React, { useState } from 'react'
import { Chart as ChartJS } from 'chart.js/auto'
import { Bar } from 'react-chartjs-2';


const BarChart = (props) => {

    const [labels] = useState(props.labels)
    const [datasets] = useState(props.datasets)

    return (
        <Bar
            data={{
                labels,
                datasets
            }}
            options={{
                title: {
                    display: true,
                    text: "Chart title!",
                    fontSize: 20
                },
                legend:  {
                    display: true,
                    position: "right"
                },
                responsive: true,
                scales: {
                    x: {
                      stacked: true,
                    },
                    y: {
                      stacked: true
                    }
                  }
            }}
        />
    )

}

export default BarChart