import SearchBar from "./SearchBar"
import AccountLogSign from "./AccountLogSign"
import UserProfile from './UserProfile'
import React, { useEffect, useState } from "react";
import Store from "../../Store";



const Header = () => {

    const [theme, setTheme] = useState(Store.getState().theme)
    const [userExist, setUserExist] = useState(false)

    // If we are on the password reset page, clear the local storage
    if (window.location.href.includes('passwordresetrequest')) {
        localStorage.clear()
    }

    // On component initialization: check if an user is already loged in with the browser storage
    // If an user is loged in, replace the AccountLogSign component by the UserProfile component.
    useEffect(() => {
        //localStorage.clear()
        if (localStorage.getItem('persistant-login-id') != null) {
            setUserExist(true)
        }
    }, [])

    Store.subscribe(() => {
        const storeLogin = Store.getState().login
        if (localStorage.getItem('persistant-login-id') != null || storeLogin.id != null) {
            setUserExist(true)
        }
        setTheme(Store.getState().theme)
    })

    return (
        <header className={`bg-${theme}-main-transparent`}>
            <div 
                id="header"
                className={`container`}
                >
                <a id="logo-home" className={`logo color-${theme}-contrast`} href="/">dBaato!<br/><i><a className="gab-link" href="https://gabrielmercier.com">by Gabriel Mercier</a></i></a>
                <SearchBar/>
                { userExist ? <UserProfile/> : <AccountLogSign/> }
            </div>
        </header>
    )
}

export default Header