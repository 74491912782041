const findHighestOcc = (list) => {
    let occs = {}
    let occsReversed = {}
    let occsArray = []
    list.forEach(element => {
        const occValue = occs[element] === undefined ? 1 : occs[element] + 1
        occs[element] = occValue
        occsReversed[occValue] = element
        occsArray.push(occValue)
    });

    const highestOcc = occsReversed[Math.max(...occsArray).toString()]
    return highestOcc
}

export default findHighestOcc