import { useState } from 'react'
import Store from '../../Store'
import DebateEvolution from './charts/DebateEvolution'
import BarChart from './charts/BarChart'
import { t } from 'i18next'

const Stats = (props) => {

    const [theme, setTheme] = useState(Store.getState().theme)

    const [scoresRatio] = useState(props.stats.scoresRatio)
    const [scoresRatioTrend] = useState(props.stats.scoresRatioTrend)
    const [prosCountersPct] = useState(props.stats.prosCountersPct)
    const [consCountersPct] = useState(props.stats.consCountersPct)
    const [yesVotes] = useState(props.stats.yesVotes)
    const [noVotes] = useState(props.stats.noVotes)
    const [votesLabels] = useState(props.stats.votesLabel)
    const [topCountryVotingYes] = useState(props.stats.topCountryVotingYes)
    const [topCountryVotingNo] = useState(props.stats.topCountryVotingNo)
    const [topSexVotingYes] = useState(props.stats.topSexVotingYes)
    const [topSexVotingNo] = useState(props.stats.topSexVotingNo)
    const [topCountryVotingYesDesc] = useState(props.stats.topCountryVotingYesDesc)
    const [topCountryVotingNoDesc] = useState(props.stats.topCountryVotingNoDesc)
    const [topSexVotingYesDesc] = useState(props.stats.topSexVotingYesDesc)
    const [topSexVotingNoDesc] = useState(props.stats.topSexVotingNoDesc)
    const [votersYesMedianAge] = useState(props.stats.votersYesMedianAge)
    const [votersYesMedianAgeDesc] = useState(props.stats.votersYesMedianAgeDesc)
    const [votersNoMedianAge] = useState(props.stats.votersNoMedianAge)
    const [votersNoMedianAgeDesc] = useState(props.stats.votersNoMedianAgeDesc)
    const [sexBarchartValues] = useState(props.stats.sexBarchartValues)
    const [sexBarchartLabels] = useState(props.stats.sexBarchartLabels)
    const [medianAgesSexValues] = useState(props.stats.medianAgesSexValues)
    const [medianAgesSexLabels] = useState(props.stats.medianAgesSexLabels)
    const [countryBarchartValues] = useState(props.stats.countryBarchartValues)
    const [countryBarchartLabels] = useState(props.stats.countryBarchartLabels)

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    return (
        <>
            <div className='content'>
                
                <section className={`stats-numbers color-light-text`}>
                    <h2 className={`stats-numbers-title`}>{t("debate.statsKeyTitle")}</h2>
                    <div className={`stats-data`}>
                        <div className={`stats-number`}>
                            <div className='number-wrapper'>
                                <p className='number'>{scoresRatio}</p>
                                {
                                    scoresRatioTrend === "up" ? 
                                        <svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M25.6157 0.384092C25.4949 0.262387 25.3512 0.165786 25.1929 0.0998635C25.0346 0.0339407 24.8647 0 24.6932 0C24.5217 0 24.3519 0.0339407 24.1936 0.0998635C24.0352 0.165786 23.8915 0.262387 23.7708 0.384092L15.5985 8.56461L10.0247 2.98108C9.90394 2.85937 9.76025 2.76277 9.60192 2.69685C9.4436 2.63093 9.27378 2.59699 9.10226 2.59699C8.93074 2.59699 8.76093 2.63093 8.6026 2.69685C8.44427 2.76277 8.30058 2.85937 8.17979 2.98108L0.384315 10.772C0.262539 10.8928 0.165882 11.0364 0.0999211 11.1946C0.03396 11.3528 0 11.5226 0 11.694C0 11.8654 0.03396 12.0351 0.0999211 12.1933C0.165882 12.3516 0.262539 12.4952 0.384315 12.6159C0.505097 12.7376 0.648795 12.8342 0.80712 12.9001C0.965446 12.9661 1.13526 13 1.30678 13C1.4783 13 1.64811 12.9661 1.80644 12.9001C1.96477 12.8342 2.10846 12.7376 2.22925 12.6159L9.10226 5.73389L14.676 11.3174C14.7968 11.4391 14.9405 11.5357 15.0988 11.6016C15.2572 11.6676 15.427 11.7015 15.5985 11.7015C15.77 11.7015 15.9398 11.6676 16.0982 11.6016C16.2565 11.5357 16.4002 11.4391 16.521 11.3174L25.6157 2.22795C25.7375 2.10724 25.8341 1.96363 25.9001 1.80539C25.966 1.64716 26 1.47744 26 1.30602C26 1.13461 25.966 0.964886 25.9001 0.806653C25.8341 0.648419 25.7375 0.504804 25.6157 0.384092V0.384092Z" fill="#00FF19"/>
                                        </svg>
                                        :
                                    scoresRatioTrend === "down" ? 
                                    <svg width="26" height="13" viewBox="0 0 26 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M25.6157 12.6159C25.4949 12.7376 25.3512 12.8342 25.1929 12.9001C25.0346 12.9661 24.8647 13 24.6932 13C24.5217 13 24.3519 12.9661 24.1936 12.9001C24.0352 12.8342 23.8915 12.7376 23.7708 12.6159L15.5985 4.43539L10.0247 10.0189C9.90394 10.1406 9.76025 10.2372 9.60192 10.3031C9.4436 10.3691 9.27378 10.403 9.10226 10.403C8.93074 10.403 8.76093 10.3691 8.6026 10.3031C8.44427 10.2372 8.30058 10.1406 8.17979 10.0189L0.384315 2.22795C0.262539 2.10724 0.165882 1.96363 0.0999211 1.80539C0.03396 1.64716 0 1.47744 0 1.30602C0 1.13461 0.03396 0.964886 0.0999211 0.806652C0.165882 0.648419 0.262539 0.504805 0.384315 0.384093C0.505097 0.262387 0.648795 0.165785 0.80712 0.0998618C0.965446 0.033939 1.13526 -4.19343e-07 1.30678 -4.19343e-07C1.4783 -4.19343e-07 1.64811 0.033939 1.80644 0.0998618C1.96477 0.165785 2.10846 0.262387 2.22925 0.384093L9.10226 7.26611L14.676 1.68259C14.7968 1.56088 14.9405 1.46428 15.0988 1.39836C15.2572 1.33243 15.427 1.29849 15.5985 1.29849C15.77 1.29849 15.9398 1.33243 16.0982 1.39836C16.2565 1.46428 16.4002 1.56088 16.521 1.68259L25.6157 10.772C25.7375 10.8928 25.8341 11.0364 25.9001 11.1946C25.966 11.3528 26 11.5226 26 11.694C26 11.8654 25.966 12.0351 25.9001 12.1933C25.8341 12.3516 25.7375 12.4952 25.6157 12.6159V12.6159Z" fill="#FF1900"/>
                                    </svg>
                                
                                    :
                                    <svg width="27" height="3" viewBox="0 0 27 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="27" height="3" rx="1.5" className={`fill-light-textLow`}/>
                                    </svg>

                                }
                            </div>
                            <p className='stat-info'>{t("debate.statsScoresRatio")}</p>
                        </div>
                        <div className={`stats-number`}>
                            <div className='number-wrapper'>
                                <p className='number'>{prosCountersPct}</p>
                            </div>
                            <p className='stat-info'>{t("debate.statsYesDebatedPct")}</p>
                        </div>
                        <div className={`stats-number`}>
                            <div className='number-wrapper'>
                                <p className='number'>{consCountersPct}</p>
                            </div>
                            <p className='stat-info'>{t("debate.statsNoDebatedPct")}</p>
                        </div>
                    </div>
                    <section className={`stats-numbers color-light-text`}>
                        <h2 className={`stats-numbers-title`}>{t("debate.statsDebateEvolutionTitle")}</h2>
                        <div className={`stats-data`}>
                            <div id="full-chart" className={`stats-number`}>
                                {
                                    <DebateEvolution
                                        labels={votesLabels}
                                        data={{
                                            yes: yesVotes,
                                            no: noVotes
                                        }}
                                    />
                                }
                            </div>
                        </div>
                    </section>
                    <section className={`stats-numbers color-light-text`}>
                        <h2 className={`stats-numbers-title`}>{t("debate.statsDemographicsTitle")}</h2>
                        <div className={`stats-data`}>
                            <div className={`stats-number`}>
                                <div className='number-wrapper'>
                                    <p className='number'>{topCountryVotingYes}</p>
                                </div>
                                <p className='stat-info'>{t("debate.statsTopCountryYes")}</p>
                            </div>
                            <div className={`stats-number`}>
                                <div className='number-wrapper'>
                                    <p className='number'>{topSexVotingYes}</p>
                                </div>
                                <p className='stat-info'>{t("debate.statsTopSexYes")}</p>
                            </div>
                            <div className={`stats-number`}>
                                <div className='number-wrapper'>
                                    <p className='number'>{votersYesMedianAge}</p>
                                </div>
                                <p className='stat-info'>{t("debate.statsMedianAgeYes")}</p>
                            </div>
                            
                        </div>
                        <div id="demographics-charts" className={`stats-data`}>
                            <div className={`stats-number`}>
                                <BarChart
                                    labels={sexBarchartLabels}
                                    datasets={sexBarchartValues}
                                />
                            </div>
                            <div className={`stats-number`}>
                                <BarChart
                                    labels={countryBarchartLabels}
                                    datasets={countryBarchartValues}
                                />
                            </div>
                        </div>
                        <div className={`stats-data`}>
                        <div className={`stats-number`}>
                                <div className='number-wrapper'>
                                    <p className='number'>{topCountryVotingNo}</p>
                                </div>
                                <p className='stat-info'>{t("debate.statsTopCountryNo")}</p>
                            </div>
                            <div className={`stats-number`}>
                                <div className='number-wrapper'>
                                    <p className='number'>{topSexVotingNo}</p>
                                </div>
                                <p className='stat-info'>{t("debate.statsTopSexNo")}</p>
                            </div>
                            <div className={`stats-number`}>
                                <div className='number-wrapper'>
                                    <p className='number'>{votersNoMedianAge}</p>
                                </div>
                                <p className='stat-info'>{t("debate.statsMedianAgeNo")}</p>
                            </div>
                        </div>
                    </section>
                </section>
            </div>
        </>
    )


}

export default Stats