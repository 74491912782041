
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Api from "../../API/Api";
import { Trans } from 'react-i18next';
import Store from "../../Store";

const api = new Api

const EmailConfirm = () => {

    const user = useParams().user
    const emailToken = useParams().emailtoken
    const navigate = useNavigate()

    const [message, setMessage] = useState('confirming')
    const [tried, setTried] = useState(false)
    
    // On mount, use the api GET method to confirm the email
    // Then, redirect to the home page
    useEffect(() => {

        Store.dispatch({
            type: 'UPDATE_NAVIGATION_HISTORY',
            payload: "email-confirm"
        })

        if (!tried) {
            api.confirmEmail(user, emailToken).then(
                response => {
                    if (response.data) {
                        setMessage("success")
                        setTimeout(() => {
                            navigate('/login')
                        }, 3000)
                    } else {
                        document.getElementById('confirmation').style.backgroundColor = 'rgb(240, 80, 120)'
                        document.getElementById('confirmation').style.color = 'rgb(177, 4, 4)'
                        setMessage("error")
                        setTimeout(() => {
                            navigate('/')
                        }, 3000)
                    }
                }
            ).catch(error => {
                document.getElementById('confirmation').style.backgroundColor = 'rgb(240, 80, 120)'
                document.getElementById('confirmation').style.color = 'rgb(177, 4, 4)'
                setMessage('error')
                setTimeout(() => {
                    navigate('/')
                }, 3000)
            })

            setTried(true)
        }

    }, [])

    return (
        <>
            <div id="confirmation" className={`${
                    message === "confirming" ? `bg-light-blue color-light-onContrast` 
                    : message === "success" ? `bg-light-green color-light-onContrast`
                    : `bg-light-red color-light-onContrast`
                }`}>
                <p>
                {
                    message === "confirming" ? <Trans i18nKey={"externals.emailConfirm.confirming"}></Trans> : 
                    message === "success" ? <Trans i18nKey={"externals.emailConfirm.success"}></Trans> : 
                    <Trans i18nKey={"externals.emailConfirm.error"}></Trans>
                }
                </p>
            </div>
        </>
    )
}

export default EmailConfirm