import { useEffect, useState } from "react"
import Store from "../../../Store"
import { Trans } from 'react-i18next';
import { t } from "i18next";
import topicsTrans from "../../../utils/topicsTrans";
import Api from "../../../API/Api";
import FloatingMenu from "../FloatingMenu";
import Modal from '../Modal'
import Loading from "../Loading";
import { useNavigate } from "react-router-dom";
import log from "../../../functions/log";
import getBreakpoint from "../../../functions/getBreakpoint";

const api = new Api


const HomeDebate = (props) => {

    const [theme, setTheme] = useState(Store.getState().theme)
    const [breakpoint, setBreakpoint] = useState(getBreakpoint())
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const moreId = Math.random()
    const id = Math.random()
    const easing = 'cubic-bezier(0.075, 0.82, 0.165, 1)'
    const navigate = useNavigate()

    const [topArgShowedIndex, setTopArgShowedIndex] = useState(0) // can be 0 or 1. if 0 -> actually showing the topShowed arg type.
    const [topArgProText] = useState(<Trans i18nKey={"home.debate.bestPro"}/>)
    const [topArgConText] = useState(<Trans i18nKey={"home.debate.bestCon"}/>)
    const [changeArgTypeText, setChangeArgTypeText] = useState(<Trans i18nKey={props.topShowed === "pro" ? "home.debate.showTopCon" : "home.debate.showTopPro" }/>)
    const [domainTranslated, setDomainTranslated] = useState()
    const [upvoteClass, setUpvoteClass] = useState(
        props.userVote === "up" ? `fill-${Store.getState().theme}-main` : `fill-${Store.getState().theme}-contrast`
    )
    const [downvoteClass, setDownvoteClass] = useState(
        props.userVote === "down" ? `fill-${Store.getState().theme}-main` : `fill-${Store.getState().theme}-contrast`
    )

    const [menuVisible, setMenuVisible] = useState(false)
    const [signalModalVisible, setSignalModalVisible] = useState(false)
    const [signalText, setSignalText] = useState('')
    const [signalModalButtonId] = useState(Math.random())
    const [signalIsLoading, setSignalIsLoading] = useState(false)
    const [followIsLoading, setFollowIsLoading] = useState(false)
    const [followUnfollowText, setFollowUnfollowText] = useState(props.isFollowing ? <Trans i18nKey={'home.debate.unfollow'}/> : <Trans i18nKey={'home.debate.follow'}/>)
    const [isFollowing, setIsFollowing] = useState(props.isFollowing)
    const [signalBtnSize, setSignalBtnSize] = useState({width: '100px', height: "35px"})
    const [followBtnSize, setFollowBtnSize] = useState({width: "100px", height: "35px"})
    const [followButtonId] = useState(Math.random())

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
        const floatingVisible = Store.getState().floatingVisible
        if (!floatingVisible) {
            setMenuVisible(false)
        }
    })

    useEffect(() => {
        window.addEventListener('scroll', () => {setMenuVisible(false)})
        try {
            setDomainTranslated(topicsTrans[localStorage.getItem('i18nextLng')][props.domain])
        } catch (error) {
            setDomainTranslated(props.domain)
        }
        window.addEventListener('resize', () => {
            setBreakpoint(getBreakpoint())
            setWindowWidth(window.innerWidth)
        })
    }, [])

    const switchTopArgument = () => {

        if (topArgShowedIndex === 0) {
            const box = document.getElementById(id)
            const anim = box.animate([{ marginLeft: '-100%' }], { duration: 618, easing })
            anim.onfinish = (e) => {
                box.style.marginLeft = '-100%'
            }

            setTopArgShowedIndex(1)

            if (props.topShowed === "pro") {
                setChangeArgTypeText(<Trans i18nKey={"home.debate.showTopPro"}/>)
            } else {
                setChangeArgTypeText(<Trans i18nKey={"home.debate.showTopCon"}/>)
            }

        } else if (topArgShowedIndex === 1) {
            const box = document.getElementById(id)
            const anim = box.animate([{ marginLeft: '0%' }], { duration: 618, easing })
            anim.onfinish = (e) => {
                box.style.marginLeft = '0%'
            }

            setTopArgShowedIndex(0)
            if (props.topShowed === "pro") {
                setChangeArgTypeText(<Trans i18nKey={"home.debate.showTopCon"}/>)
            } else {
                setChangeArgTypeText(<Trans i18nKey={"home.debate.showTopPro"}/>)
            }
        }
    }

    const debateVote = async (direction, id) => {

        // If user loged in -> simply process to the debate vote. Otherwise, redirect to login / register
        const uid = localStorage.getItem('persistant-login-id')
        
        if (typeof(uid) === "string") {

            // Change arrows fill color so user knows they are not clickable anymore
            if (direction === "up" && upvoteClass === `fill-${Store.getState().theme}-contrast`) {
                setUpvoteClass(`fill-${theme}-main`)
                setDownvoteClass(`fill-${theme}-contrast`)
            } else if (direction === "down" && downvoteClass === `fill-${Store.getState().theme}-contrast`) {
                setDownvoteClass(`fill-${theme}-main`)
                setUpvoteClass(`fill-${theme}-contrast`)
            // If user undo a vote
            } else if (direction === "up" && upvoteClass === `fill-${theme}-main`) {
                setUpvoteClass(`fill-${theme}-contrast`)
            } else if (direction === "down" && downvoteClass === `fill-${theme}-main`) {
                setDownvoteClass(`fill-${theme}-contrast`)
            }

            api.debateVote(direction, props.debateId, uid)

        } else {

        }

    }

    return (
        <>
            <div id={Math.random()} className={`home-debate-wrapper bg-${theme}-component shadow-${theme} ${windowWidth <= 570 ? `border-${theme}-lowContrast` : null}`}>
                <section className={`home-debate`}>
                    <div className={`home-debate-top`}>
                        <div className="home-debate-title">
                            <h2 className={`color-${theme}-contrast`}>
                                {props.title}
                            </h2>
                            <div
                                id={moreId}
                                onClick={() => {
                                    if (!menuVisible) {
                                        setMenuVisible(true)
                                    }
                                }} 
                                className={`btn-actions-floating ${windowWidth <= 570 ? `hover-${theme}-contrast` : `hover-${theme}-lowContrast`}`}>
                                <span className={`bg-${theme}-contrast`}></span>
                                <span className={`bg-${theme}-contrast`}></span>
                                <span className={`bg-${theme}-contrast`}></span>
                            </div>
                        </div>
                        <div className="home-debate-actions-wrapper">
                            <div className={`home-debate-arrows-wrapper ${windowWidth <= 570 ? `border-${theme}-lowContrast` : `border-${theme}`}`}>
                                <div className={`home-debate-arrows`}>
                                    <button
                                        style={{backgroundColor: 'transparent'}}
                                        onClick={() => {
                                            if (localStorage.getItem('persistant-login-id') != null && typeof localStorage.getItem('persistant-login-id') === "string") {
                                                debateVote("up")
                                            } else {
                                                navigate('/signup')
                                            }
                                        }}
                                        className={`home-debate-arrow`}>
                                        <div>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path className={upvoteClass} d="M13.2826 8.46751L9.53264 4.71751C9.46131 4.64923 9.3772 4.59571 9.28514 4.56001C9.10254 4.485 8.89774 4.485 8.71514 4.56001C8.62308 4.59571 8.53897 4.64923 8.46764 4.71751L4.71764 8.46751C4.64771 8.53744 4.59224 8.62046 4.55439 8.71183C4.51655 8.80319 4.49707 8.90112 4.49707 9.00001C4.49707 9.19974 4.57641 9.39129 4.71764 9.53251C4.85887 9.67374 5.05041 9.75308 5.25014 9.75308C5.44987 9.75308 5.64141 9.67374 5.78264 9.53251L8.25014 7.05751V12.75C8.25014 12.9489 8.32916 13.1397 8.46981 13.2803C8.61046 13.421 8.80123 13.5 9.00014 13.5C9.19905 13.5 9.38982 13.421 9.53047 13.2803C9.67112 13.1397 9.75014 12.9489 9.75014 12.75V7.05751L12.2176 9.53251C12.2874 9.60281 12.3703 9.65861 12.4617 9.69668C12.5531 9.73476 12.6511 9.75436 12.7501 9.75436C12.8491 9.75436 12.9472 9.73476 13.0386 9.69668C13.13 9.65861 13.2129 9.60281 13.2826 9.53251C13.3529 9.46279 13.4087 9.37984 13.4468 9.28845C13.4849 9.19705 13.5045 9.09902 13.5045 9.00001C13.5045 8.90101 13.4849 8.80298 13.4468 8.71158C13.4087 8.62019 13.3529 8.53724 13.2826 8.46751Z"/>
                                            </svg>
                                        </div>
                                    </button>
                                    <button
                                        style={{backgroundColor: 'transparent'}}
                                        onClick={() => {
                                            if (localStorage.getItem('persistant-login-id') != null && typeof localStorage.getItem('persistant-login-id') === "string") {
                                                debateVote("down")
                                            } else {
                                                navigate('/signup')
                                            }
                                        }}
                                        className={`home-debate-arrow ${downvoteClass} bg-${theme}-contrast border-${theme}-left-contrast`}>
                                        <div>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path className={downvoteClass} d="M4.71736 9.53249L8.46736 13.2825C8.53869 13.3508 8.6228 13.4043 8.71486 13.44C8.89746 13.515 9.10226 13.515 9.28486 13.44C9.37692 13.4043 9.46103 13.3508 9.53236 13.2825L13.2824 9.53249C13.3523 9.46256 13.4078 9.37954 13.4456 9.28817C13.4835 9.19681 13.5029 9.09888 13.5029 8.99999C13.5029 8.80026 13.4236 8.60871 13.2824 8.46749C13.1411 8.32626 12.9496 8.24692 12.7499 8.24692C12.5501 8.24692 12.3586 8.32626 12.2174 8.46749L9.74986 10.9425V5.24999C9.74986 5.05107 9.67084 4.86031 9.53019 4.71966C9.38954 4.579 9.19877 4.49999 8.99986 4.49999C8.80095 4.49999 8.61018 4.579 8.46953 4.71966C8.32888 4.86031 8.24986 5.05107 8.24986 5.24999V10.9425L5.78236 8.46749C5.71264 8.39719 5.62969 8.34139 5.53829 8.30332C5.4469 8.26524 5.34887 8.24564 5.24986 8.24564C5.15085 8.24564 5.05282 8.26524 4.96143 8.30332C4.87003 8.34139 4.78708 8.39719 4.71736 8.46749C4.64706 8.53721 4.59127 8.62016 4.55319 8.71155C4.51511 8.80295 4.49551 8.90098 4.49551 8.99999C4.49551 9.09899 4.51511 9.19702 4.55319 9.28842C4.59127 9.37981 4.64706 9.46276 4.71736 9.53249Z"/>
                                            </svg>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            {
                                props.topShowed === "pro" ? props.topCon != null ?
                                    <div className={`home-debate-arg-type ${windowWidth <= 570 ? `border-${theme}-lowContrast` : `border-${theme}`}`}>
                                        <button className={`color-${theme}-text`} onClick={
                                            () => { switchTopArgument() }
                                            }>
                                            <div>
                                                { changeArgTypeText }
                                            </div>
                                        </button>
                                    </div>
                                    :
                                    null
                                    :
                                    props.topPro != null ? 
                                    <div className={`home-debate-arg-type ${windowWidth <= 570 ? `border-${theme}-lowContrast` : `border-${theme}`}`}>
                                        <button className={`color-${theme}-text`} onClick={
                                            () => {switchTopArgument()}
                                            }>
                                            <div>
                                                {changeArgTypeText}
                                            </div>
                                        </button>
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    <div className="home-debate-middle" id={id}>

                        <div>
                            <div className="home-debate-score-author-type">
                                <h3 className={`color-${theme}-${props.topShowed === "pro" ? "green" : "red"}`}>{props.topShowed === "pro" ? props.topPro.upvotes - props.topPro.downvotes : props.topCon.upvotes - props.topCon.downvotes}</h3>
                                <div className="home-debate-author-type">
                                    <p className={`color-${theme}-contrast`}>{props.topShowed === "pro" ? props.topPro.author : props.topCon.author}</p>
                                    <h4 className={`color-${theme}-contrast`}>{props.topShowed === "pro" ? topArgProText : topArgConText}</h4>
                                </div>
                            </div>
                            <p className={`color-${theme}-contrast`}>"{props.topShowed === "pro" ? props.topPro.content : props.topCon.content}"</p>
                        </div>
                        {
                            // Do not build div for other top arg if does not exist
                            props.topShowed === "pro" ? props.topCon != null ? 
                                <div>
                                    <div className="home-debate-score-author-type">
                                        <h3 className={`color-${theme}-red`}>{props.topCon.upvotes - props.topCon.downvotes}</h3>
                                        <div className="home-debate-author-type">
                                            <p className={`color-${theme}-contrast`}>{props.topShowed === "pro" ? props.topCon.author : props.topPro.author}</p>
                                            <h4 className={`color-${theme}-contrast`}>{topArgConText}</h4>
                                        </div>
                                    </div>
                                    <p className={`color-${theme}-contrast`}>"{props.topCon.content}"</p>
                                </div>
                                :
                                null
                                :
                                props.topPro != null ? 
                                <div>
                                    <div className="home-debate-score-author-type">
                                        <h3 className={`color-${theme}-green`}>{props.topPro.upvotes - props.topPro.downvotes}</h3>
                                        <div className="home-debate-author-type">
                                            <p className={`color-${theme}-contrast`}>{props.topShowed === "pro" ? props.topCon.author : props.topPro.author}</p>
                                            <h4 className={`color-${theme}-contrast`}>{topArgProText}</h4>
                                        </div>
                                    </div>
                                    <p className={`color-${theme}-contrast`}>"{props.topPro.content}"</p>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className="home-debate-bottom">
                        <div className={`${windowWidth <= 570 ? null : `border-${theme}`} home-debate-stats ${windowWidth <= 570 ? `color-${theme}-onSoft` : `color-${theme}-text` } ${windowWidth <= 570 ? `bg-${theme}-mobile-soft` : null}`}>
                            <a href={`/topic/${props.domain}`}><b>{domainTranslated}</b></a>
                            <p><b>{props.topShowed === "pro" ? props.yesPct : 100-props.yesPct}%</b> {
                                props.topShowed === "pro" ?
                                    breakpoint === "extraSmall" ? 
                                        t('home.debate.votedYesShort') : 
                                        t('home.debate.votedYes') : 
                                    breakpoint === "extraSmall" ? 
                                        t('home.debate.votedNoShort') :
                                        t('home.debate.votedNo')
                                }</p>
                            <p className="home-debate-nb-args"><b>{props.nbArguments}</b> arguments</p>
                        </div>
                        <a  
                            href={`/debate/${props.domain}/${props.debateId}`}
                            className={`home-debate-goto bg-${theme}-contrast`}>
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path className={`fill-${theme}-onContrast`} d="M20.9067 13.5567C20.8511 13.4135 20.7679 13.2826 20.6617 13.1717L14.8283 7.33835C14.7196 7.22957 14.5904 7.14328 14.4483 7.08441C14.3062 7.02554 14.1538 6.99524 14 6.99524C13.6893 6.99524 13.3914 7.11866 13.1717 7.33835C13.0629 7.44712 12.9766 7.57626 12.9177 7.71839C12.8589 7.86051 12.8286 8.01284 12.8286 8.16668C12.8286 8.47736 12.952 8.77533 13.1717 8.99501L17.0217 12.8333H8.16667C7.85725 12.8333 7.5605 12.9563 7.34171 13.1751C7.12292 13.3938 7 13.6906 7 14C7 14.3094 7.12292 14.6062 7.34171 14.825C7.5605 15.0438 7.85725 15.1667 8.16667 15.1667H17.0217L13.1717 19.005C13.0623 19.1135 12.9755 19.2425 12.9163 19.3847C12.8571 19.5268 12.8266 19.6793 12.8266 19.8333C12.8266 19.9874 12.8571 20.1399 12.9163 20.282C12.9755 20.4242 13.0623 20.5532 13.1717 20.6617C13.2801 20.771 13.4092 20.8578 13.5513 20.9171C13.6935 20.9763 13.846 21.0068 14 21.0068C14.154 21.0068 14.3065 20.9763 14.4487 20.9171C14.5908 20.8578 14.7199 20.771 14.8283 20.6617L20.6617 14.8283C20.7679 14.7174 20.8511 14.5866 20.9067 14.4433C21.0234 14.1593 21.0234 13.8407 20.9067 13.5567Z"/>
                            </svg>
                        </a>
                    </div>
                </section>
            </div>
            {
                menuVisible ? 
                    <FloatingMenu
                        content={
                            <div className={`floating-menu-home-debate color-${theme}-text`}>
                                <div
                                    id={followButtonId}
                                    onClick={() => {
                                        if (!followIsLoading) {

                                            if (localStorage.getItem('persistant-login-id') != null && typeof localStorage.getItem('persistant-login-id') === "string") {
                                                setFollowBtnSize({
                                                    width: `${document.getElementById(followButtonId).getBoundingClientRect().width}px`,
                                                    height: `${document.getElementById(followButtonId).getBoundingClientRect().height}px`
                                                })
                                                setFollowIsLoading(true)
                                                api.debateFollow(
                                                    props.debateId,
                                                    localStorage.getItem('persistant-login-id'),
                                                    localStorage.getItem('persistant-login-email'),
                                                    props.title,
                                                    props.domain
                                                )
                                                .then(res => {
                                                    if (res.data.success) {
                                                        setFollowIsLoading(false)
                                                        setIsFollowing(!isFollowing)
                                                        setFollowUnfollowText(
                                                            <Trans i18nKey={!isFollowing ? "home.debate.unfollow" : "home.debate.follow"}/>
                                                        )
                                                    } else {
                                                        setFollowIsLoading(false)
                                                        log('log', 'home.debate.followError', 'error', 5000)
                                                    }
                                                })
                                                .catch(err => {
                                                    setFollowIsLoading(false)
                                                    log('log', 'home.debate.followError', 'error', 5000)
                                                })
                                            } else {
                                                navigate('/signup')
                                            }
                                        }
                                    }} 
                                    className={`floating-menu-home-action border-bottom-${theme}-onComponent`}>
                                    {
                                        followIsLoading ? 
                                            <Loading
                                                height={followBtnSize.height}
                                                width={followBtnSize.width}
                                            /> : <>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" data-name="Layer 1" viewBox="0 0 24 24">
                                                <path className={`fill-${theme}-contrast`} d="M18,13.18V10a6,6,0,0,0-5-5.91V3a1,1,0,0,0-2,0V4.09A6,6,0,0,0,6,10v3.18A3,3,0,0,0,4,16v2a1,1,0,0,0,1,1H8.14a4,4,0,0,0,7.72,0H19a1,1,0,0,0,1-1V16A3,3,0,0,0,18,13.18ZM8,10a4,4,0,0,1,8,0v3H8Zm4,10a2,2,0,0,1-1.72-1h3.44A2,2,0,0,1,12,20Zm6-3H6V16a1,1,0,0,1,1-1H17a1,1,0,0,1,1,1Z"/>
                                            </svg>
                                            <p>{followUnfollowText}</p>
                                        </>
                                    }
                                </div>
                                <div 
                                    onClick={() => {
                                        Store.dispatch({
                                            type: 'SET_FLOATING_VISIBLE',
                                            payload: false
                                        })
                                        navigator.share({
                                            title: props.title,
                                            text: "See how people are debating on this question",
                                            url: `https://www.dbaato.com/debate/${props.domain}/${props.debateId}`
                                        })
                                    }}
                                    className={`floating-menu-home-action border-bottom-${theme}-onComponent`}>
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path className={`fill-${theme}-contrast`} d="M15.3761 7.99921L9.70945 2.33254C9.6104 2.23348 9.4842 2.16601 9.34681 2.13868C9.20941 2.11134 9.067 2.12536 8.93757 2.17897C8.80815 2.23257 8.69753 2.32335 8.6197 2.43983C8.54187 2.55631 8.50033 2.69325 8.50033 2.83333V5.34432C6.56537 5.52342 4.76688 6.41811 3.4568 7.85332C2.14672 9.28852 1.41934 11.1609 1.41699 13.1042V14.1667C1.41698 14.3138 1.46277 14.4572 1.548 14.5771C1.63323 14.697 1.75368 14.7874 1.89261 14.8357C2.03155 14.8841 2.18209 14.8879 2.32333 14.8468C2.46457 14.8057 2.5895 14.7216 2.68079 14.6063C3.3747 13.781 4.22612 13.1023 5.1853 12.6098C6.14448 12.1174 7.19219 11.821 8.26721 11.738C8.30249 11.7335 8.39103 11.7266 8.50033 11.7197V14.1667C8.50033 14.3068 8.54187 14.4437 8.6197 14.5602C8.69753 14.6766 8.80815 14.7674 8.93757 14.821C9.067 14.8746 9.20941 14.8887 9.34681 14.8613C9.4842 14.834 9.6104 14.7665 9.70945 14.6675L15.3761 9.00079C15.4419 8.93504 15.4941 8.85697 15.5297 8.77104C15.5653 8.68511 15.5836 8.59301 15.5836 8.5C15.5836 8.40699 15.5653 8.31489 15.5297 8.22896C15.4941 8.14303 15.4419 8.06496 15.3761 7.99921ZM9.91699 12.4567V10.9792C9.91702 10.8861 9.89871 10.794 9.86312 10.7081C9.82753 10.6221 9.77536 10.544 9.70958 10.4782C9.6438 10.4125 9.56571 10.3603 9.47976 10.3247C9.39381 10.2891 9.30169 10.2708 9.20866 10.2708C9.02812 10.2708 8.29073 10.3058 8.10258 10.331C6.19334 10.5069 4.37595 11.2322 2.87032 12.4193C3.04112 10.857 3.78224 9.41256 4.95176 8.36263C6.12128 7.31271 7.637 6.73109 9.20866 6.72917C9.30169 6.72919 9.39381 6.71089 9.47976 6.6753C9.56571 6.63971 9.6438 6.58753 9.70958 6.52175C9.77536 6.45598 9.82753 6.37788 9.86312 6.29193C9.89871 6.20598 9.91702 6.11386 9.91699 6.02083V4.54329L13.8737 8.5L9.91699 12.4567Z"/>
                                    </svg>

                                    <p>{t('home.debate.share')}</p>
                                </div>
                                <div 
                                    onClick={() => {
                                        Store.dispatch({
                                            type: 'SET_FLOATING_VISIBLE',
                                            payload: false
                                        })
                                        if (localStorage.getItem('persistant-login-id') != null && typeof localStorage.getItem('persistant-login-id') === "string") {
                                            setSignalModalVisible(true)
                                        } else {
                                            navigate('/signup')
                                        }   
                                    }}
                                    className="floating-menu-home-action">
                                    <svg
                                        className="icon-btn" width="19" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path className={`fill-${theme}-red`} d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"/>      
                                    </svg>
                                    <p className={`color-${theme}-red`}>{t("home.debate.signal")}</p>
                                </div>
                            </div>
                        }
                        masterId={moreId}
                    />
                    : null
            }
            {
                signalModalVisible ? 
                    <Modal
                        content={
                            <div className="content">
                                <h1 className="modal-title">{t("home.debate.signal")}</h1>
                                <textarea
                                    className="modal-textarea"
                                    onChange={(e) => {setSignalText(e.target.value)}}
                                    placeholder={t("home.debate.signalPlaceholder")}>
                                </textarea>
                                <button
                                    className={`btn bg-${theme}-contrast color-${theme}-onContrast`}
                                    id={signalModalButtonId}
                                    onClick={() => {
                                    if (!signalIsLoading) {
                                        if (signalText.length > 2) {
                                            setSignalIsLoading(true)
                                            setSignalBtnSize({
                                                width: `${document.getElementById(signalModalButtonId).getBoundingClientRect().width}px`,
                                                height: `${document.getElementById(signalModalButtonId).getBoundingClientRect().height}px`
                                            })
                                            api.signalment(
                                                props.debateId,
                                                "debate",
                                                "unknown",
                                                signalText,
                                                localStorage.getItem("persistant-login-id")
                                            )
                                            .then(res => {
                                                if (res.data.success) {
                                                    log('log', "home.debate.signalSuccess", "success", 5000)
                                                    setSignalModalVisible(false)
                                                } else {
                                                    log('log', "home.debate.signalError", "error", 5000)
                                                }
                                                setSignalIsLoading(false)
                                            })
                                            .catch(err => {
                                            })
                                        } else {
                                            log('log', "home.debate.signalIncorrect", "warning", 5000)
                                        }
                                    }
                                    }}>
                                    {
                                        signalIsLoading ? 
                                        <Loading
                                            height={signalBtnSize.height}
                                            width={signalBtnSize.width}
                                        /> : t("home.debate.signalBtn")
                                    }
                                </button>
                            </div>
                        }
                        onClose={() => {setSignalModalVisible(false)}}
                    />
                :
                null
            }
        </>
    )
}

export default HomeDebate