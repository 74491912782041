import Store from "../../Store";
import { useEffect, useState } from "react";
import getTimeEllapsed from "../../functions/getTimeEllapsed"
import { t } from "i18next";

const TopicDebate = (props) => {

    const [theme, setTheme] = useState(Store.getState().theme)

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    return (
        <section className={`border-${theme}-lowContrast topic-debate-wrapper color-${theme}-text`}>
            <div className="topic-debate">
                <div>
                    <a href={`https://dbaato.com/debate/${props.domain}/${props.id}`}>{props.title}</a>
                </div>
                <div className={`border border-${theme}-ultraLowContrast-bottom`}></div>
                <div className="debate-info-wrapper">
                    <div className="debate-info">
                        {`${getTimeEllapsed(props.openTimestamp)}`}
                    </div>
                    <div className="debate-info">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" viewBox="0 0 24 24"><path className={`fill-${theme}-contrast`} d="M12.3,12.22A4.92,4.92,0,0,0,14,8.5a5,5,0,0,0-10,0,4.92,4.92,0,0,0,1.7,3.72A8,8,0,0,0,1,19.5a1,1,0,0,0,2,0,6,6,0,0,1,12,0,1,1,0,0,0,2,0A8,8,0,0,0,12.3,12.22ZM9,11.5a3,3,0,1,1,3-3A3,3,0,0,1,9,11.5Zm9.74.32A5,5,0,0,0,15,3.5a1,1,0,0,0,0,2,3,3,0,0,1,3,3,3,3,0,0,1-1.5,2.59,1,1,0,0,0-.5.84,1,1,0,0,0,.45.86l.39.26.13.07a7,7,0,0,1,4,6.38,1,1,0,0,0,2,0A9,9,0,0,0,18.74,11.82Z"/></svg>
                        <p>{props.nb_participants}</p>
                    </div>
                    <div className="debate-info">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" viewBox="0 0 24 24"><path className={`fill-${theme}-contrast`} d="M13,11H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Zm4-4H7A1,1,0,0,0,7,9H17a1,1,0,0,0,0-2Zm2-5H5A3,3,0,0,0,2,5V15a3,3,0,0,0,3,3H16.59l3.7,3.71A1,1,0,0,0,21,22a.84.84,0,0,0,.38-.08A1,1,0,0,0,22,21V5A3,3,0,0,0,19,2Zm1,16.59-2.29-2.3A1,1,0,0,0,17,16H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"/></svg>
                        <p>{props.nb_arguments}</p>
                    </div>
                    {
                        props.nb_arguments > 0 ?
                        <>
                            <div className="debate-info">
                                {`${props.votedYes} ${t('home.debate.votedYesShort')}`}
                            </div>
                            <div className="debate-info">
                                {`${props.votedNo} ${t('home.debate.votedNoShort')}`}
                            </div>
                        </>
                        :
                        null
                    }
                </div>
            </div>
        </section>
    )
}

export default TopicDebate