const SETTINGS = {
    home: {
        initMinDebates: 5,
        initMinArgs: 3,
        paginationMinDebates: 3,
        paginationMinArgs: 3,
        usersToFollowAppearIndex: 5,
        trendingTopicsAppearIndex: 3,
        stickyOffsetPxFromHeader: 20
    }
}

export default SETTINGS