const colors = {

    light: {
        // Color levels from lighter to darker
        main1: "rgb(250, 250, 250)",
        main2: "rgb(245, 245, 245)",
        main3: "rgb(235, 235, 235)",
        main4: "rgb(225, 225, 225)",
        main3Transparent: "rgba(235, 235, 235, 0.2)",
        grey: "#7C7C7C",
        cyan: "rgb(86, 198, 178)",
        cyanPressed: "rgb(56, 168, 148)",
        pink: "rgb(195, 86, 198)",
        gradient: "linear-gradient(45deg, rgb(127, 168, 199), rgb(195, 86, 198), rgb(243, 163, 43))",
        electricBlue: "rgb(19, 108, 167)",
        electricBlueTransparent: "rgba(19, 108, 167, 0.637)",
        dark: "rgb(33, 33, 33)",
        red: "rgb(232, 83, 83)",
        ultraLightRed: "rgb(254, 244, 230)",
        green: "#48DC69",
        ultraLightGreen: "rgb(230, 244, 254)",
        modalFilter: "rgba(235, 235, 235, 0.8)",
        darkTransparent: "rgba(33, 33, 33, 0.8)",
        positiveBg: "rgb(188, 232, 241)",
        positiveText: "rgb(69, 134, 182)",
        inputBg: "rgb(245, 245, 245)",
        inputBorder: "rgba(33, 33, 33, 0.1)"
    },
    dark: {
        main1: "rgb(250, 250, 250)",
        main2: "rgb(245, 245, 245)",
        main3: "rgb(235, 235, 235)",
        main4: "rgb(225, 225, 225)",
        main3Transparent: "rgba(235, 235, 235, 0.2)",
        grey: "#7C7C7C",
        cyan: "rgb(86, 198, 178)",
        cyanPressed: "rgb(56, 168, 148)",
        pink: "rgb(195, 86, 198)",
        gradient: "linear-gradient(45deg, rgb(127, 168, 199), rgb(195, 86, 198), rgb(243, 163, 43))",
        electricBlue: "rgb(19, 108, 167)",
        electricBlueTransparent: "rgba(19, 108, 167, 0.637)",
        dark: "rgb(12, 12, 12)",
        red: "rgb(232, 83, 83)",
        ultraLightRed: "rgb(254, 244, 230)",
        green: "#48DC69",
        ultraLightGreen: "rgb(230, 244, 254)",
        modalFilter: "rgba(235, 235, 235, 0.8)",

        positiveBg: "rgb(188, 232, 241)",
        positiveText: "rgb(69, 134, 182)",
        inputBg: "rgb(245, 245, 245)",
        inputBorder: "rgba(33, 33, 33, 0.1)"
    }


}

export default colors