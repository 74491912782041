import { t } from 'i18next';
import Store from '../../Store';
import { useEffect, useState } from 'react';
import Api from '../../API/Api';
import getBreakpoint from '../../functions/getBreakpoint';

const api = new Api

const SearchBar = () => {

    const [theme, setTheme] = useState(Store.getState().theme)
    const [breakpoint, setBreakpoint] = useState(getBreakpoint())
    const [resultsVisible, setResultsVisible] = useState(false)
    const [searchResultsWindow, setSearchResultsWindow] = useState({top: 0, width: 0, left: 0})
    const [searchResults, setSearchResults] = useState(null)
    const [resultsReadyPrepare, setResultsReadyPrepare] = useState(false)
    const [resultsReady, setResultsReady] = useState(false)
    const [inputValue, setInputValue] = useState('')

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    useEffect(() => {
        window.addEventListener('resize', () => {
            setBreakpoint(getBreakpoint())
        })
    }, [])

    useEffect(() => {
        setResultsReady(resultsReadyPrepare)
    }, [resultsReadyPrepare, theme])

    const resultsWindowSetPosDim = () => {
        try {
            const searchBarDimsPos = document.getElementById('search-bar').getBoundingClientRect()
            setSearchResultsWindow({
                top: `${searchBarDimsPos.top + searchBarDimsPos.height + 5}px`,
                width: breakpoint != "extraSmall" ? `${searchBarDimsPos.width -5}px` : `calc(100% - 10px)`,
                left: breakpoint != "extraSmall" ? `${searchBarDimsPos.left + 5}px` : "5px"
            })
        } catch (error) {
            setTimeout(() => {
                const searchBarDimsPos = document.getElementById('search-bar').getBoundingClientRect()
                setSearchResultsWindow({
                    top: `${searchBarDimsPos.top + searchBarDimsPos.height + 5}px`,
                    width: breakpoint != "extraSmall" ? `${searchBarDimsPos.width -5}px` : `calc(100% - 10px)`,
                    left: breakpoint != "extraSmall" ? `${searchBarDimsPos.left + 5}px` : "5px"
                })
            }, 200)
        }
    }

    const search = async (query) => {

        if (query.length > 2) {
            api.globalSearch(query)
            .then(res => {
                if (res.data.success) {
                    if (res.data.hasData) {
                        let debatesResults = null
                        let argsResults = null
                        if (res.data.debates != "no-result") {
                            debatesResults = 
                            <section className={`results-content color-${theme}-text`}>
                                <h1>{t('common.searchResults.debates')}</h1>
                                    {res.data.debates.map(debate => {
                                        return (
                                            <a 
                                                className={`color-${theme}-text`}
                                                href={`/debate/${debate.domain}/${debate.id}`}
                                                key={Math.random()}>
                                                <div className={`border-${theme}-main search-debate-wrapper`}>
                                                    <p>{debate.title}</p>
                                                    <div className='result-info'>
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18px" viewBox="0 0 24 24"><path className={`fill-${theme}-contrast`} d="M12.3,12.22A4.92,4.92,0,0,0,14,8.5a5,5,0,0,0-10,0,4.92,4.92,0,0,0,1.7,3.72A8,8,0,0,0,1,19.5a1,1,0,0,0,2,0,6,6,0,0,1,12,0,1,1,0,0,0,2,0A8,8,0,0,0,12.3,12.22ZM9,11.5a3,3,0,1,1,3-3A3,3,0,0,1,9,11.5Zm9.74.32A5,5,0,0,0,15,3.5a1,1,0,0,0,0,2,3,3,0,0,1,3,3,3,3,0,0,1-1.5,2.59,1,1,0,0,0-.5.84,1,1,0,0,0,.45.86l.39.26.13.07a7,7,0,0,1,4,6.38,1,1,0,0,0,2,0A9,9,0,0,0,18.74,11.82Z"/></svg>
                                                            <p>{debate.nb_participants}</p>
                                                        </span>
                                                        <span>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18px" viewBox="0 0 24 24"><path className={`fill-${theme}-contrast`} d="M13,11H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Zm4-4H7A1,1,0,0,0,7,9H17a1,1,0,0,0,0-2Zm2-5H5A3,3,0,0,0,2,5V15a3,3,0,0,0,3,3H16.59l3.7,3.71A1,1,0,0,0,21,22a.84.84,0,0,0,.38-.08A1,1,0,0,0,22,21V5A3,3,0,0,0,19,2Zm1,16.59-2.29-2.3A1,1,0,0,0,17,16H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"/></svg>
                                                            <p>{debate.nb_arguments}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </a>
                                        )
                                    })}
                            </section>
                        } else {
                            debatesResults = 
                            <section className={`results-content color-${theme}-text`}>
                                <h1>{t('common.searchResults.debates')}</h1>
                                <p>{t('common.searchResults.noResult')}</p>
                            </section>
                        }

                        if (res.data.args != "no-result") {
                            argsResults=
                            <section className={`results-content color-${theme}-text`}>
                                <h1>{t('common.searchResults.arguments')}</h1>
                                    {res.data.args.map(arg => {
                                        return (
                                            <a 
                                                className={`color-${theme}-text`}
                                                href={`/debate/${arg.domain}/${arg.debate_id}`}
                                                key={Math.random()}>
                                                <div className={`border-${theme}-main search-debate-wrapper`}>
                                                    <p>"{arg.content}"</p>
                                                    <div className='result-info'>
                                                        <span>
                                                            <p 
                                                                style={{fontWeight: 600}}>
                                                                - {arg.author}
                                                            </p>
                                                        </span>
                                                    </div>
                                                </div>
                                            </a>
                                        )
                                    })}
                            </section>
                        } else {
                            argsResults =
                            <section className={`results-content color-${theme}-text`}>
                                <h1>{t('common.searchResults.arguments')}</h1>
                                <p>{t('common.searchResults.noResult')}</p>
                            </section>
                        }

                        setSearchResults([debatesResults, argsResults])
                        setResultsReadyPrepare(true)
                    } else {
                        let debatesResults = 
                            <section className={`results-content color-${theme}-text`}>
                                <h1>{t('common.searchResults.debates')}</h1>
                                <p>{t('common.searchResults.noResult')}</p>
                            </section>
                        let argsResults =
                        <section className={`results-content color-${theme}-text`}>
                            <h1>{t('common.searchResults.arguments')}</h1>
                            <p>{t('common.searchResults.noResult')}</p>
                        </section>
                        setSearchResults([debatesResults, argsResults])
                        setResultsReadyPrepare(true)
                    }
                } else {
                    let debatesResults = 
                        <section className={`results-content color-${theme}-text`}>
                            <h1>{t('common.searchResults.debates')}</h1>
                            <p>{t('common.searchResults.noResult')}</p>
                        </section>
                    let argsResults =
                    <section className={`results-content color-${theme}-text`}>
                        <h1>{t('common.searchResults.arguments')}</h1>
                        <p>{t('common.searchResults.noResult')}</p>
                    </section>
                    setSearchResults([debatesResults, argsResults])
                    setResultsReadyPrepare(true)
                }
            })
            .catch(err => {
                console.log(err)
                setResultsReadyPrepare(false)
            })
        }
    }

    const onClose = (e) => {
        if (e.target.className.includes("research-results-wrapper")) {
            setResultsVisible(false)
        }
    }

    return (
        <>
        <div id="search-bar" className="search-bar">
            <form
                autoComplete="off"
                method="get"
                role="search"
                >
                <div className={`bg-${theme}-component shadow-${theme} color-${theme}-textLow`} id="input-wrapper">
                    <svg className={`fill-${theme}-textLow`} width="20px" viewBox='0 0 24 24' xmlns="http://www.w3.org/2000/svg"><path fill="current-color" d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>
                    <input
                        onChange={(e) => {
                            resultsWindowSetPosDim()
                            setInputValue(e.target.value)
                            if (e.target.value.length > 2) {
                                setResultsVisible(true)
                                search(e.target.value)
                            } else {
                                setResultsVisible(false)
                            }
                        }}
                        onClick={() => {
                            if (inputValue.length > 2) {
                                setResultsVisible(true)
                            }
                        }}
                        className={`color-${theme}-textLow`}
                        placeholder={t("common.searchBar")}
                        style={{backgroundColor: 'transparent'}}
                    />
                </div>
            </form>
        </div>
        {
            resultsVisible ? 
            <div className={`research-results-wrapper shadow-${theme}`}
                onClick={(e) => {onClose(e)}}>
                <div className={`research-results bg-${theme}-component shadow-${theme}`}
                    style={{ top: searchResultsWindow.top, width: searchResultsWindow.width, left: searchResultsWindow.left }}>
                    <div className='results'>
                        {
                            resultsReady ?
                            searchResults.map(result => {
                                return result
                            })
                            :
                            null
                        }
                    </div>
                </div>
            </div>
            :
            null
        }
        </>
    )
}

export default SearchBar