import Store from "../Store"

class HomePaginator {

    constructor () {
        this.posY = window.scrollY
        this.isPaginating = false
        this.initialised = false
    }

    isScrollDown = () => {
        let dir = "down"
        if (window.scrollY < this.posY) {
            dir = "up"
        }
        this.posY = window.scrollY
        if (dir === "down") {
            return true
        } else {
            return false
        }
    }

    // Set to false after fetch of data
    setIsPaginating = (paginatingValue) => {
        this.isPaginating = paginatingValue
    }

    // Returns a bool to check if yes or not we can paginate
    canPaginate = () => {

        const scrollingDown = this.isScrollDown()

        if (!this.isPaginating && scrollingDown &&  this.posY + window.innerHeight + 30 > document.body.scrollHeight) {
            this.isPaginating = true
            Store.dispatch({
                type: 'SET_CAN_PAGINATE',
                payload: true
            })
            return true
        } else {
            Store.dispatch({
                type: 'SET_CAN_PAGINATE',
                payload: false
            })
            return false
        }
    }

}

export default HomePaginator