import { useState } from "react"
import Store from "../../../../Store"

const SideBoxShadow = () => {

    const [theme, setTheme] = useState(Store.getState().theme)

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    return (
        <div className={`home-side-shadow-wrapper bg-${theme}-component shadow-${theme}`}>
            <div className={`home-side-shadow`}>
                <div className={`shadow shadow-${theme}-component bg-${theme}-main`}>
                </div>
            </div>
        </div>
    )
}

export default SideBoxShadow