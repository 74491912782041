
import { useState } from "react"
import Store from "../../../Store"

const HomeCaroussel = (props) => {

    const [theme, setTheme] = useState(Store.getState().theme)
    
    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    return (
        <div className={`home-caroussel-wrapper`}>
            <div className={`home-caroussel`}>
                <a 
                    href={`/debate/${props.debates[0].domain}/${props.debates[0].id}`}
                    className={`home-caroussel-part-wrapper`}>
                    <div className={`home-caroussel-part bg-${theme}-component ${props.debates[0].domain}`}>
                        <div className={`home-caroussel-info relative color-dark-text`}>
                            <div className={`home-caroussel-title loaded`}>
                                {props.debates[0].title}
                            </div>
                        </div>
                    </div>
                </a>
                <a 
                    href={`/debate/${props.debates[1].domain}/${props.debates[1].id}`}
                    className={`home-caroussel-part-wrapper shadow-${theme}-component`}>
                    <div className={`home-caroussel-part bg-${theme}-component ${props.debates[1].domain}`}>
                        <div className={`home-caroussel-info relative color-dark-text`}>
                            <div className={`home-caroussel-title loaded`}>
                                {props.debates[1].title}
                            </div>
                        </div>
                    </div>
                </a>
                <a 
                    href={`/debate/${props.debates[2].domain}/${props.debates[2].id}`}
                    className={`home-caroussel-part-wrapper`}>
                    <div className={`home-caroussel-part bg-${theme}-component ${props.debates[2].domain}`}>
                        <div className={`home-caroussel-info relative color-dark-text`}>
                            <div className={`home-caroussel-title loaded`}>
                                {props.debates[2].title}
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default HomeCaroussel