import { t } from "i18next";

const log = (elemId, i18nKey, type, timeout, animation=undefined) => {

    // Show the log box
    const logElem = document.getElementById(elemId)
    logElem.innerText = t(i18nKey)
    logElem.style.display = "block"
    logElem.classList.add(type)

    // Make the log box disapear after x ms
    setTimeout(() => {
        logElem.classList.remove(type)
        logElem.style.display = "none"
    }, timeout)

}

export default log