import Store from "../../Store"
import { useEffect, useState } from "react"
import { IKContext, IKImage } from 'imagekitio-react'
import stringIsLink from "../../functions/stringIsLink"

const ProfilePicMini = (props) => {

    const [theme, setTheme] = useState(Store.getState().theme)
    const [borderClass, setBorderClass] = useState('')

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    useEffect(() => {
        if (typeof props.profilePicLink === "string") {
            if (props.profilePicLink === "none" || props.profilePicLink.includes('null') || props.profilePicLink.includes('?')) {
                setBorderClass(props.border)
            }
        } else {
            setBorderClass(props.border)
        }
    }, [])

    return (
        <div className={`profile-pic-wrapper ${borderClass}`}>
            {
                stringIsLink(props.profilePicLink) ? 
                !props.profilePicLink.includes('null') ?
                    !props.profilePicLink.includes('?') ?
                    <IKContext urlEndpoint="https://ik.imagekit.io/hhsvlzkzs">
                        <IKImage
                            transformation={[{
                                width: typeof props.customWidth === "undefined" ? 40 : props.customWidth,
                                height: typeof props.customHeight === "undefined" ? 40 : props.customHeight
                            }]}
                            src={ props.profilePicLink }
                            loading="lazy"
                            lqip={{ active: true, quality: 20, blur: 10 }}
                        />
                    </IKContext>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 24 24">
                        <path className={`fill-${theme}-contrast`} d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z"/>
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 24 24">
                        <path className={`fill-${theme}-contrast`} d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z"/>
                    </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="16" viewBox="0 0 24 24">
                    <path className={`fill-${theme}-contrast`} d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z"/>
                </svg>
            }
        </div>
    )
}

export default ProfilePicMini