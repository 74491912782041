import { useEffect, useState } from "react";
import Store from "../Store";
import ThemeSwitch from "./reusable/ThemeSwitch";
import ProfilePicMini from "./reusable/ProfilePicMini";
import Api from "../API/Api";
import Loading from "./reusable/Loading";
import { t } from 'i18next'
import log from "../functions/log";
import Modal from "./reusable/Modal";
import { Navigate, useNavigate } from "react-router-dom";

const api = new Api

const Account = () => {

    const [theme, setTheme] = useState(Store.getState().theme)
    const [contentLoaded, setContentLoaded] = useState(false)
    const [profilePicSize, setProfilePicSize] = useState(Math.min(150, Math.round(window.innerWidth / 5)))
    const [user, setUser] = useState({})
    const [isUploadingPic, setIsUploadingPic] = useState(false)
    const [deleteModalVisible, setDeleteModalVisible] = useState(false)
    const [delBtnClass, setDelBtnClass] = useState(`bg-light-lowContrast label-light`)
    const [canDel, setCanDel] = useState(false)

    Store.subscribe(() => {
        setTheme(Store.getState().theme)
    })

    const navigate = useNavigate()

    useEffect(() => {

        window.addEventListener('resize', () => {
            setProfilePicSize(Math.min(150, Math.round(window.innerWidth / 5)))
        })

        api.getUserProfile()
        .then(res => {
            console.log(res)
            if (res.data.success) {
                let userObject = {}
                userObject.uid = res.data.user.uid
                userObject.pic = res.data.user.pic
                userObject.pic_format = res.data.user.pic_format
                userObject.score = res.data.user.score
                userObject.sex = res.data.user.sex
                userObject.email = res.data.user.email
                userObject.region = res.data.user.region
                userObject.birthdate = res.data.user.birthdate

                try {
                    userObject.following = JSON.parse(res.data.user.following).following.length
                } catch (err) {
                    userObject.following = res.data.user.following.following.length
                }

                try {
                    userObject.followers = JSON.parse(res.data.user.followers).followers.length
                } catch (err) {
                    userObject.followers = res.data.user.followers.followers.length
                }

                setUser(userObject)
                setContentLoaded(true)
            }
        })
        .catch(err => {
            navigate('/')
        })
    }, [])

    const editData = async (field, value) => {
        api.editUserData(field, value)
        .then(res => {
            console.log(res)
            if (res.data.success) {
                //setUser(res.data.user)
                let userObject = res.data.user
                try {
                    userObject.following = JSON.parse(res.data.user.following).following.length
                } catch (err) {
                    userObject.following = res.data.user.following.following.length
                }

                try {
                    userObject.followers = JSON.parse(res.data.user.followers).followers.length
                } catch (err) {
                    userObject.followers = res.data.user.followers.followers.length
                }
                setUser(userObject)
            } else {
                log("log", "account.logs.unknownError", "error", 3000)
            }
        })
        .catch(err => {
            log("log", "account.logs.unknownError", "error", 3000)
        })
    }

    const editPic = async (file) => {
        api.editProfilePic(user.pic, file, user.uid)
        .then(res => {
            if (res.data.success) {
                localStorage.setItem('profile-pic', res.data.pic + '.' + res.data.pic_format)
                let newUserObj = user
                newUserObj.pic = res.data.pic
                newUserObj.pic_format = res.data.pic_format
                console.log(newUserObj)
                setUser(newUserObj)
            } else {
                log("log", "account.logs.unknownError", "error", 3000)
            }
            setIsUploadingPic(false)
        })
        .catch(err => {
            log("log", "account.logs.unknownError", "error", 3000)
            setIsUploadingPic(false)
        })
    }

    const typingUidForDelete = (typed) => {

        if (typed === user.uid) {
            setDelBtnClass("bg-light-red color-light-onContrast")
            setCanDel(true)
        } else {
            setDelBtnClass(`bg-light-lowContrast label-light`)
            setCanDel(false)
        }
    }

    const deleteAccount = () => {
        if (canDel) {
            api.deleteAccount(localStorage.getItem('persistant-login-id'))
            .then(res => {
                console.log(res)
                if (res.data.success) {
                    localStorage.clear()
                    navigate('/')
                } else {
                    log('log', "account.logs.unknownError", "error", 3000)
                }
            })
            .catch(err => {
                log('log', "account.logs.unknownError", "error", 3000)
            })
        }
    }
 
    return (
        <>
            <main className={`main-wrapper bg-${theme}-main`}>
                <a style={{ paddingTop: "20px" }} href="/" className={`logo color-${theme}-text`}>dBaato!</a>
                <div className='main'>
                    <div id="account-wrapper">
                            <div id="account-user-wrapper">
                                {
                                    contentLoaded ?
                                    <>
                                        <div id="account-user">
                                            <input id="pic-input" type="file" accept="image/*"
                                                onChange={(e) => {
                                                    if (typeof e.target.files[0] != "undefined") {
                                                        setIsUploadingPic(true)
                                                        editPic(e.target.files[0])
                                                    } 
                                                }}
                                                onAbort={() => {setIsUploadingPic(false)}}>
                                            </input>
                                            <div id="pic-wrapper" className={`border-${theme}-lowContrast`} onClick={() => {
                                                if (!isUploadingPic) {
                                                    document.getElementById('pic-input').click()
                                                }
                                                }}>
                                                {
                                                    !isUploadingPic ?
                                                    <ProfilePicMini
                                                        profilePicLink={ `https://ik.imagekit.io/hhsvlzkzs/${user.pic}.${user.pic_format}` }
                                                        customWidth={ profilePicSize }
                                                        customHeight={ profilePicSize }
                                                    />
                                                    :
                                                    <Loading/>
                                                }
                                
                                            </div>
                                            <div id="user">
                                                <div id="uid" >
                                                    <p className={`color-${theme}-text`}>{user.uid}</p>
                                                </div>
                                                <div className="bg-light-gradient"></div>
                                                <div id="stats" className={`color-${theme}-text`}>
                                                    <p>{t("account.score")} {user.score}</p>
                                                    <p>{t("account.followers")} {user.followers}</p>
                                                    <p>{t("account.following")} {user.following}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="user-info-wrapper" className={`border-${theme}-lowContrast`}>
                                            <div className={`color-${theme}-text`} id="user-info">
                                                <h3>{t("account.informations")}</h3>
                                                <p>{t("account.email")} {user.email}</p>
                                                <p>{t("account.birthdate")} {user.birthdate}</p>
                                                <p>{t("account.region")} {user.region}</p>
                                                <p>{t("account.sex")} {user.sex}</p>
                                                <div id="info-inline">
                                                    <p>{user.accepts_emails === 1 ? t("account.emailsPrefYes") : t("account.emailsPrefNo")}</p>
                                                    <button className={`border-${theme}-contrast color-${theme}-text`}
                                                        onClick={() => {
                                                            editData('emailing', user.accepts_emails === 0 ? 1 : 0)
                                                        }}>
                                                        {t("account.emailsChange")}
                                                    </button>
                                                </div>
                                                <div id="info-row-reverse">
                                                    <button className={`color-${theme}-red`} onClick={
                                                        () => {
                                                            setCanDel(false)
                                                            setDeleteModalVisible(true)
                                                        }
                                                        }>
                                                        {t("account.accountDelete")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <Loading/>
                                }
                            </div>
                    </div>
                </div>
                <div id="log"></div>
                <ThemeSwitch/>
            </main>
            {
                deleteModalVisible ?
                <Modal
                    content={
                        <div id="delete-account-modal">
                            <h2>{t("account.accountDeleteConfTitle")}</h2>
                            <label style={{ display: "block" }}>{t("account.accountDeletePlaceholder")}</label>
                            <input 
                                className={`input border-light-lowContrast`}
                                onChange={(e) => {typingUidForDelete(e.target.value)}}
                                >
                            </input>
                            <div id="inputs-inline">
                                <button
                                    style={{border: "none", borderRadius: "3px"}}
                                    onClick={() => {setDeleteModalVisible(false)}}
                                    >{t("account.accountDeleteConfCancel")}
                                </button>
                                <button 
                                    style={{border: "none", borderRadius: "3px"}}
                                    onClick={() => {deleteAccount()}}
                                    className={delBtnClass}
                                >{t("account.accountDeleteConfYes")}
                                </button>
                            </div>
                        </div>
                    }
                    onClose={() => setDeleteModalVisible(false)}
                />:
                null
            }
        </>
    )


}

export default Account