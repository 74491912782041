import CounterArg from "../components/reusable/CounterArg"

const generateCounterArgs = (mainCounterProps) => {

    // 1. Get counter arguments
    // 2. Add them to the list
    // 3. Return them

    const counters = []
    mainCounterProps.counterArgsList.forEach(counter => {
        if (typeof(counter) === "object") {
            if (counter.counter_argument_of === mainCounterProps.id) {
                // Get counters of this counter
                const counterCounters = []
                mainCounterProps.arguments.map(counter_ => {
                    if (counter_.counter_argument_of === counter.id) {
                        counterCounters.push(counter_)
                    }
                })
                counters.push(
                    <CounterArg
                        id={counter.id}
                        key={Math.random()}
                        vote={counter.vote}
                        counterArgsList={counterCounters}
                        upvotes={counter.upvotes}
                        downvotes={counter.downvotes}
                        timestamp={counter.timestamp}
                        author={counter.author}
                        content={counter.content}
                        arguments={mainCounterProps.arguments}
                        debateId={mainCounterProps.debateId}
                        topic={mainCounterProps.topic}
                        title={mainCounterProps.title}
                        onAction={mainCounterProps.onAction}
                        onLike={mainCounterProps.onLike}
                        onEdit={mainCounterProps.onEdit}
                        onDelete={mainCounterProps.onDelete}
                        onSignal={mainCounterProps.onSignal}
                        profilePics={mainCounterProps.profilePics}
                    />
                )
            }
        }
        
    });

    return counters

}

export default generateCounterArgs